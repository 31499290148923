import { getAmadeusToken } from './auth';
import { supabase } from '../lib/supabase';

async function updateApiCallCount() {
  try {
    // First check if row exists
    const { data: existing, error: checkError } = await supabase
      .from('api_calls')
      .select('*')
      .eq('api_name', 'amadeus_v2_schedule_flights')
      .eq('endpoint', 'flight_details')
      .single();

    console.log('Existing API call row:', existing);
    
    if (checkError) {
      console.error('Error checking API call row:', checkError);
      return;
    }

    // Then update using direct increment
    const { data, error } = await supabase
      .from('api_calls')
      .update({ 
        count: existing.count + 1,  // Direct increment
        last_called: new Date().toISOString(),
        url: `https://api.amadeus.com/v2/schedule/flights`  // Store base URL
      })
      .eq('api_name', 'amadeus_v2_schedule_flights')
      .eq('endpoint', 'flight_details')
      .select();

    console.log('Update response:', data);
    
    if (error) {
      console.error('Update error details:', error);
      throw error;
    }
    
  } catch (error) {
    console.error('Error updating API call count:', error);
  }
}

export async function findFlight(airline, flightNumber, date) {
  try {
    const token = await getAmadeusToken();
    const formattedDate = new Date(date).toISOString().split('T')[0];
    const paddedFlightNumber = flightNumber.padStart(4, '0');

    console.log('Starting flight search with params:', {
      airline,
      flightNumber,
      paddedFlightNumber,
      date,
      formattedDate,
      hasToken: !!token
    });

    const apiUrl = `https://api.amadeus.com/v2/schedule/flights?` + 
      `carrierCode=${airline}&` +
      `flightNumber=${paddedFlightNumber}&` +
      `scheduledDepartureDate=${formattedDate}`;

    console.log('API URL:', apiUrl);

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json'
      }
    });

    // Log response status and headers
    console.log('API Response Status:', response.status);
    console.log('API Response Headers:', Object.fromEntries(response.headers.entries()));

    // Increment the counter for this API endpoint
    await updateApiCallCount();

    const data = await response.json();

    // Add this detailed logging
    console.log('Raw API Response:', {
      fullData: data,
      meta: data.meta,
      dataArray: data.data,
      status: response.status
    });

    if (!response.ok) {
      console.error('API Error Response:', {
        status: response.status,
        statusText: response.statusText,
        errorData: data,
        errorDetail: data.errors?.[0]?.detail
      });
      throw new Error(`Amadeus API error: ${data.errors?.[0]?.detail || response.status}`);
    }

    // Enhance the data structure check
    console.log('API Response Data Structure:', {
      hasData: !!data,
      dataType: typeof data,
      hasDataArray: Array.isArray(data.data),
      dataLength: data.data?.length,
      firstItem: data.data?.[0] ? {
        type: data.data[0].type,
        flightDesignator: data.data[0].flightDesignator,
        flightPoints: data.data[0].flightPoints?.length
      } : 'missing',
      meta: data.meta,
      rawResponse: JSON.stringify(data).substring(0, 200) + '...' // First 200 chars for quick view
    });

    if (data.data && data.data[0]) {
      const flight = data.data[0];
      const departure = flight.flightPoints?.[0] || {};
      const arrival = flight.flightPoints?.[1] || {};
      const segment = flight.segments?.[0] || {};

      console.log('=== Flight API Processing ===');
      console.log('Raw arrival:', arrival);
      console.log('Arrival timings:', arrival.arrival?.timings);
      console.log('First timing value:', arrival.arrival?.timings?.[0]?.value);

      return {
        meta: {
          count: data.meta?.count,
          links: data.meta?.links
        },
        flight: {
          // Basic flight info
          type: flight.type,
          scheduledDepartureDate: flight.scheduledDepartureDate,
          flightDesignator: {
            carrierCode: flight.flightDesignator?.carrierCode,
            flightNumber: flight.flightDesignator?.flightNumber
          },
          
          // Departure details
          departure: {
            airport: departure.iataCode,
            scheduledTime: departure.departure?.timings?.[0]?.value,
            timeQualifier: departure.departure?.timings?.[0]?.qualifier
          },
          
          // Arrival details
          arrival: {
            airport: arrival.iataCode,
            scheduledTime: arrival.arrival?.timings?.[0]?.value.split('+')[0],
            timeQualifier: arrival.arrival?.timings?.[0]?.qualifier
          },
          
          // Segment details
          segment: {
            boardPoint: segment.boardPointIataCode,
            offPoint: segment.offPointIataCode,
            duration: segment.scheduledSegmentDuration
          }
        },
        
        // Keep raw response for debugging
        _raw: data
      };
    } else {
      console.warn('Flight not found - Detailed Analysis:', {
        searchParams: {
          airline,
          flightNumber,
          paddedFlightNumber,
          date: formattedDate
        },
        responseData: {
          hasData: !!data,
          dataArray: data.data,
          meta: data.meta,
          rawData: JSON.stringify(data).substring(0, 200) + '...' // First 200 chars
        },
        responseStatus: response.status,
        possibleReasons: [
          'Empty data array from API',
          'Missing flight data in response',
          'Unexpected response structure',
          'API returned success but no flight data'
        ],
        dataKeys: data ? Object.keys(data) : [],
        metaInfo: data.meta ? {
          count: data.meta.count,
          links: data.meta.links
        } : 'No meta information'
      });
      throw new Error('Flight not found');
    }

  } catch (error) {
    console.error('Flight search failed:', {
      errorType: error.constructor.name,
      errorMessage: error.message,
      errorStack: error.stack,
      originalParams: {
        airline,
        flightNumber,
        date
      }
    });
    throw error;
  }
} 

export const processEmailedFlight = async (emailData) => {
  // Convert email data to flight format
  // Use existing flight insertion logic
  // Return confirmation to user
};

// Internal utility functions
const calculateDistance = (departureAirport, arrivalAirport) => {
  // For now, return a placeholder
  return 0;
};

const calculateDuration = (departureTime, arrivalTime) => {
  const departure = new Date(departureTime);
  const arrival = new Date(arrivalTime);
  return Math.round((arrival - departure) / (1000 * 60));
};

export const storeFlight = async (flightData) => {
  try {
    const flightRecord = {
      airline_code: flightData.flightDesignator.carrierCode,
      flight_number: flightData.flightDesignator.flightNumber.toString(),
      departure_airport_code: flightData.departure.airport,
      arrival_airport_code: flightData.arrival.airport,
      departure_time: flightData.departure.scheduledTime.substring(0, 16),
      arrival_time: flightData.arrival.scheduledTime.substring(0, 16),
      distance_miles: calculateDistance(
        flightData.departure.airport, 
        flightData.arrival.airport
      ),
      duration_mins: calculateDuration(
        flightData.departure.scheduledTime.substring(0, 16), 
        flightData.arrival.scheduledTime.substring(0, 16)
      )
    };

    console.log('Storing flight record:', flightRecord);

    const { data, error } = await supabase
      .from('flights')
      .insert([flightRecord])
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Error storing flight:', error);
    throw error;
  }
};

/**
 * Flight-related API functions
 */

const POS_CONFIG = {
  currencyCode: 'USD',
  countryCode: 'US'
};

/**
 * Get flight availability from Amadeus API
 * @param {Object} params
 * @param {string} params.origin - Origin airport code
 * @param {string} params.destination - Destination airport code
 * @param {string} params.date - Flight date (YYYY-MM-DD)
 * @param {string} params.airline - Airline code
 * @param {string} params.flightNumber - Flight number
 * @returns {Promise<Object>} Flight availability data
 */
export async function getFlightAvailability({
  origin,
  destination,
  date,
  airline,
  flightNumber
}) {
  try {
    console.log('Fetching flight availability:', {
      origin,
      destination,
      date,
      airline,
      flightNumber
    });

    const token = await getAmadeusToken();

    const response = await fetch('https://api.amadeus.com/v1/shopping/availability/flight-availabilities', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        originDestinations: [{
          id: "1",
          originLocationCode: origin,
          destinationLocationCode: destination,
          departureDateTime: {
            date: date,
            time: "08:35:00"
          },
          carrierCode: airline,
          flightNumber: flightNumber
        }],
        travelers: [{
          id: "1",
          travelerType: "ADULT"
        }],
        sources: ["GDS"],
        searchCriteria: {
          maxFlightOffers: 1,
          additionalInformation: {
            returnAvailableSeatsOnly: true
          },
          flightFilters: {
            carrierRestrictions: {
              includedCarrierCodes: [airline]
            },
            connectionRestrictions: {
              maxNumberOfConnections: 0
            }
          }
        },
        currencyCode: POS_CONFIG.currencyCode,
        countryCode: POS_CONFIG.countryCode
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.errors?.[0]?.detail || 'Failed to fetch flight availability');
    }

    const data = await response.json();
    console.log('API Response:', data);

    if (data.data) {
      // Filter for exact match on airline and flight number
      data.data = data.data.filter(flight =>
        flight.segments.length === 1 &&
        flight.segments[0].carrierCode === airline &&
        flight.segments[0].number === flightNumber
      );
    }

    return data;
  } catch (error) {
    console.error('Error in getFlightAvailability:', error);
    throw error;
  }
}