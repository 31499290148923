import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { supabase } from '../../lib/supabase';

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 4px;
  box-sizing: border-box;
`;

const Label = styled.div`
  height: 33%;
  font-size: 12px;
  color: ${props => props.theme.colors.neutral.dark.base};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputContainer = styled.div`
  height: 67%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ETicket = styled.span`
  color: #A99968;
  font-weight: 600;
  font-size: 16px;
  padding: 6px 16px;
  border: 1px solid #A99968;
  border-radius: 16px;
  background-color: rgba(169, 153, 104, 0.05);
  box-shadow: 0 1px 2px rgba(169, 153, 104, 0.1);
  cursor: pointer;
  transition: all 0.2s ease;
  width: fit-content;
  max-width: 90%;
  text-align: center;

  &:hover {
    background-color: rgba(169, 153, 104, 0.1);
  }

  input {
    font-size: 16px;
    font-weight: 600;
    width: 120px;  // Wider for e-ticket numbers
    text-align: center;
    border: none;
    outline: none;
    padding: 0;
    background: transparent;
    text-transform: uppercase;
    color: #A99968;
  }
`;

const Dialog = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
`;

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Message = styled.p`
  margin: 0 0 16px 0;
  color: ${props => props.theme.colors.neutral.dark.darkest};
`;

const CloseButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  background: ${props => props.theme.colors.neutral.light.base};
  color: ${props => props.theme.colors.neutral.dark.darkest};
  cursor: pointer;
  
  &:hover {
    background: ${props => props.theme.colors.neutral.light.dark};
  }
`;

function ETicketSelector({ eticket, flightId, onUpdate }) {
  const [isEditing, setIsEditing] = useState(false);
  const [tempETicket, setTempETicket] = useState(eticket || '');
  const [showDialog, setShowDialog] = useState(false);

  const handleETicketUpdate = async (value) => {
    if (value.trim()) {
      try {
        const { error } = await supabase
          .from('flights')
          .update({ eticket_number: value.trim() })
          .eq('id', flightId)
          .single();

        if (error) throw error;
        
        // Update local state and notify parent
        const updatedETicket = value.trim();
        setTempETicket(updatedETicket);
        if (onUpdate) onUpdate(updatedETicket);
        setIsEditing(false);
      } catch (error) {
        console.error('Error updating e-ticket:', error);
      }
    }
  };

  return (
    <>
      <SelectorContainer>
        <Label>E-Ticket</Label>
        <InputContainer>
          <ETicket 
            onClick={() => setShowDialog(true)}
            $isEditing={isEditing}
            $isAdd={!eticket}
          >
            {isEditing ? (
              <input
                type="text"
                value={tempETicket}
                onChange={(e) => setTempETicket(e.target.value.toUpperCase())}
                onBlur={() => handleETicketUpdate(tempETicket)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleETicketUpdate(tempETicket);
                  }
                }}
                autoFocus
                maxLength={13}  // Standard e-ticket length
                onClick={(e) => e.stopPropagation()}
              />
            ) : (
              eticket || 'Upload'  // Changed from 'Add' to 'Upload'
            )}
          </ETicket>
        </InputContainer>
      </SelectorContainer>

      {showDialog && (
        <>
          <DialogOverlay onClick={() => setShowDialog(false)} />
          <Dialog>
            <Message>Future Functionality - Matthew rewriting from scratch</Message>
            <CloseButton onClick={() => setShowDialog(false)}>Close</CloseButton>
          </Dialog>
        </>
      )}
    </>
  );
}

ETicketSelector.propTypes = {
  eticket: PropTypes.string,
  flightId: PropTypes.string.isRequired,
  onUpdate: PropTypes.func
};

export default ETicketSelector; 