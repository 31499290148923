import React, { useState } from 'react';
import styled from 'styled-components';
import { H2, BodyL } from '../../styles/typography';
import FlightIcon from '../icons/FlightIcon';
import AvailabilityIcon from '../icons/AvailabilityIcon';
import SeatmapIcon from '../icons/SeatmapIcon';
import AlertIcon from '../icons/AlertIcon';
import WalletIcon from '../icons/WalletIcon';
import SettingsIcon from '../icons/SettingsIcon';
import BackArrowIcon from '../icons/BackArrowIcon';
import LogoutIcon from '../icons/LogoutIcon';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';

const MenuOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  opacity: ${props => props.$isOpen ? 1 : 0};
  pointer-events: ${props => props.$isOpen ? 'auto' : 'none'};
  transition: opacity 0.3s ease;
`;

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 360px;
  height: 100vh;
  background-color: ${props => props.theme.colors.neutral.light.light};
  transform: translateX(${props => props.$isOpen ? '0' : '-100%'});
  transition: transform 0.3s ease;
  z-index: 999;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
`;

const MenuHeader = styled.div`
  padding: 8px 16px;
  height: 44px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.light.dark};
`;

const BackButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  height: 100%;
  cursor: pointer;
  color: ${props => props.theme.colors.highlight.darkest};
  display: flex;
  align-items: center;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 16px;
  height: 40px;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.light.dark};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.neutral.light.lightest};
  }
`;

const MenuLabel = styled(BodyL)`
  color: ${props => props.theme.colors.neutral.dark.darkest};
`;

const SignOutItem = styled(MenuItem)`
  margin-top: auto;
  border-top: 1px solid ${props => props.theme.colors.neutral.light.dark};
`;

function SideMenu({ isOpen, onClose, onSignOut }) {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleNavigation = (path, label) => {
    console.log('=== Menu Navigation Debug ===');
    console.log('1. Starting navigation:', {
      from: window.location.pathname,
      to: path,
      label
    });

    // Close menu
    onClose();

    // Navigate with callback
    navigate(path, {
      replace: false,
      state: { from: window.location.pathname },
    });

    console.log('2. Navigation called');
  };

  const handleSignOut = async () => {
    try {
      onClose();
      await onSignOut();
    } catch (error) {
      console.error('Error in menu sign out:', error);
    }
  };

  const menuItems = [
    { Icon: FlightIcon, label: 'My Flights', path: '/flights' },
    { Icon: AvailabilityIcon, label: 'Flight Availability', path: '/flight-availability' },
    { Icon: SeatmapIcon, label: 'Seatmaps - Coming soon!' },
    { Icon: AlertIcon, label: 'Alerts - Coming soon!' },
    { Icon: WalletIcon, label: 'Wallet - Coming soon!' },
    { Icon: SettingsIcon, label: 'Settings - Coming soon!' },
    { 
      label: 'Seat Icons',
      path: '/seat-icons',
      emoji: '🪑'
    }
  ];

  return (
    <>
      <MenuOverlay $isOpen={isOpen} onClick={onClose} />
      <MenuContainer $isOpen={isOpen}>
        <MenuHeader>
          <BackButton onClick={onClose}>
            <BackArrowIcon size={24} color={theme.colors.highlight.darkest} />
          </BackButton>
          <H2>Back</H2>
        </MenuHeader>
        {menuItems.map((item, index) => (
          <MenuItem 
            key={index}
            onClick={() => {
              if (item.path) {
                handleNavigation(item.path, item.label);
              }
            }}
          >
            {item.Icon ? (
              <item.Icon 
                size={20} 
                color={theme.colors.highlight.darkest}
              />
            ) : (
              <span style={{ fontSize: '20px' }}>{item.emoji}</span>
            )}
            <MenuLabel>{item.label}</MenuLabel>
          </MenuItem>
        ))}
        <SignOutItem onClick={handleSignOut}>
          <LogoutIcon />
          <MenuLabel>Sign Out</MenuLabel>
        </SignOutItem>
      </MenuContainer>
    </>
  );
}

export default SideMenu; 