import styled from 'styled-components';
import { LAYOUT } from '../../styles/layout';

const ScrollableContent = styled.div`
  position: fixed;
  top: ${LAYOUT.TOTAL_HEADER_HEIGHT}px;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: ${LAYOUT.CONTENT_PADDING}px;
  box-sizing: border-box;
  width: 100%;
  max-width: ${LAYOUT.MOBILE_WIDTH}px;
  margin: 0 auto;
`;

export default ScrollableContent; 