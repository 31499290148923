import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { LAYOUT } from '../../styles/layout';
import Seat from './Seat';  // Import Seat component

const CONTAINER_WIDTH = 320;
const PADDING = 8;
const WING_WIDTH = 20;
const FUSELAGE_WIDTH = CONTAINER_WIDTH - (PADDING * 2) - (WING_WIDTH * 2);

// Grid calculation constants
const AISLE_COUNT = 2;  // Typical for narrow body aircraft
const ROW_NUMBER_COLUMNS = 2;  // Left and right row numbers

const Container = styled.div`
  width: ${CONTAINER_WIDTH}px;
  margin: 0 auto 24px auto;
  padding: ${PADDING}px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

const Cockpit = styled.div`
  height: 30px;
  width: ${FUSELAGE_WIDTH}px;
  margin-left: ${WING_WIDTH}px;
  background: ${props => props.theme.colors.neutral.light.dark};
  border-radius: 4px 4px 0 0;
`;

const MainSection = styled.div`
  display: flex;
`;

const Wing = styled.div`
  width: 20px;
  display: grid;
  grid-template-rows: repeat(${props => props.rows}, 1fr);
  background: ${props => props.theme.colors.neutral.light.dark};
`;

const WingCell = styled.div`
  width: 20px;
  height: ${props => props.cellSize}px;
  font-size: 10px;
  text-align: center;
  border: 0.5px solid ${props => props.theme.colors.neutral.light.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
`;

const Fuselage = styled.div`
  width: ${FUSELAGE_WIDTH}px;
  flex: 1;
  height: 200px;
  background: ${props => props.theme.colors.neutral.light.lightest};
  border: 2px solid ${props => props.theme.colors.neutral.dark.base};
  border-top: none;
  border-bottom: none;
`;

const Tail = styled.div`
  height: 30px;
  width: ${FUSELAGE_WIDTH}px;
  margin-left: ${WING_WIDTH}px;
  background: ${props => props.theme.colors.neutral.light.dark};
  border-radius: 0 0 4px 4px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.width}, ${props => props.cellSize}px);
  border-collapse: collapse;
  width: fit-content;
  height: 100%;
`;

const GridCell = styled.div`
  width: ${props => props.cellSize}px;
  height: ${props => props.cellSize}px;
  font-size: 10px;
  text-align: center;
  border: 0.5px solid ${props => props.theme.colors.neutral.light.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
`;

const SeatContainer = styled(GridCell)`
  padding: 0;  // Remove padding since we'll size the seat directly
`;

const SeatPopup = styled.div`
  position: fixed;
  z-index: 1000;
  background: white;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  min-width: 200px;
  top: ${props => props.$top}px;
  left: ${props => props.$left}px;
  transform: translate(-50%, -100%) translateY(-8px);
  pointer-events: auto;
`;

const PopupTitle = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 14px;
  color: ${props => props.theme.colors.neutral.dark.darkest};
`;

const CharacteristicsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
`;

const Characteristic = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${props => props.theme.colors.neutral.dark.base};
`;

const DynamicWing = styled(Wing)`
  height: ${props => props.$height}px;
`;

const DynamicFuselage = styled(Fuselage)`
  height: ${props => props.$height}px;
`;

function NewSeatmapContainer({ 
  seatmapData, 
  backColor, 
  baseColor, 
  cushionColor,
  cabinFilter,
  currentSeat  // Add currentSeat prop
}) {
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });

  const deckConfig = seatmapData?.data?.[0]?.decks?.[0]?.deckConfiguration;
  const deck = seatmapData?.data?.[0]?.decks?.[0];
  
  // Get characteristics dictionary from API response
  const characteristicsDictionary = seatmapData?.dictionaries?.seatCharacteristics || {};

  const gridWidth = deckConfig?.width || 0;
  const gridLength = deckConfig?.length || 0;

  // Create initial grid and populate
  const grid = Array(gridLength).fill().map(() => Array(gridWidth).fill(null));
  const columnLetters = new Array(gridWidth).fill('');
  
  // Populate seats and collect letters
  deck?.seats?.forEach(seat => {
    const { coordinates, number } = seat;
    if (coordinates && number) {
      const match = number.match(/^(\d+)([A-Z])$/);
      if (match) {
        grid[coordinates.x][coordinates.y] = number;
        columnLetters[coordinates.y] = match[2];
      }
    }
  });

  // Compress multiple empty columns into single aisles
  const compressGrid = (grid) => {
    if (!grid.length) return { compressedGrid: [], compressedLetters: [] };
    
    // Check which columns are completely empty
    const emptyColumns = new Array(grid[0].length).fill(true);
    grid.forEach(row => {
      row.forEach((cell, colIndex) => {
        if (cell !== null) emptyColumns[colIndex] = false;
      });
    });

    // Find groups of adjacent empty columns
    const columnsToKeep = [];
    let isInEmptyGroup = false;
    
    emptyColumns.forEach((isEmpty, index) => {
      if (isEmpty) {
        if (!isInEmptyGroup) {
          // Keep first column of empty group
          columnsToKeep.push(index);
          isInEmptyGroup = true;
        }
        // Skip other empty columns in group
      } else {
        columnsToKeep.push(index);
        isInEmptyGroup = false;
      }
    });

    // Create compressed grid and letters
    const compressedGrid = grid.map(row => 
      columnsToKeep.map(colIndex => row[colIndex])
    );
    
    const compressedLetters = columnsToKeep.map(colIndex => 
      columnLetters[colIndex]
    );

    return { compressedGrid, compressedLetters };
  };

  // Filter empty rows and compress columns
  const nonEmptyGrid = grid.filter(row => row.some(cell => cell !== null));
  const { compressedGrid, compressedLetters } = compressGrid(nonEmptyGrid);

  // Calculate dimensions with compressed grid
  const totalColumns = compressedGrid[0]?.length + 2; // +2 for row numbers
  const cellSize = Math.floor(FUSELAGE_WIDTH / totalColumns);
  const gridHeight = cellSize * (compressedGrid.length + 2); // +2 for header/footer

  // Track unique row numbers
  const rowNumbers = new Set();
  deck?.seats?.forEach(seat => {
    const match = seat.number.match(/^(\d+)[A-Z]$/);
    if (match) {
      rowNumbers.add(match[1]);
    }
  });
  const sortedRowNumbers = Array.from(rowNumbers).sort((a, b) => Number(a) - Number(b));

  // Track overwing rows
  const overwingRows = new Set();
  
  // Group seats by row
  const seatsByRow = {};
  deck?.seats?.forEach(seat => {
    const match = seat.number.match(/^(\d+)/);
    if (match) {
      const rowNum = match[1];
      if (!seatsByRow[rowNum]) {
        seatsByRow[rowNum] = [];
      }
      seatsByRow[rowNum].push(seat);
    }
  });

  // Check which rows are fully overwing
  Object.entries(seatsByRow).forEach(([rowNum, seats]) => {
    if (seats.every(seat => seat.characteristicsCodes?.includes('OW'))) {
      overwingRows.add(rowNum);
    }
  });

  // Calculate number of rows including header/footer
  const totalRows = compressedGrid.length + 2;  // +2 for header/footer rows

  const getSeatProps = (seatData, cellSize) => {
    if (!seatData) return { isAvailable: false };
    
    // First check if it's the current seat
    const isYourSeat = seatData.number === currentSeat;
    
    // If it's your seat, override other properties
    if (isYourSeat) {
      return {
        isAvailable: true,
        isSelected: true,
        isOccupied: false,
        isBlocked: false,
        size: cellSize,
        backColor,
        baseColor,
        cushionColor,
        seatNumber: seatData.number,
        onClick: (e) => handleSeatClick(seatData, e)
      };
    }

    // Otherwise, use the normal availability logic
    const characteristics = seatData.characteristicsCodes || [];
    const seatAvailability = seatData.travelerPricing?.[0]?.seatAvailabilityStatus;

    return {
      isAvailable: seatAvailability === 'AVAILABLE',
      isOccupied: seatAvailability === 'OCCUPIED',
      isBlocked: seatAvailability === 'BLOCKED',
      seatNumber: seatData.number,
      size: cellSize,
      backColor,
      baseColor,
      cushionColor,
      isWindow: characteristics.includes('W'),
      isAisle: characteristics.includes('A'),
      isExit: characteristics.includes('E'),
      isLimitedRecline: characteristics.includes('L'),
      onClick: (e) => handleSeatClick(seatData, e)
    };
  };

  // Add this in the component
  const cellRef = React.useRef();

  const handleSeatClick = (seatData, event) => {
    // Prevent event from bubbling up
    event.stopPropagation();
    
    const rect = event.currentTarget.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const newPosition = {
      top: rect.top + scrollTop - 10,
      left: rect.left + (rect.width / 2)
    };
    
    console.log('Popup Debug:', {
      clickedSeat: seatData.number,
      seatElement: {
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height
      },
      scrollTop,
      calculatedPosition: newPosition,
      willShowPopup: true
    });
    
    setPopupPosition(newPosition);
    setSelectedSeat(seatData);
  };

  // Modify the click-away handler
  useEffect(() => {
    const handleClickAway = (event) => {
      // Only handle click-away if we didn't just click a seat
      if (selectedSeat && 
          !event.target.closest('.seat-popup') && 
          !event.target.closest('.seat-container')) {
        setSelectedSeat(null);
      }
    };

    document.addEventListener('click', handleClickAway);
    return () => document.removeEventListener('click', handleClickAway);
  }, [selectedSeat]);

  // Filter seats by cabin class if specified
  const filteredSeats = deck?.seats?.filter(seat => 
    !cabinFilter || seat.cabin === cabinFilter
  );

  return (
    <Container>
      <Cockpit />
      <MainSection>
        <DynamicWing $height={gridHeight} rows={totalRows}>
          {Array(totalRows).fill().map((_, index) => {
            // Skip header/footer rows
            const rowNumber = index > 0 && index <= compressedGrid.length ? 
              sortedRowNumbers[index - 1] : null;
            
            return (
              <WingCell 
                key={`left-wing-${index}`}
                cellSize={cellSize}
              >
                {rowNumber && overwingRows.has(rowNumber) ? 'W' : '•'}
              </WingCell>
            );
          })}
        </DynamicWing>
        <DynamicFuselage $height={gridHeight}>
          <Grid width={totalColumns} cellSize={cellSize}>
            {/* Header row */}
            <GridCell ref={cellRef} cellSize={cellSize}>•</GridCell>
            {compressedLetters.map((letter, index) => (
              <GridCell key={`header-${index}`} cellSize={cellSize}>
                {letter || '•'}
              </GridCell>
            ))}
            <GridCell cellSize={cellSize}>•</GridCell>

            {/* Seat grid */}
            {compressedGrid.map((row, rowIndex) => (
              <React.Fragment key={`row-${rowIndex}`}>
                <GridCell cellSize={cellSize}>{sortedRowNumbers[rowIndex] || '•'}</GridCell>
                {row.map((cell, colIndex) => {
                  const seatData = filteredSeats?.find(s => s.number === cell);
                  const seatProps = getSeatProps(seatData, cellSize);
                  
                  console.log(`Seat ${cell || 'empty'}:`, {
                    number: seatData?.number,
                    isCurrentSeat: seatData?.number === currentSeat,
                    availability: seatData?.travelerPricing?.[0]?.seatAvailabilityStatus,
                    props: seatProps,
                    rawData: seatData,
                    // Add these to see what makes up each seat type
                    hasPersonIcon: seatProps.isOccupied || seatProps.isSelected,
                    personIconType: seatProps.isSelected ? 'Your Seat' : 
                                   seatProps.isOccupied ? 'Occupied' : 'None',
                    seatStyle: {
                      base: seatProps.isSelected ? 'selected-base' : 
                            seatProps.isOccupied ? 'occupied-base' : 'normal-base',
                      back: seatProps.isSelected ? 'selected-back' : 
                            seatProps.isOccupied ? 'occupied-back' : 'normal-back',
                      person: seatProps.isSelected ? 'selected-person' : 
                              seatProps.isOccupied ? 'occupied-person' : 'none'
                    }
                  });

                  return (
                    <SeatContainer 
                      key={`seat-${rowIndex}-${colIndex}`} 
                      cellSize={cellSize}
                      className="seat-container"
                    >
                      {cell ? (
                        <Seat {...seatProps} />
                      ) : (
                        '•'
                      )}
                    </SeatContainer>
                  );
                })}
                <GridCell cellSize={cellSize}>{sortedRowNumbers[rowIndex] || '•'}</GridCell>
              </React.Fragment>
            ))}

            {/* Footer row */}
            <GridCell cellSize={cellSize}>•</GridCell>
            {compressedLetters.map((letter, index) => (
              <GridCell key={`footer-${index}`} cellSize={cellSize}>
                {letter || '•'}
              </GridCell>
            ))}
            <GridCell cellSize={cellSize}>•</GridCell>
          </Grid>
        </DynamicFuselage>
        <DynamicWing $height={gridHeight} rows={totalRows}>
          {Array(totalRows).fill().map((_, index) => {
            const rowNumber = index > 0 && index <= compressedGrid.length ? 
              sortedRowNumbers[index - 1] : null;
            
            return (
              <WingCell 
                key={`right-wing-${index}`}
                cellSize={cellSize}
              >
                {rowNumber && overwingRows.has(rowNumber) ? 'W' : '•'}
              </WingCell>
            );
          })}
        </DynamicWing>
      </MainSection>
      <Tail />
      
      {console.log('Render Debug:', {
        selectedSeat: selectedSeat?.number,
        popupPosition,
        shouldShowPopup: !!selectedSeat
      })}
      
      {selectedSeat && (
        <SeatPopup 
          className="seat-popup"
          $top={popupPosition.top}
          $left={popupPosition.left}
        >
          <PopupTitle>Seat {selectedSeat.number}</PopupTitle>
          <CharacteristicsList>
            {selectedSeat.characteristicsCodes?.map(code => (
              <Characteristic key={code}>
                {characteristicsDictionary[code] || `${code} - Unknown Characteristic`}
              </Characteristic>
            ))}
            <Characteristic>
              {(() => {
                switch(selectedSeat.travelerPricing?.[0]?.seatAvailabilityStatus) {
                  case 'AVAILABLE':
                    return '✅ Available';
                  case 'OCCUPIED':
                    return '❌ Occupied';
                  case 'BLOCKED':
                    return '🚫 Blocked';
                  default:
                    return '❓ Unknown Status';
                }
              })()}
            </Characteristic>
          </CharacteristicsList>
        </SeatPopup>
      )}
    </Container>
  );
}

export default NewSeatmapContainer; 