import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  console.error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Log configuration (but not the actual key)
console.log('Supabase URL:', supabaseUrl);
console.log('Supabase Key exists:', !!supabaseAnonKey);

export async function uploadSeatmap(aircraftCode, airlineCode, imageFile) {
  const { data, error } = await supabase.storage
    .from('seatmaps')
    .upload(`${airlineCode}/${aircraftCode}.png`, imageFile);
  
  if (error) throw error;
  return data;
} 