import styled from 'styled-components';
import { LAYOUT } from '../../styles/layout';

const AppContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: white;
  margin: 0 auto;
  box-sizing: border-box;
  overflow-x: hidden;

  @media (min-width: ${LAYOUT.MOBILE_WIDTH}px) {
    width: ${LAYOUT.MOBILE_WIDTH}px;
  }
`;

export default AppContainer; 