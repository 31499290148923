import styled from 'styled-components';

// Headings
export const H1 = styled.h1`
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 800; // Extra bold
  line-height: 1.2;
`;

export const H2 = styled.h2`
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 800; // Extra bold
  line-height: 1.2;
`;

export const H3 = styled.h3`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 800; // Extra bold
  line-height: 1.2;
`;

export const H4 = styled.h4`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 700; // Bold
  line-height: 1.2;
`;

export const H5 = styled.h5`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 700; // Bold
  line-height: 1.2;
`;

// Body Text
export const BodyXL = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 400; // Regular
  line-height: 1.5;
`;

export const BodyL = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400; // Regular
  line-height: 1.5;
`;

export const BodyM = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400; // Regular
  line-height: 1.5;
`;

export const BodyS = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 400; // Regular
  line-height: 1.5;
`;

export const BodyXS = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-weight: 500; // Medium
  line-height: 1.5;
`;

// Action Text
export const ActionL = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600; // Semi Bold
  line-height: 1.2;
`;

export const ActionM = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 600; // Semi Bold
  line-height: 1.2;
`;

export const ActionS = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-weight: 600; // Semi Bold
  line-height: 1.2;
`;

// Caption
export const CaptionM = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-weight: 600; // Semi Bold
  line-height: 1.2;
`;