import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LAYOUT } from '../../styles/layout';
import { BodyL, BodyM } from '../../styles/typography';

const CardContainer = styled.div`
  width: 100%;
  background: white;
  border-radius: 16px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: ${LAYOUT.CONTENT_PADDING}px;
  box-sizing: border-box;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
`;

const RippleEffect = styled.div`
  position: absolute;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
  transform: scale(0);
  animation: ripple 0.6s linear;
  pointer-events: none;

  @keyframes ripple {
    to {
      transform: scale(4);
      opacity: 0;
    }
  }
`;

const CardLayout = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr;
  gap: ${LAYOUT.CONTENT_PADDING * 0.5}px;
`;

const LogoContainer = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${LAYOUT.CONTENT_PADDING * 0.5}px;
`;

const AirlineLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const FlightInfoTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${LAYOUT.CONTENT_PADDING * 0.5}px;
`;

const FlightIdentifier = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const FlightNumber = styled(BodyL)`
  color: ${props => props.theme.colors.neutral.dark.darkest};
  margin: 0;
  text-align: center;
`;

const DateText = styled(BodyM)`
  color: ${props => props.theme.colors.neutral.dark.medium};
  margin: 0;
  text-align: center;
`;

const FlightDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${LAYOUT.CONTENT_PADDING * 0.2}px;
  width: 100%;
`;

const SectionLabel = styled(BodyM)`
  color: ${props => props.theme.colors.neutral.dark.medium};
  margin: 0;
  text-transform: uppercase;
`;

const AirportInfo = styled(BodyM)`
  color: ${props => props.theme.colors.neutral.dark.darkest};
  margin: 0;
`;

const TimeText = styled(BodyL)`
  color: ${props => props.theme.colors.neutral.dark.darkest};
  margin: 0;
`;

const FlightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1px;
`;

// Add new styled components for the flight stats
const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

const FlightStats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1px;
`;

const StatText = styled(BodyM)`
  color: ${props => props.theme.colors.neutral.dark.medium};
  font-size: 10px;
  margin: 0;
  text-align: center;
`;

const formatAirportName = (name) => {
  if (!name) return '';
  // Remove 'International' and 'Airport' and any trailing spaces/hyphens
  return name
    .replace(/\s*International\s*/i, ' ')
    .replace(/\s*Airport\s*$/i, '')
    .trim();
};

const FlightCard = ({ 
  airlineLogo,
  airlineCode,
  flightNumber,
  departureTime,
  departureAirport,
  departureCode,
  arrivalAirport,
  arrivalCode,
  arrivalTime,
  arrivalTimeLocal,
  distance,
  duration,
  date,
  hasAlert,
  showArrow = true,
  onNavigate,
  style,
  isAddFlight,
}) => {
  const [imgError, setImgError] = useState(false);

  // Date formatting
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    
    const getOrdinal = (n) => {
      const s = ['th', 'st', 'nd', 'rd'];
      const v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    };

    return `${month} ${getOrdinal(day)} ${year}`;
  };

  // Add new function to handle date adjustment
  const getAdjustedDate = (dateString, departureTime) => {
    if (!dateString || !departureTime) return dateString;
    
    // Check if departure has a +1
    const isDepartureDayAfter = departureTime.includes('+1');
    
    if (isDepartureDayAfter) {
      // Add one day to the display date
      const originalDate = new Date(dateString);
      const adjustedDate = new Date(originalDate.setDate(originalDate.getDate() + 1));
      return adjustedDate.toISOString().split('T')[0];
    }
    
    return dateString;
  };

  // Modify formatTime to be relative to adjusted date
  const formatTime = (timeString) => {
    if (!timeString) return 'N/A';
    
    const time = timeString.substring(11, 16);
    const arrivalDate = new Date(timeString.substring(0, 10));
    const displayDate = new Date(getAdjustedDate(date, departureTime));
    
    // Compare dates and adjust the day difference
    if (arrivalDate.getTime() !== displayDate.getTime()) {
      const diffTime = arrivalDate.getTime() - displayDate.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays > 0 ? `${time}+${diffDays}` : time;
    }
    
    return time;
  };

  const formatDuration = (mins) => {
    const hours = Math.floor(mins / 60);
    const minutes = mins % 60;
    return `${hours}h ${minutes}m`;
  };

  // Get the adjusted display date
  const displayDate = getAdjustedDate(date, departureTime);

  return (
    <CardContainer onClick={onNavigate} style={style}>
      <CardLayout>
        <LogoSection>
          <LogoContainer>
            {airlineLogo && !imgError && (
              <AirlineLogo 
                src={airlineLogo} 
                alt={`${airlineCode} logo`}
                onError={() => setImgError(true)}
              />
            )}
          </LogoContainer>
          {(distance || duration) && (
            <FlightStats>
              {distance && <StatText>{distance.toLocaleString()} miles</StatText>}
              {duration && <StatText>{formatDuration(duration)}</StatText>}
            </FlightStats>
          )}
        </LogoSection>
        <ContentContainer>
          <FlightInfoTop>
            <FlightIdentifier>
              <FlightNumber>
                {airlineCode} {flightNumber}
              </FlightNumber>
            </FlightIdentifier>
            <DateText>{formatDate(displayDate)}</DateText>
          </FlightInfoTop>
          
          <FlightDetails>
            <FlightSection>
              <AirportInfo>
                {formatAirportName(departureAirport)} ({departureCode})
              </AirportInfo>
              <TimeText>{formatTime(departureTime)}</TimeText>
            </FlightSection>
            
            <FlightSection>
              <AirportInfo>
                {formatAirportName(arrivalAirport)} ({arrivalCode})
              </AirportInfo>
              <TimeText>{formatTime(arrivalTime)}</TimeText>
            </FlightSection>
          </FlightDetails>
        </ContentContainer>
      </CardLayout>
    </CardContainer>
  );
};

FlightCard.propTypes = {
  airlineLogo: PropTypes.string,
  airlineCode: PropTypes.string,
  flightNumber: PropTypes.string,
  date: PropTypes.string,
  departureAirport: PropTypes.string,
  departureCode: PropTypes.string,
  departureTime: PropTypes.string,
  arrivalAirport: PropTypes.string,
  arrivalCode: PropTypes.string,
  arrivalTime: PropTypes.string,
  arrivalTimeLocal: PropTypes.string,
  distance: PropTypes.number,
  duration: PropTypes.number,
  isAddFlight: PropTypes.bool,
  showArrow: PropTypes.bool
};

export default FlightCard; 