/**
 * MAIN APP FILE - This is the primary App file used by index.js
 * 
 * Route Structure:
 * - Non-authenticated routes (/login, /register)
 * - Authenticated routes (all others)
 * 
 * Note: This file should be kept in sync with App.jsx regarding routes.
 * Any route added to App.jsx should also be added here.
 */

import React, { useState, useEffect, useCallback } from 'react';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import { theme } from './styles/theme';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import MyFlightsPage from './pages/MyFlightsPage';
import OnboardingPage from './pages/OnboardingPage';
import { supabase } from './lib/supabase';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AddFlightPage from './pages/AddFlightPage';
import { LAYOUT } from './styles/layout';
import SeatmapPage from './pages/SeatmapPage';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import FlightAvailabilityPage from './pages/FlightAvailabilityPage';
import ModularFlightDetailPage from './pages/ModularFlightDetailPage';

const AppWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${props => props.theme.colors.neutral.light.lightest};
  display: flex;
  justify-content: center;
`;

const AppContainer = styled.div`
  width: 100%;
  max-width: ${LAYOUT.MOBILE_WIDTH}px;
  min-height: 100vh;
  background: white;
  position: relative;
  overflow-x: hidden;
  box-sizing: border-box;
`;

const PageTransition = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease;
  opacity: ${props => props.$visible ? 1 : 0};
`;

// Create a client
const queryClient = new QueryClient();

function App() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showRegister, setShowRegister] = useState(false);
  const [needsOnboarding, setNeedsOnboarding] = useState(false);
  const [transitioning, setTransitioning] = useState(false);
  const [loadingTimeout, setLoadingTimeout] = useState(false);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;

  // Simplified profile check with memoization
  const checkProfile = useCallback(async (userId) => {
    try {
      const { data: profile, error } = await supabase
        .from('profiles')
        .select('handle')  // Only select what we need
        .eq('id', userId)
        .single();

      if (error?.code === 'PGRST116') {
        await supabase
          .from('profiles')
          .insert([{ id: userId }]);
        return true;
      }
      
      return !profile?.handle;
    } catch (error) {
      console.error('Profile check failed:', error);
      return true;
    }
  }, []);

  // Add retry logic
  const validateConnection = async () => {
    try {
      const { error: pingError } = await supabase.from('profiles').select('count').limit(1);
      if (pingError) {
        throw new Error('Database connection failed');
      }
      return true;
    } catch (error) {
      console.error('Connection validation failed:', error);
      return false;
    }
  };

  const validateSession = async (session) => {
    if (!session?.access_token) return false;
    
    try {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (error) throw error;
      return !!user;
    } catch (error) {
      console.error('Session validation error:', error);
      return false;
    }
  };

  // Update initialization
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        const isValid = await validateSession(session);
        
        if (isValid) {
          setSession(session);
          const needsOnboarding = await checkProfile(session.user.id);
          setNeedsOnboarding(needsOnboarding);
        } else {
          setSession(null);
        }
      } catch (error) {
        console.error('Init error:', error);
        setSession(null);
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();
  }, [checkProfile]);

  const handleRegister = () => {
    setTransitioning(true);
    setTimeout(() => {
      setShowRegister(true);
      setTransitioning(false);
    }, 300);
  };

  const handleBackToLogin = () => {
    setTransitioning(true);
    setTimeout(() => {
      setShowRegister(false);
      setTransitioning(false);
    }, 300);
  };

  const clearAllStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  if (loading && !loadingTimeout) {
    return (
      <ThemeProvider theme={theme}>
        <AppWrapper>
          <AppContainer>
            <div style={{ 
              padding: '20px', 
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px' 
            }}>
              <div>Loading... {retryCount > 0 && `(Attempt ${retryCount}/${MAX_RETRIES})`}</div>
              {error && (
                <>
                  <div style={{ color: 'red', fontSize: '14px' }}>
                    {error}
                  </div>
                  <button 
                    onClick={() => {
                      setError(null);
                      setLoading(true);
                      setLoadingTimeout(false);
                      setRetryCount(0);
                    }}
                    style={{
                      padding: '8px 16px',
                      marginTop: '10px',
                      border: 'none',
                      borderRadius: '4px',
                      background: '#007AFF',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                  >
                    Retry Connection
                  </button>
                </>
              )}
            </div>
          </AppContainer>
        </AppWrapper>
      </ThemeProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AppWrapper>
            <AppContainer>
              {error && (
                <div style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  background: '#ff5555',
                  color: 'white',
                  padding: '8px',
                  textAlign: 'center',
                  zIndex: 1000,
                  fontSize: '14px'
                }}>
                  {error}
                </div>
              )}
              <Routes>
                {/* Non-authenticated routes */}
                {!session ? (
                  <>
                    <Route path="/login" element={
                      <PageTransition $visible={!transitioning}>
                        <LoginPage onRegister={handleRegister} />
                      </PageTransition>
                    } />
                    <Route path="/register" element={
                      <PageTransition $visible={!transitioning}>
                        <RegisterPage onBackToLogin={handleBackToLogin} />
                      </PageTransition>
                    } />
                    <Route path="*" element={<Navigate to="/login" replace />} />
                  </>
                ) : (
                  /* Authenticated routes */
                  <>
                    {/* Main routes */}
                    <Route path="/flights" element={<MyFlightsPage clearAllStorage={clearAllStorage} />} />
                    <Route path="/flights/:id/modular" element={<ModularFlightDetailPage />} />
                    <Route path="/flights/add" element={
                      <AddFlightPage 
                        clearAllStorage={clearAllStorage}
                        isOpen={true}
                      />
                    } />
                    
                    {/* Feature routes */}
                    <Route path="/flight-availability" element={<FlightAvailabilityPage />} />
                    <Route path="/flights/:id/seat" element={<SeatmapPage />} />
                    
                    {/* Special routes */}
                    <Route path="/onboarding" element={<OnboardingPage onComplete={() => setNeedsOnboarding(false)} />} />
                    
                    {/* Catch-all redirect */}
                    <Route path="*" element={<Navigate to="/flights" replace />} />
                  </>
                )}
              </Routes>
            </AppContainer>
          </AppWrapper>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
