import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LAYOUT } from '../../styles/layout';
import SeatSelector from '../SeatSelector/SeatSelector';
import FlightCabinSelector from '../FlightCabinSelector/FlightCabinSelector';
import BookingRefSelector from '../BookingRefSelector/BookingRefSelector';
import ETicketSelector from '../ETicketSelector/ETicketSelector';
import FlightLoadGauge from '../FlightLoadGauge/FlightLoadGauge';

const CardContainer = styled.div`
  width: 100%;
  background: white;
  border-radius: 16px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  position: relative;
  height: 150px;
`;

const SeatSelectorWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 33%;
  height: 66%;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 8px;
`;

const BookingRefWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 33%;
  width: 33%;
  height: 50%;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 8px;
`;

const ETicketWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 33%;
  width: 33%;
  height: 50%;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 8px;
`;

const FlightLoadWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 66%;
  width: 34%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 8px;
`;

const CabinSelectorWrapper = styled.div`
  position: absolute;
  top: 66%;
  left: 0;
  width: 33%;
  height: 33%;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 8px;
`;

function FlightDetailsCard({ flight, onFlightUpdate }) {
  const handleSeatUpdate = (seatNumber) => {
    onFlightUpdate({ seat_number: seatNumber });
  };

  const handleCabinUpdate = (cabinClass) => {
    console.log('FlightDetailsCard - handleCabinUpdate:', {
      newCabinClass: cabinClass,
      currentFlight: flight
    });
    onFlightUpdate({ seat_class: cabinClass });
  };

  const handleBookingRefUpdate = (bookingRef) => {
    onFlightUpdate({ pnr: bookingRef });
  };

  return (
    <CardContainer>
      <SeatSelectorWrapper>
        <SeatSelector 
          seatNumber={flight.seat_number}
          flightId={flight.id}
          flight={flight}
          onUpdate={handleSeatUpdate}
        />
      </SeatSelectorWrapper>
      <BookingRefWrapper>
        <BookingRefSelector 
          bookingRef={flight.pnr}
          flightId={flight.id}
          onUpdate={handleBookingRefUpdate}
        />
      </BookingRefWrapper>
      <ETicketWrapper>
        <ETicketSelector 
          eticket={flight.eticket_number}
          flightId={flight.id}
        />
      </ETicketWrapper>
      <FlightLoadWrapper>
        <FlightLoadGauge 
          flightLoad={flight.flight_load}
        />
      </FlightLoadWrapper>
      <CabinSelectorWrapper>
        <FlightCabinSelector 
          cabinClass={flight.seat_class}
          flightId={flight.id}
          onUpdate={handleCabinUpdate}
        />
      </CabinSelectorWrapper>
    </CardContainer>
  );
}

FlightDetailsCard.propTypes = {
  flight: PropTypes.object.isRequired,
  onFlightUpdate: PropTypes.func.isRequired
};

export default FlightDetailsCard; 