import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';

const CardContainer = styled.div`
  padding: 12px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 8px;
  margin-bottom: 8px;
`;

const Title = styled.h4`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
`;

const Rating = styled.div`
  margin: 4px 0;
  color: ${props => props.theme.colors.brand.gold};
`;

const Description = styled.p`
  margin: 8px 0;
  font-size: 14px;
  color: ${props => props.theme.colors.neutral.dark.base};
  line-height: 1.4;
`;

const Link = styled.a`
  color: ${props => props.theme.colors.brand.primary};
  text-decoration: none;
  font-size: 14px;
  
  &:hover {
    text-decoration: underline;
  }
`;

function TransportOptionCard({ title, rating, description, url }) {
  const renderStars = () => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <FontAwesomeIcon 
          key={i}
          icon={i <= rating ? faStarSolid : faStarRegular}
          style={{ marginRight: '2px' }}
        />
      );
    }
    return stars;
  };

  return (
    <CardContainer>
      <Title>{title}</Title>
      <Rating>{renderStars()}</Rating>
      <Description>{description}</Description>
      {url && <Link href={url} target="_blank" rel="noopener noreferrer">More Info →</Link>}
    </CardContainer>
  );
}

TransportOptionCard.propTypes = {
  title: PropTypes.string.isRequired,
  rating: PropTypes.number,
  description: PropTypes.string.isRequired,
  url: PropTypes.string
};

export default TransportOptionCard; 