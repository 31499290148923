import React, { useState } from 'react';
import styled from 'styled-components';
import { H1, BodyM } from '../styles/typography';
import { supabase } from '../lib/supabase';
import { useNavigate } from 'react-router-dom';

const PageContainer = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.colors.neutral.light.lightest};
`;

const LogoContainer = styled.div`
  width: 100%;
  height: 208px;
  background-color: ${props => props.theme.colors.highlight.lightest};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  padding-top: 6px;
`;

const Logo = styled.img`
  width: 196px;
  height: 196px;
`;

const Title = styled(H1)`
  margin-bottom: 16px;
  padding: 0 24px;
`;

const Form = styled.form`
  width: 100%;
  max-width: 428px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
  box-sizing: border-box;
`;

const Input = styled.input`
  width: 100%;
  padding: 16px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.highlight.darkest};
  }
`;

const LoginButton = styled.button`
  width: 100%;
  padding: 16px;
  background-color: ${props => props.theme.colors.highlight.darkest};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin: 4px 0;
`;

const ForgotPassword = styled(BodyM)`
  color: ${props => props.theme.colors.highlight.darkest};
  cursor: pointer;
  text-align: left;
  padding: 0;
  margin: 4px 0;
`;

const ErrorMessage = styled(BodyM)`
  color: ${props => props.theme.colors.support.error.dark};
  text-align: center;
  margin-bottom: 16px;
`;

function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isRegistering, setIsRegistering] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setError(null);
      setLoading(true);
      
      // First sign in
      const { data: signInData, error: signInError } = await supabase.auth.signInWithPassword({
        email: email,
        password,
      });

      if (signInError) throw signInError;
      if (!signInData?.user) throw new Error('No user data received');

      // Wait a moment for the session to be established
      await new Promise(resolve => setTimeout(resolve, 500));

      // Double check the session
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;
      if (!session) throw new Error('No session established');

      console.log('Login successful, navigating to flights...');
      
      // Force a page reload to ensure clean state
      window.location.href = '/flights';
      
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message);
      
      // Clear any partial session data on error
      localStorage.removeItem(`sb-${process.env.REACT_APP_SUPABASE_PROJECT_ID}-auth-token`);
      await supabase.auth.signOut();
      
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    try {
      setLoading(true);
      console.log('Requesting password reset for:', email);
      
      const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: 'https://flyercopilot.com/auth/callback',
      });

      console.log('Password reset response:', { data, error });
      if (error) throw error;
      
      alert('Check your email for password reset instructions');
    } catch (error) {
      console.error('Password reset error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      setError(null);
      setLoading(true);

      if (password !== confirmPassword) {
        throw new Error('Passwords do not match');
      }

      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: 'https://flyercopilot.com/auth/callback'
        }
      });

      if (error) throw error;
      if (!data?.user) throw new Error('No user data received');

      alert('Check your email to confirm your account');
      setIsRegistering(false);
      
    } catch (error) {
      console.error('Registration error:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer>
      <LogoContainer>
        <Logo src="/logo.png" alt="FlyerCopilot Logo" />
      </LogoContainer>
      <Title>Welcome to FlyerCopilot!</Title>
      <Form onSubmit={isRegistering ? handleRegister : handleLogin}>
        <Input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
          autoComplete="email"
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
          autoComplete={isRegistering ? 'new-password' : 'current-password'}
        />
        {isRegistering && (
          <Input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            disabled={loading}
            autoComplete="new-password"
          />
        )}
        {!isRegistering && (
          <ForgotPassword onClick={handleForgotPassword}>
            Forgot password?
          </ForgotPassword>
        )}
        <LoginButton type="submit" disabled={loading}>
          {loading ? (isRegistering ? 'Registering...' : 'Logging in...') 
            : (isRegistering ? 'Register' : 'Login')}
        </LoginButton>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <BodyM 
          style={{ textAlign: 'center', cursor: 'pointer', color: '#666' }}
          onClick={() => {
            setError(null);
            setIsRegistering(!isRegistering);
          }}
        >
          {isRegistering 
            ? 'Already have an account? Login' 
            : 'Need an account? Register'}
        </BodyM>
      </Form>
    </PageContainer>
  );
}

export default LoginPage; 