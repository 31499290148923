import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import MainHeader from '../components/MainHeader/MainHeader';
import SubHeader from '../components/SubHeader/SubHeader';
import ScrollableContent from '../components/layout/ScrollableContent';
import FlightCard from '../components/FlightCard/FlightCard';
import { supabase } from '../lib/supabase';
import { useNavigate } from 'react-router-dom';
import AppLayout from '../components/layout/AppContainer';
import { LAYOUT } from '../styles/layout';
import PropTypes from 'prop-types';

const FlightsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${LAYOUT.CONTENT_PADDING}px;
`;

const FloatingButton = styled.button`
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.highlight.darkest};
  color: white;
  border: none;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3), 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  z-index: 100;
  opacity: ${props => props.$visible ? 1 : 0};
  transform: ${props => props.$visible ? 'scale(1) translateY(0)' : 'scale(0.8) translateY(20px)'};

  line-height: 0;
  padding: 0;

  & > span {
    margin-top: -2px;
  }

  &:hover {
    transform: ${props => props.$visible ? 'scale(1.05) translateY(0)' : 'scale(0.8) translateY(20px)'};
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.35), 0 8px 15px rgba(0, 0, 0, 0.25);
  }

  &:active {
    transform: ${props => props.$visible ? 'scale(0.95) translateY(0)' : 'scale(0.8) translateY(20px)'};
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.25), 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  @media (min-width: 429px) {
    right: calc(50% - 214px + 24px);
  }
`;

function MyFlightsPage({ clearAllStorage }) {
  const navigate = useNavigate();
  const [flights, setFlights] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;

  const handleSignOut = useCallback(async () => {
    try {
      // 1. Sign out from Supabase
      await supabase.auth.signOut();
      
      // 2. Clear storage
      localStorage.clear();
      sessionStorage.clear();
      
      // 3. Navigate to login WITHOUT triggering re-renders
      window.location.href = '/';
    } catch (error) {
      console.error('Error signing out:', error);
      window.location.href = '/';
    }
  }, []);

  const fetchFlights = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session) {
        // Don't call handleSignOut here, just redirect
        window.location.href = '/';
        return;
      }

      const { data, error } = await supabase
        .from('flights')
        .select(`
          *,
          airlines (
            code,
            name,
            logo_url
          ),
          departure:airports!flights_departure_airport_code_fkey(*),
          arrival:airports!flights_arrival_airport_code_fkey(*)
        `)
        .eq('user_email', session.user.email.toLowerCase())
        .gte('arrival_time', new Date(Date.now() - 4 * 60 * 60 * 1000).toISOString())
        .order('departure_time', { ascending: true });

      if (error) throw error;

      setFlights(data);
      setRetryCount(0);
    } catch (err) {
      console.error('Error fetching flights:', err);
      
      if (err.message.includes('Auth session missing')) {
        window.location.href = '/';
        return;
      }
      
      if (retryCount < MAX_RETRIES) {
        console.log(`Retrying fetch (${retryCount + 1}/${MAX_RETRIES})...`);
        setRetryCount(prev => prev + 1);
        setTimeout(fetchFlights, 2000);
      }
      
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchFlights();
  }, [fetchFlights]);

  useEffect(() => {
    const timer = setTimeout(() => setButtonVisible(true), 300);
    return () => clearTimeout(timer);
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    
    const getOrdinal = (n) => {
      const s = ['th', 'st', 'nd', 'rd'];
      const v = n % 100;
      return n + (s[(v - 20) % 10] || s[v] || s[0]);
    };

    return date.toLocaleDateString('en-US', {
      month: 'long',
      year: 'numeric'
    }).replace(',', '') + ' ' + getOrdinal(day);
  }

  function formatTime(dateString) {
    return new Date(dateString).toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  function formatTimeLocal(dateString, timezone) {
    if (!timezone) return formatTime(dateString);
    
    try {
      return new Date(dateString).toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: timezone
      });
    } catch (error) {
      console.error('Error formatting local time:', error);
      return formatTime(dateString);
    }
  }

  const handleAddFlight = () => {
    navigate('/flights/add');
  };

  const handleFlightClick = (flight) => {
    navigate(`/flights/${flight.id}/modular`, {
      state: { flight }
    });
  };

  return (
    <>
      <MainHeader onSignOut={handleSignOut} />
      <SubHeader mainPage="My Flights" />
      <ScrollableContent>
        <FlightsList>
          {loading ? (
            <div>Loading{retryCount > 0 ? ` (Attempt ${retryCount}/${MAX_RETRIES})` : ''}...</div>
          ) : error ? (
            <div style={{ 
              padding: '20px', 
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px' 
            }}>
              <div style={{ color: 'red', fontSize: '14px' }}>
                {error}
              </div>
              <button 
                onClick={() => {
                  setError(null);
                  setRetryCount(0);
                  fetchFlights();
                }}
                style={{
                  padding: '8px 16px',
                  marginTop: '10px',
                  border: 'none',
                  borderRadius: '4px',
                  background: '#007AFF',
                  color: 'white',
                  cursor: 'pointer'
                }}
              >
                Retry Loading Flights
              </button>
            </div>
          ) : flights.length === 0 ? (
            <div>No flights found</div>
          ) : (
            flights.map(flight => {
              if (!flight || !flight.departure || !flight.arrival) {
                return null;
              }

              return (
                <FlightCard 
                  key={flight.id}
                  airlineLogo={flight.airlines?.logo_url}
                  airlineCode={flight.airlines?.code}
                  airlineName={flight.airlines?.name}
                  flightNumber={flight.flight_number}
                  date={flight.departure_time}
                  departureAirport={flight.departure.name}
                  departureCode={flight.departure.code}
                  departureTime={flight.departure_time}
                  arrivalAirport={flight.arrival.name}
                  arrivalCode={flight.arrival.code}
                  arrivalTime={flight.arrival_time}
                  arrivalTimeLocal={flight.arrival_time_local}
                  arrivalTimezone={flight.arrival.timezone}
                  distance={flight.distance_miles}
                  duration={flight.duration_mins}
                  hasAlert={!!flight.alert_type}
                  showArrow={true}
                  onNavigate={() => handleFlightClick(flight)}
                />
              );
            }).filter(Boolean)
          )}
        </FlightsList>
      </ScrollableContent>
      <FloatingButton 
        onClick={handleAddFlight}
        $visible={buttonVisible}
      >
        +
      </FloatingButton>
    </>
  );
}

MyFlightsPage.propTypes = {
  clearAllStorage: PropTypes.func.isRequired
};

export default MyFlightsPage;
