import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import GaugeChart from 'react-gauge-chart';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 12px;
  color: ${props => props.theme.colors.neutral.dark.base};
  text-align: center;
  margin-bottom: 12px;
`;

const GaugeWrapper = styled.div`
  padding: 0 4px;
  margin-bottom: 12px;
`;

const Value = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.neutral.dark.base};
  text-align: center;
`;

const Dialog = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  text-align: center;
`;

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const Message = styled.p`
  margin: 0 0 16px 0;
  color: ${props => props.theme.colors.neutral.dark.darkest};
`;

const CloseButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 8px;
  background: ${props => props.theme.colors.neutral.light.base};
  color: ${props => props.theme.colors.neutral.dark.darkest};
  cursor: pointer;
  
  &:hover {
    background: ${props => props.theme.colors.neutral.light.dark};
  }
`;

function FlightLoadGauge({ flightLoad }) {
  const [showDialog, setShowDialog] = useState(false);

  const gaugeColors = flightLoad === null ? 
    ["#E0E0E0", "#E0E0E0", "#E0E0E0"] :  // Grey when null
    ["#4CAF50", "#FFC107", "#F44336"];    // Green, Yellow, Red

  const needleColor = flightLoad === null ? "#BDBDBD" : "#A99968";
  const percent = (flightLoad ?? 50) / 100;  // Default to 50% if null

  return (
    <Wrapper>
      <Title>Flight Load</Title>
      <GaugeWrapper>
        <GaugeChart 
          id="flight-load-gauge"
          nrOfLevels={3}
          colors={gaugeColors}
          percent={percent}
          arcWidth={0.3}
          marginInPercent={0}
          needleColor={needleColor}
          needleBaseColor={needleColor}
          hideText={true}
          cornerRadius={0}
          onClick={() => setShowDialog(true)}
        />
      </GaugeWrapper>
      <Value>{flightLoad === null ? '—' : `${flightLoad}%`}</Value>

      {showDialog && (
        <>
          <DialogOverlay onClick={() => setShowDialog(false)} />
          <Dialog>
            <Message>Future Functionality - Matthew rewriting from scratch</Message>
            <CloseButton onClick={() => setShowDialog(false)}>Close</CloseButton>
          </Dialog>
        </>
      )}
    </Wrapper>
  );
}

FlightLoadGauge.propTypes = {
  flightLoad: PropTypes.number
};

export default FlightLoadGauge; 