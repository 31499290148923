import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AirportCategorySelector from '../AirportCategorySelector/AirportCategorySelector';
import AirportTransportCard from '../AirportTransportCard/AirportTransportCard';
import { supabase } from '../../lib/supabase';

const CardContainer = styled.div`
  width: 100%;
  background: white;
  border-radius: 16px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  position: relative;
`;

const FlipText = styled.div`
  font-family: 'SplitFlapTVBlackLine', monospace;
  font-size: 20px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 2px;
  display: inline-block;
`;

const AirportName = styled.h3`
  display: inline-block;
  margin-left: 8px;
  font-weight: 500;
`;

const AirportLine = styled.div`
  display: flex;
  align-items: center;
`;

const CategoryContent = styled.div`
  padding: 0 8px;
`;

function AirportDetailsCard({ flight }) {
  const [selectedCategory, setSelectedCategory] = useState('Getting to Airport');
  const [airportMetadata, setAirportMetadata] = useState(null);

  useEffect(() => {
    async function fetchAirportMetadata() {
      const airportCode = flight?.departure?.code;
      console.log('=== Metadata Fetch Debug ===');
      console.log('Attempting to fetch metadata for airport:', airportCode);
      
      try {
        const { data, error } = await supabase
          .from('airport_metadata')
          .select('*')
          .eq('airport_code', airportCode)
          .order('rating', { ascending: false, nullsLast: true })
          .order('subcategory_id');

        if (error) {
          console.error('Supabase Error:', error);
          return;
        }

        console.log('Supabase Query Results:', {
          count: data?.length || 0,
          data: data
        });
        
        setAirportMetadata(data);
      } catch (err) {
        console.error('Exception in metadata fetch:', err);
      }
    }

    if (flight?.departure?.code) {
      fetchAirportMetadata();
    }
  }, [flight?.departure?.code]);

  const categories = [
    'Getting to Airport',
    'Departures',
    'Lounges',
    'Food',
    'Shopping',
    'Services'
  ];

  const renderCategoryContent = () => {
    console.log('=== AirportDetailsCard Debug ===');
    console.log('Full flight object:', flight);
    console.log('Selected category:', selectedCategory);
    console.log('Departure data being passed:', flight?.departure);
    console.log('Airport metadata:', airportMetadata);
    
    switch(selectedCategory) {
      case 'Getting to Airport':
        return (
          <CategoryContent>
            <AirportTransportCard 
              airport={{
                ...flight.departure,
                metadata: airportMetadata
              }} 
            />
          </CategoryContent>
        );
      default:
        return null;
    }
  };

  return (
    <CardContainer>
      {flight?.departure?.code && (
        <>
          <AirportLine>
            <FlipText>{flight.departure.code}</FlipText>
            <span> - </span>
            <AirportName>{flight.departure.name}</AirportName>
          </AirportLine>
          <AirportCategorySelector 
            categories={categories}
            selectedCategory={selectedCategory}
            onCategorySelect={setSelectedCategory}
          />
          {renderCategoryContent()}
        </>
      )}
    </CardContainer>
  );
}

AirportDetailsCard.propTypes = {
  flight: PropTypes.object.isRequired
};

export default AirportDetailsCard; 