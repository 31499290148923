import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { supabase } from '../../lib/supabase';

const SelectorContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const CabinDisplay = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CabinClass = styled.div`
  color: #A99968;
  font-weight: 600;
  font-size: 16px;
  padding: 6px 16px;
  border: 1px solid #A99968;
  border-radius: 16px;
  background-color: rgba(169, 153, 104, 0.05);
  box-shadow: 0 1px 2px rgba(169, 153, 104, 0.1);
  text-align: center;
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba(169, 153, 104, 0.1);
  }
`;

const DialogOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1999;
`;

const CabinDialog = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 24px;
  border-radius: 24px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
  z-index: 2000;
  width: 80%;
  max-width: 320px;
  text-align: center;
`;

const DialogTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 16px 0;
  color: ${props => props.theme.colors.neutral.dark.darkest};
`;

const DialogText = styled.p`
  font-size: 16px;
  margin: 0 0 32px 0;
  color: ${props => props.theme.colors.neutral.dark.base};
`;

const DialogButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
`;

const DialogButton = styled.button`
  padding: 12px;
  border-radius: 12px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  background: ${props => props.theme.colors.neutral.light.lightest};
  color: ${props => props.theme.colors.neutral.dark.darkest};
  transition: all 0.2s ease;

  &:hover {
    background: ${props => props.theme.colors.neutral.light.dark};
  }

  &:active {
    transform: scale(0.98);
  }
`;

function FlightCabinSelector({ cabinClass, flightId, onUpdate }) {
  const [isSelecting, setIsSelecting] = useState(false);

  console.log('FlightCabinSelector render:', {
    cabinClass,
    flightId
  });

  const handleCabinSelect = async (selectedClass) => {
    console.log('FlightCabinSelector - handleCabinSelect:', {
      selectedClass,
      currentCabinClass: cabinClass,
      flightId
    });

    try {
      const { data, error } = await supabase
        .from('flights')
        .update({ seat_class: selectedClass })
        .eq('id', flightId)
        .single();

      if (error) throw error;
      
      console.log('FlightCabinSelector - DB update success:', {
        selectedClass,
        dbResponse: data
      });

      // Only notify parent, no local state
      if (onUpdate) {
        onUpdate(selectedClass);
      }
      setIsSelecting(false);
    } catch (error) {
      console.error('FlightCabinSelector - Error:', error);
    }
  };

  return (
    <>
      <SelectorContainer onClick={() => setIsSelecting(true)}>
        <CabinDisplay>
          <CabinClass>
            {cabinClass}
          </CabinClass>
        </CabinDisplay>
      </SelectorContainer>

      {isSelecting && (
        <>
          <DialogOverlay onClick={() => setIsSelecting(false)} />
          <CabinDialog>
            <DialogTitle>Select Cabin Class</DialogTitle>
            <DialogText>
              {cabinClass ? 'Change your cabin class' : 'Select your cabin class'}
            </DialogText>
            <DialogButtons>
              <DialogButton onClick={() => handleCabinSelect('Economy')}>
                Economy
              </DialogButton>
              <DialogButton onClick={() => handleCabinSelect('Premium Economy')}>
                Premium Economy
              </DialogButton>
              <DialogButton onClick={() => handleCabinSelect('Business')}>
                Business
              </DialogButton>
              <DialogButton onClick={() => handleCabinSelect('First')}>
                First
              </DialogButton>
            </DialogButtons>
          </CabinDialog>
        </>
      )}
    </>
  );
}

FlightCabinSelector.propTypes = {
  cabinClass: PropTypes.string,
  flightId: PropTypes.string.isRequired,
  onUpdate: PropTypes.func
};

export default FlightCabinSelector; 