import { colors } from './colors';

export const theme = {
  colors: {
    ...colors,
    highlight: {
      ...colors.highlight,
      lightest: '#E6F0FF',
    },
  },
  // You can add other theme properties here like spacing, breakpoints, etc.
};
