async function getAmadeusToken() {
    const AMADEUS_API_KEY = 'aY35bGbswqzJXVvxV6VvyYCYnAUO4Ekm'
    const AMADEUS_API_SECRET = 'qhGFgAfvqFch3ARf'

    const response = await fetch('https://api.amadeus.com/v1/security/oauth2/token', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            grant_type: 'client_credentials',
            client_id: AMADEUS_API_KEY,
            client_secret: AMADEUS_API_SECRET
        })
    })

    const tokenData = await response.json()
    return tokenData.access_token
}

export async function getAirportDetails(airportCode) {
  try {
    const token = await getAmadeusToken();
    const apiUrl = `https://api.amadeus.com/v1/reference-data/locations?subType=CITY,AIRPORT&keyword=${airportCode}`;

    console.log('=== Making Amadeus Airport API Call ===');
    console.log('Looking up airport:', airportCode);

    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': 'application/json'
      }
    });

    const data = await response.json();
    console.log('Airport API Response:', {
      meta: data.meta,
      airportResult: data.data?.find(item => 
        item.subType === 'AIRPORT' && 
        item.iataCode === airportCode
      )
    });
    return data;
  } catch (error) {
    console.error('Error fetching airport details:', error);
    throw error;
  }
}

export { getAmadeusToken }; 