import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MainHeader from '../components/MainHeader/MainHeader';
import SubHeader from '../components/SubHeader/SubHeader';
import ScrollableContent from '../components/layout/ScrollableContent';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import FlightMap from '../components/FlightMap/FlightMap';
import FlightCard from '../components/FlightCard/FlightCard';
import FlightDetailsCard from '../components/FlightDetailsCard/FlightDetailsCard';
import AirportDetailsCard from '../components/AirportDetailsCard/AirportDetailsCard';
import { supabase } from '../lib/supabase';

const MapSection = styled.div`
  height: 200px;
  margin: 1px;
  margin-bottom: 16px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
`;

const CardSection = styled.div`
  margin: 1px;
  margin-bottom: 16px;
`;

const DetailsSection = styled.div`
  margin: 1px;
  margin-bottom: 16px;
`;

function ModularFlightDetailPage() {
  const { id } = useParams();
  const { state } = useLocation();
  const [flight, setFlight] = useState(state?.flight);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFlight = async () => {
      try {
        const { data, error } = await supabase
          .from('flights')
          .select(`
            *,
            airlines (
              name,
              logo_url
            ),
            departure:departure_airport_code (*),
            arrival:arrival_airport_code (*)
          `)
          .eq('id', id)
          .single();

        if (error) throw error;
        console.log('ModularFlightDetailPage - Fresh data fetched:', data);
        setFlight(data);
      } catch (error) {
        console.error('Error fetching flight:', error);
      }
    };

    fetchFlight();
  }, [id]);

  const handleFlightUpdate = (updates) => {
    setFlight(prev => ({
      ...prev,
      ...updates
    }));
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleMainClick = () => {
    navigate('/flights');
  };

  return (
    <>
      <MainHeader />
      <SubHeader 
        mainPage="My Flights"
        subPage="Flight Details"
        onBack={handleBack}
        onMainClick={handleMainClick}
      />
      <ScrollableContent>
        <MapSection>
          {flight && (
            <FlightMap 
              departure={flight.departure}
              arrival={flight.arrival}
            />
          )}
        </MapSection>
        <CardSection>
          {flight && (
            <FlightCard 
              airlineLogo={flight.airlines?.logo_url}
              airlineCode={flight.airlines?.code}
              flightNumber={flight.flight_number}
              date={flight.departure_time}
              departureAirport={flight.departure.name}
              departureCode={flight.departure.code}
              departureTime={flight.departure_time}
              arrivalAirport={flight.arrival.name}
              arrivalCode={flight.arrival.code}
              arrivalTime={flight.arrival_time}
              distance={flight.distance_miles}
              duration={flight.duration_mins}
              showArrow={false}
            />
          )}
        </CardSection>
        <DetailsSection>
          {flight && (
            <FlightDetailsCard 
              flight={flight}
              onFlightUpdate={handleFlightUpdate}
            />
          )}
        </DetailsSection>
        <DetailsSection>
          {flight && <AirportDetailsCard flight={flight} />}
        </DetailsSection>
      </ScrollableContent>
    </>
  );
}

export default ModularFlightDetailPage; 