import React from 'react';
import styled from 'styled-components';
import { LAYOUT } from '../../styles/layout';
import { BodyL } from '../../styles/typography';
import BackArrowIcon from '../icons/BackArrowIcon';

const SubHeaderContainer = styled.header`
  position: fixed;
  top: ${LAYOUT.MAIN_HEADER_HEIGHT}px;
  left: 0;
  width: 100%;
  height: ${LAYOUT.SUB_HEADER_HEIGHT}px;
  background: white;
  z-index: 99;
  display: flex;
  align-items: center;
  padding: 0 ${LAYOUT.CONTENT_PADDING}px;
  box-sizing: border-box;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.light.dark};

  @media (min-width: ${LAYOUT.MOBILE_WIDTH}px) {
    width: ${LAYOUT.MOBILE_WIDTH}px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const BackButton = styled.button`
  border: none;
  background: none;
  padding: 8px;
  margin: -8px 4px -8px -8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.highlight.darkest};
`;

const PageTitle = styled(BodyL)`
  color: ${props => props.theme.colors.neutral.dark.darkest};
  font-weight: 600;
`;

const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  white-space: nowrap;
`;

const MainPageLink = styled.span`
  color: ${props => props.theme.colors.neutral.dark.base};
  cursor: pointer;
  
  &:hover {
    color: ${props => props.theme.colors.highlight.darkest};
  }
`;

const SubPageLink = styled(MainPageLink)`
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CurrentPage = styled.span`
  color: ${props => props.theme.colors.neutral.dark.darkest};
  font-weight: 600;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Separator = styled.span`
  color: ${props => props.theme.colors.neutral.dark.light};
  margin: 0 2px;
  flex-shrink: 0;
`;

function SubHeader({ 
  mainPage,      // Name of the main page
  subPage,       // Name of the sub page (optional)
  subSubPage,    // Name of the sub-sub page (optional)
  onBack,        // Navigation function
  onMainClick,   // Function to navigate to main page
  onSubClick     // Function to navigate to sub page (optional)
}) {
  // If we're on a sub-sub page
  if (subSubPage) {
    return (
      <SubHeaderContainer>
        <BackButton onClick={onBack}>
          <BackArrowIcon size={20} />
        </BackButton>
        <NavigationContainer>
          <MainPageLink onClick={onMainClick}>
            {mainPage}
          </MainPageLink>
          <Separator>›</Separator>
          <SubPageLink onClick={onSubClick}>
            {subPage}
          </SubPageLink>
          <Separator>›</Separator>
          <CurrentPage>{subSubPage}</CurrentPage>
        </NavigationContainer>
      </SubHeaderContainer>
    );
  }

  // If we're on a sub page
  if (subPage) {
    return (
      <SubHeaderContainer>
        <BackButton onClick={onBack}>
          <BackArrowIcon size={20} />
        </BackButton>
        <NavigationContainer>
          <MainPageLink onClick={onMainClick}>
            {mainPage}
          </MainPageLink>
          <Separator>›</Separator>
          <CurrentPage>{subPage}</CurrentPage>
        </NavigationContainer>
      </SubHeaderContainer>
    );
  }

  // If we're on a main page
  return (
    <SubHeaderContainer>
      <PageTitle>{mainPage}</PageTitle>
    </SubHeaderContainer>
  );
}

export default SubHeader; 