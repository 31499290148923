import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BodyS } from '../../styles/typography';

const SelectorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px;
`;

const CategoryLozenge = styled(BodyS)`
  padding: 4px 12px;
  border-radius: 16px;
  cursor: pointer;
  background: ${props => props.isSelected ? 
    props.theme.colors.highlight.darkest : 
    props.theme.colors.highlight.lightest
  };
  color: ${props => props.isSelected ? 
    'white' : 
    props.theme.colors.neutral.dark.darkest
  };
  transition: all 0.2s ease;
  margin: 0;

  &:hover {
    background: ${props => props.isSelected ? 
      props.theme.colors.highlight.dark : 
      props.theme.colors.highlight.light
    };
  }
`;

function AirportCategorySelector({ categories, selectedCategory, onCategorySelect }) {
  return (
    <SelectorContainer>
      {categories.map(category => (
        <CategoryLozenge 
          key={category}
          isSelected={selectedCategory === category}
          onClick={() => onCategorySelect(category)}
        >
          {category}
        </CategoryLozenge>
      ))}
    </SelectorContainer>
  );
}

AirportCategorySelector.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedCategory: PropTypes.string.isRequired,
  onCategorySelect: PropTypes.func.isRequired
};

export default AirportCategorySelector; 