import React, { useState, useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import styled from 'styled-components';
import { H1, BodyM } from '../styles/typography';
import { LAYOUT } from '../styles/layout';
import { supabase } from '../lib/supabase';
import { findFlight } from '../api/flights';
import FlightCard from '../components/FlightCard/FlightCard';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import MainHeader from '../components/MainHeader/MainHeader';
import SubHeader from '../components/SubHeader/SubHeader';
import { getAirportDetails } from '../api/auth';
import { getAirportTimezone } from '../constants/timezones';

const DetailPageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: min(428px, 100vw);
  height: 100vh;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const HeaderArea = styled.div`
  width: 100%;
  height: ${LAYOUT.TOTAL_HEADER_HEIGHT}px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
`;

const ContentArea = styled.div`
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: ${LAYOUT.CONTENT_PADDING}px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${LAYOUT.CONTENT_PADDING * 2}px;
  margin: 0 auto;
  padding-top: ${LAYOUT.CONTENT_PADDING}px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${LAYOUT.CONTENT_PADDING}px;
`;

const Label = styled.label`
  font-weight: 500;
  color: ${props => props.theme.colors.neutral.dark.darkest};
`;

const Select = styled.select`
  width: 100%;
  padding: 16px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 8px;
  font-size: 16px;
  background-color: white;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.highlight.darkest};
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 16px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 8px;
  font-size: 16px;
  background-color: white;
  height: 53px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.highlight.darkest};
  }

  &::placeholder {
    color: ${props => props.theme.colors.neutral.dark.light};
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 16px;
  background-color: ${props => props.theme.colors.highlight.darkest};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 16px;
`;

const ErrorMessage = styled(BodyM)`
  color: ${props => props.theme.colors.support.error.dark};
  font-size: 14px;
  margin-top: 4px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 16px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 8px 8px 0 0;
  font-size: 16px;
  border-bottom: none;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.highlight.darkest};
  }
`;

const DropdownList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 0 0 8px 8px;
  background: white;
`;

const DropdownItem = styled.li`
  padding: 12px 16px;
  cursor: pointer;
  
  &:hover {
    background-color: ${props => props.theme.colors.neutral.light.lightest};
  }
`;

const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 16px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 8px;
  font-size: 16px;
  background-color: white;
  height: 53px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.highlight.darkest};
  }

  &::placeholder {
    color: ${props => props.theme.colors.neutral.dark.light};
  }

  // Override react-datepicker styles
  &.react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
    width: 100%;
  }

  .react-datepicker__input-container input {
    width: 100%;
    height: 53px;
    padding: 16px;
    border: 1px solid ${props => props.theme.colors.neutral.light.dark};
    border-radius: 8px;
    font-size: 16px;
    background-color: white;
    box-sizing: border-box;
    
    &:focus {
      outline: none;
      border-color: ${props => props.theme.colors.highlight.darkest};
    }
  }
`;

const ResponseDisplay = styled.pre`
  background: white;
  padding: 24px;
  border-radius: 8px;
  margin-top: 24px;
  font-family: monospace;
  font-size: 12px;
  white-space: pre-wrap;
  word-wrap: break-word;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-x: auto;
`;

const FlightInfo = styled.div`
  display: grid;
  gap: 24px;
`;

const Section = styled.div`
  display: grid;
  gap: 8px;
`;

const SectionTitle = styled.h3`
  margin: 0;
  color: ${props => props.theme.colors.neutral.dark.darkest};
  font-size: 18px;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px 16px;
`;

const InfoLabel = styled.span`
  color: ${props => props.theme.colors.neutral.dark.base};
  font-weight: 500;
`;

const Value = styled.span`
  color: ${props => props.theme.colors.neutral.dark.darkest};
`;

const FlightCardWrapper = styled.div`
  margin-top: ${LAYOUT.CONTENT_PADDING * 2}px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const SelectFlightButton = styled(Button)`
  margin-top: ${LAYOUT.CONTENT_PADDING * 2}px;
`;

const CabinGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${LAYOUT.CONTENT_PADDING}px;
`;

const CabinButton = styled.button`
  padding: 12px 16px;
  border-radius: 24px;
  border: 1px solid ${props => props.$selected ? 
    props.theme.colors.highlight.darkest : 
    props.theme.colors.neutral.light.dark};
  background: ${props => props.$selected ? 
    props.theme.colors.highlight.lightest : 
    'white'};
  color: ${props => props.$selected ? 
    props.theme.colors.highlight.darkest : 
    props.theme.colors.neutral.dark.darkest};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;

  &:hover {
    border-color: ${props => props.theme.colors.highlight.darkest};
    background: ${props => props.$selected ? 
      props.theme.colors.highlight.lightest : 
      props.theme.colors.neutral.light.lightest};
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 24px;
  border-radius: 16px;
  width: 90%;
  max-width: 380px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
`;

const ModalTitle = styled.h3`
  margin: 0;
  color: ${props => props.theme.colors.neutral.dark.darkest};
  font-size: 18px;
`;

const ModalButton = styled.button`
  padding: 16px;
  border: none;
  border-radius: 8px;
  background: ${props => props.theme.colors.highlight.darkest};
  color: white;
  font-weight: 500;
  cursor: pointer;
`;

const parseDuration = (durationString) => {
  console.log('Parsing duration:', durationString);
  
  // Handle undefined or invalid duration string
  if (!durationString) {
    console.log('No duration string provided');
    return null;
  }
  
  // Remove the PT prefix
  const duration = durationString.substring(2);
  console.log('After PT removal:', duration);
  
  let hours = 0;
  let minutes = 0;
  
  // Find hours
  const hoursMatch = duration.match(/(\d+)H/);
  if (hoursMatch) {
    hours = parseInt(hoursMatch[1]);
    console.log('Hours found:', hours);
  }
  
  // Find minutes
  const minutesMatch = duration.match(/(\d+)M/);
  if (minutesMatch) {
    minutes = parseInt(minutesMatch[1]);
    console.log('Minutes found:', minutes);
  }
  
  const totalMinutes = (hours * 60) + minutes;
  console.log('Total minutes calculated:', totalMinutes);
  
  return totalMinutes || null;
};

const checkAirlineSectorSupport = async (airlineCode, flightNumber, departureDate, apiResponse) => {
  try {
    // Check if airline has sector config
    const { data: config, error: configError } = await supabase
      .from('airline_sector_configs')
      .select('*')
      .eq('airline_code', airlineCode)
      .single();

    if (configError) throw configError;

    // If no config or multi-sectors not handled, log it
    if (!config || !config.handle_multi_sectors) {
      // Log to the error logs table
      const { error: logError } = await supabase
        .from('multi_sector_error_logs')
        .insert({
          airline_code: airlineCode,
          flight_number: flightNumber,
          departure_date: departureDate,
          api_response: apiResponse,
          status: 'pending',
          notes: 'Multi-sector support not configured for this airline'
        });

      if (logError) throw logError;

      return {
        supported: false,
        config: null,
        error: 'Multi-sector flights not supported for this airline yet'
      };
    }

    return {
      supported: true,
      config,
      error: null
    };
  } catch (error) {
    console.error('Error checking airline sector support:', error);
    throw error;
  }
};

const convertToUTC = (timeStr) => {
  // Parse the ISO string to get timezone offset
  const date = new Date(timeStr);
  // Get time parts in UTC
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const utcDate = date.toISOString().split('T')[0];
  return `${utcDate}T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}Z`;
};

const subtractDurationFromTime = (timeStr, durationStr) => {
  console.log('Calculating backwards from:', {
    arrivalTime: timeStr,
    duration: durationStr
  });

  // Convert the arrival time to Date object
  const arrivalDate = new Date(timeStr);
  
  // Parse the duration string (PT14H20M)
  const durationMinutes = parseDuration(durationStr);
  
  // Subtract the duration (in milliseconds)
  const departureDate = new Date(arrivalDate.getTime() - (durationMinutes * 60000));
  
  console.log('Time calculation:', {
    arrivalDate,
    durationMinutes,
    calculatedDeparture: departureDate
  });
  
  return departureDate;
};

const formatTimeForDisplay = (dateStr) => {
  try {
    console.log('Formatting time for:', dateStr);
    const date = new Date(dateStr);
    console.log('Parsed date object:', date);
    
    if (isNaN(date.getTime())) {
      console.log('Invalid date detected');
      return 'N/A';
    }
    
    const formatted = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
    console.log('Formatted time:', formatted);
    return formatted;
  } catch (error) {
    console.error('Error formatting time:', error);
    return 'N/A';
  }
};

function FlightDisplay({ data, onSelect }) {
  const [airlineName, setAirlineName] = useState('');
  const [logoUrl, setLogoUrl] = useState('');
  const { flight } = data;

  // Add logging for the incoming data
  console.log('FlightDisplay received data:', {
    scheduledDepartureDate: flight.scheduledDepartureDate,
    departureTime: flight.departure.scheduledTime,
    arrivalTime: flight.arrival.scheduledTime
  });

  useEffect(() => {
    async function fetchAirlineDetails() {
      try {
        const { data: airlineData, error } = await supabase
          .from('airlines')
          .select('name, logo_url')
          .eq('code', flight.flightDesignator.carrierCode)
          .single();

        if (error) throw error;
        if (airlineData) {
          setAirlineName(airlineData.name);
          setLogoUrl(airlineData.logo_url);
        }
      } catch (error) {
        console.error('Error fetching airline:', error);
      }
    }

    if (flight?.flightDesignator?.carrierCode) {
      fetchAirlineDetails();
    }
  }, [flight?.flightDesignator?.carrierCode]);

  return (
    <>
      <FlightCardWrapper>
        <FlightCard 
          airlineLogo={logoUrl}
          airlineCode={flight.flightDesignator.carrierCode}
          flightNumber={String(flight.flightDesignator.flightNumber)}
          date={flight.scheduledDepartureDate}
          departureAirport={flight.departure.airport}
          departureCode={flight.departure.airport}
          departureTime={flight.departure.scheduledTime}
          arrivalAirport={flight.arrival.airport}
          arrivalCode={flight.arrival.airport}
          arrivalTime={flight.arrival.scheduledTime}
          arrivalTimeLocal={flight.arrival.scheduledTime}
          isAddFlight={true}
          showArrow={false}
        />
        <SelectFlightButton onClick={() => onSelect(flight)}>
          Select this Flight
        </SelectFlightButton>
      </FlightCardWrapper>

      {/* Debug panel */}
      <DebugContainer>
        <DebugTitle>Flight Times Debug</DebugTitle>
        <DebugContent>
          {JSON.stringify({
            raw: {
              scheduledDepartureDate: flight.scheduledDepartureDate,
              departureTime: flight.departure.scheduledTime,
              arrivalTime: flight.arrival.scheduledTime
            },
            formatted: {
              departureTime: formatTimeForDisplay(flight.departure.scheduledTime),
              arrivalTime: formatTimeForDisplay(flight.arrival.scheduledTime)
            }
          }, null, 2)}
        </DebugContent>
      </DebugContainer>
    </>
  );
}

function FlightSummary({ data }) {
  console.log('FlightSummary received data:', data);
  
  if (!data?.datedFlight) {
    console.log('No datedFlight in data');
    return null;
  }
  
  const flight = data.datedFlight;
  console.log('Flight data:', flight);
  
  const formatTime = (timeString) => {
    if (!timeString) return 'N/A';
    return new Date(timeString).toLocaleString();
  };

  // First, let's check the raw data structure
  return (
    <ResponseDisplay>
      <FlightInfo>
        <Section>
          <SectionTitle>Flight Details</SectionTitle>
          <InfoGrid>
            <InfoLabel>Flight</InfoLabel>
            <Value>{flight.carrierCode} {flight.flightNumber}</Value>
            <InfoLabel>Aircraft</InfoLabel>
            <Value>{flight.aircraft?.aircraftType || 'N/A'}</Value>
          </InfoGrid>
        </Section>

        <Section>
          <SectionTitle>Departure</SectionTitle>
          <InfoGrid>
            <InfoLabel>Airport</InfoLabel>
            <Value>{flight.departure?.iataCode}</Value>
            <InfoLabel>Terminal</InfoLabel>
            <Value>{flight.departure?.terminal || 'N/A'}</Value>
            <InfoLabel>Time</InfoLabel>
            <Value>{formatTime(flight.departure?.scheduledTime)}</Value>
          </InfoGrid>
        </Section>

        <Section>
          <SectionTitle>Arrival</SectionTitle>
          <InfoGrid>
            <InfoLabel>Airport</InfoLabel>
            <Value>{flight.arrival?.iataCode}</Value>
            <InfoLabel>Terminal</InfoLabel>
            <Value>{flight.arrival?.terminal || 'N/A'}</Value>
            <InfoLabel>Time</InfoLabel>
            <Value>{formatTime(flight.arrival?.scheduledTime)}</Value>
          </InfoGrid>
        </Section>

        {/* Add raw data display for debugging */}
        <Section>
          <SectionTitle>Raw Data (Debug)</SectionTitle>
          <pre style={{ fontSize: '12px', overflow: 'auto' }}>
            {JSON.stringify(data, null, 2)}
          </pre>
        </Section>
      </FlightInfo>
    </ResponseDisplay>
  );
}

// Add this helper function
const getManufacturerFromCode = (code) => {
  // Basic mapping of common aircraft codes to manufacturers
  if (code.startsWith('7') || code.startsWith('8')) return 'Boeing';
  if (code.startsWith('3') || code.startsWith('A')) return 'Airbus';
  if (code.startsWith('E')) return 'Embraer';
  if (code.startsWith('CR')) return 'Bombardier';
  return 'Unknown';  // Default fallback
};

// Add new styled component for the option with logo
const AirlineOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  
  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
`;

// Add styled components for debug section
const DebugContainer = styled.div`
  margin-top: ${LAYOUT.CONTENT_PADDING * 2}px;
  padding: ${LAYOUT.CONTENT_PADDING}px;
  background: ${props => props.theme.colors.neutral.light.lightest};
  border-radius: ${LAYOUT.BORDER_RADIUS}px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
`;

const DebugTitle = styled.h3`
  margin: 0 0 16px 0;
  font-size: 14px;
  color: ${props => props.theme.colors.neutral.dark.base};
`;

const DebugContent = styled.pre`
  margin: 0;
  padding: 16px;
  background: white;
  border-radius: 8px;
  overflow-x: auto;
  font-size: 12px;
  line-height: 1.4;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
`;

// Add these styled components after your existing ones
const SegmentSelectorContainer = styled.div`
  margin: 24px 0;
  padding: 16px;
  border-radius: 12px;
  background: ${props => props.theme.colors.neutral.light.lightest};
`;

const SegmentTitle = styled(H1)`
  margin-bottom: 16px;
  font-size: 20px;
`;

const SegmentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SegmentOption = styled.button`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 8px;
  background: white;
  cursor: pointer;
  text-align: left;
  transition: all 0.2s ease;

  &:hover {
    border-color: ${props => props.theme.colors.highlight.darkest};
    background: ${props => props.theme.colors.neutral.light.lightest};
  }
`;

const RouteDisplay = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const SegmentDetail = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.neutral.dark.base};
`;

// Add this new styled component near the other styled components
const SectorSelector = styled.div`
  margin: 24px 0;
  background: ${props => props.theme.colors.neutral.light.lightest};
  border-radius: 12px;
  padding: 16px;
`;

const SectorOption = styled.button`
  width: 100%;
  padding: 16px;
  margin-bottom: 12px;
  background: white;
  border: 2px solid ${props => 
    props.$selected 
      ? props.theme.colors.highlight.darkest 
      : props.theme.colors.neutral.light.dark};
  border-radius: 8px;
  cursor: pointer;
  text-align: left;
  transition: all 0.2s ease;

  &:hover {
    border-color: ${props => props.theme.colors.highlight.darkest};
    background: ${props => 
      props.$selected 
        ? 'white' 
        : props.theme.colors.neutral.light.lightest};
  }
`;

const SectorDetails = styled.div`
  display: grid;
  gap: 8px;
`;

const SectorRoute = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.colors.neutral.dark.darkest};
`;

const SectorTime = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.neutral.dark.base};
`;

// Add these new styled components for the sector selector
const SectorGrid = styled.div`
  display: flex;
  gap: ${LAYOUT.CONTENT_PADDING}px;
  margin-top: ${LAYOUT.CONTENT_PADDING * 2}px;
  margin-bottom: ${LAYOUT.CONTENT_PADDING * 2}px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
`;

const SectorButton = styled.button`
  padding: 8px 16px;
  border-radius: 24px;
  border: 1px solid ${props => props.$selected ? 
    props.theme.colors.highlight.darkest : 
    props.theme.colors.neutral.light.dark};
  background: ${props => props.$selected ? 
    props.theme.colors.highlight.lightest : 
    'white'};
  color: ${props => props.$selected ? 
    props.theme.colors.highlight.darkest : 
    props.theme.colors.neutral.dark.darkest};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
  flex-shrink: 0;

  &:hover {
    border-color: ${props => props.theme.colors.highlight.darkest};
    background: ${props => props.$selected ? 
      props.theme.colors.highlight.lightest : 
      props.theme.colors.neutral.light.lightest};
  }
`;

// Update the SectorSelection component
function SectorSelection({ segments, onSelectSector, selectedSegment }) {
  const formatRoute = (segment) => {
    return `${segment.boardPointIataCode} → ${segment.offPointIataCode}`;
  };

  return (
    <SectorGrid>
      {segments.map((segment, index) => (
        <SectorButton
          key={index}
          onClick={() => onSelectSector(segment)}
          $selected={selectedSegment === segment}
        >
          {formatRoute(segment)}
        </SectorButton>
      ))}
    </SectorGrid>
  );
}

// Update the FormGroup styled component for cabin class specifically
const CabinFormGroup = styled(FormGroup)`
  margin-top: ${LAYOUT.CONTENT_PADDING * 3}px;  // 36px top margin
  margin-bottom: ${LAYOUT.CONTENT_PADDING * 2}px; // 24px bottom margin
`;

// First, add a new styled component for the loading state
const LoadingMessage = styled(BodyM)`
  color: ${props => props.theme.colors.neutral.dark.base};
  text-align: center;
  padding: ${LAYOUT.CONTENT_PADDING}px;
`;

// Add this mapping constant at the top level
const CABIN_TO_BOOKING_CODE = {
  'Economy': 'Y',
  'Premium Economy': 'W',
  'Business': 'J',  // Using 'J' as it's commonly used for Business
  'First': 'F'
};

function AddFlightPage({ clearAllStorage }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [airlines, setAirlines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    airline: '',
    flightNumber: '',
    date: '',
    cabin: 'Economy'
  });
  const [errors, setErrors] = useState({});
  const [apiResponse, setApiResponse] = useState(null);
  const [airlineSearchTerm, setAirlineSearchTerm] = useState('');
  const [showAirlineDropdown, setShowAirlineDropdown] = useState(false);
  const dropdownRef = useRef(null);

  // Add loading state specifically for airlines
  const [airlinesLoading, setAirlinesLoading] = useState(true);

  // Add these two lines:
  const [segments, setSegments] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(null);

  // Add this reset function
  const resetForm = () => {
    setFormData({
      airline: '',
      flightNumber: '',
      date: '',
      cabin: 'Economy'
    });
    setErrors({});
    setApiResponse(null);
    setAirlineSearchTerm('');
    setShowAirlineDropdown(false);
  };

  // Reset form on mount only
  useEffect(() => {
    resetForm();
  }, []); // Empty dependency array - runs once on mount

  // Fetch airlines when page becomes visible
  useEffect(() => {
    const fetchAirlines = async () => {
      try {
        setAirlinesLoading(true);
        const { data: { session } } = await supabase.auth.getSession();
        
        if (!session) {
          console.error('No active session found');
          return;
        }

        const { data, error } = await supabase
          .from('airlines')
          .select('*')
          .order('name');

        if (error) throw error;

        setAirlines(data || []); // Ensure we always set an array
      } catch (error) {
        console.error('Error fetching airlines:', error);
        setAirlines([]); // Set empty array on error
      } finally {
        setAirlinesLoading(false);
      }
    };

    fetchAirlines();
  }, [location.key]); // Re-fetch airlines when location changes

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowAirlineDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.airline) newErrors.airline = 'Please select an airline';
    if (!formData.flightNumber) {
      newErrors.flightNumber = 'Please enter a flight number';
    } else if (!/^\d+$/.test(formData.flightNumber)) {
      newErrors.flightNumber = 'Flight number must be numeric';
    }
    if (!formData.date) newErrors.date = 'Please select a date';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const [showDuplicateError, setShowDuplicateError] = useState(false);

  const handleSelectFlight = async (flight) => {
    try {
      console.log('\n=== Checking Amadeus Airport Data ===');
      console.log('Checking airport:', flight.departure.airport);

      // Check if we have Amadeus data for this airport
      const { data: airport } = await supabase
        .from('airports')
        .select('amadeus_fetched_at')
        .eq('code', flight.departure.airport)
        .single();

      console.log('Current airport data status:', {
        code: flight.departure.airport,
        hasAmadeusData: !!airport?.amadeus_fetched_at,
        lastFetched: airport?.amadeus_fetched_at || 'never'
      });

      // If never fetched, get the data
      if (!airport?.amadeus_fetched_at) {
        console.log('Fetching new Amadeus data for:', flight.departure.airport);
        const amadeusData = await getAirportDetails(flight.departure.airport);
        
        // Get the airport data from the response
        const airportData = amadeusData.data?.find(item => 
          item.subType === 'AIRPORT' && 
          item.iataCode === flight.departure.airport
        );

        if (airportData) {
          // Map Amadeus data to our specific fields
          const { error: updateError } = await supabase
            .from('airports')
            .update({ 
              amadeus_fetched_at: new Date().toISOString(),
              amadeus_type: airportData.type,
              amadeus_sub_type: airportData.subType,
              amadeus_name: airportData.name,
              amadeus_detailed_name: airportData.detailedName,
              amadeus_id: airportData.id,
              amadeus_self_href: airportData.self?.href,
              amadeus_timezone_offset: airportData.timeZoneOffset,
              amadeus_latitude: airportData.geoCode?.latitude,
              amadeus_longitude: airportData.geoCode?.longitude,
              amadeus_city_name: airportData.address?.cityName,
              amadeus_city_code: airportData.address?.cityCode,
              amadeus_country_name: airportData.address?.countryName,
              amadeus_country_code: airportData.address?.countryCode,
              amadeus_region_code: airportData.address?.regionCode,
              amadeus_travelers_score: airportData.analytics?.travelers?.score
            })
            .eq('code', flight.departure.airport);

          if (updateError) {
            console.error('Error saving Amadeus data:', updateError);
          } else {
            console.log('Amadeus data mapped and saved successfully');
          }
        }
      } else {
        console.log('Using existing Amadeus data from:', airport.amadeus_fetched_at);
      }

      console.log('\n=== Flight Save Process ===');
      console.log('1. Raw flight data:', flight);

      setErrors({});
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        throw new Error('No authenticated user found');
      }

      // Get duration from the segment property
      const segmentDuration = flight.segment?.duration;
      console.log('2. Duration data:', {
        segment: flight.segment,
        duration: segmentDuration
      });

      if (!segmentDuration) {
        console.log('3. ERROR: Missing duration data');
        throw new Error('Could not find flight duration');
      }

      // STEP 1: Get simplified departure time (just date and time)
      const departureTimeForDB = flight.departure.scheduledTime.substring(0, 16);
      console.log('Step 1 - Departure time:', {
        raw: flight.departure.scheduledTime,
        forDB: departureTimeForDB  // Will be just YYYY-MM-DDTHH:mm
      });

      // STEP 2: Get arrival time (just date and time)
      const arrivalTimeForDB = flight.arrival.scheduledTime.substring(0, 16);
      console.log('Step 2 - Arrival time:', {
        raw: flight.arrival.scheduledTime,
        forDB: arrivalTimeForDB  // Will be just YYYY-MM-DDTHH:mm
      });

      // Parse duration for storage only
      const durationMatch = segmentDuration.match(/PT(?:(\d+)H)?(?:(\d+)M)?/);
      const totalMinutes = (parseInt(durationMatch[1] || 0) * 60) + parseInt(durationMatch[2] || 0);

      const flightData = {
        user_email: user.email,
        airline_code: flight.flightDesignator.carrierCode,
        flight_number: flight.flightDesignator.flightNumber,
        departure_time: departureTimeForDB,
        arrival_time: arrivalTimeForDB,
        departure_airport_code: flight.departure.airport,
        arrival_airport_code: flight.arrival.airport,
        duration_mins: totalMinutes,
        seat_class: formData.cabin
      };

      console.log('Flight data to save:', flightData);

      const { data, error } = await supabase
        .from('flights')
        .upsert(flightData)
        .select('*')
        .single();

      if (error) throw error;
      console.log('Successfully saved flight:', data);
      
      navigate(`/flights/${data.id}`);
    } catch (error) {
      console.error('Error saving flight:', error);
      setErrors({ 
        submit: error.message || 'Unable to save flight. Please try again.' 
      });
    }
  };

  const cabinOptions = [
    { value: '', label: 'Any Cabin' },
    { value: 'Economy', label: 'Economy' },
    { value: 'Premium Economy', label: 'Premium Economy' },
    { value: 'Business', label: 'Business' },
    { value: 'First', label: 'First' }
  ];

  // Update the flight number change handler
  const handleFlightNumberChange = (e) => {
    setFormData({
      ...formData, 
      flightNumber: e.target.value,
      date: '' // Reset date when flight number changes
    });
    setApiResponse(null); // Clear any previous API response
    setErrors({}); // Clear any errors
  };

  // Update the useEffect to only trigger when we have all fields AND date was just set
  useEffect(() => {
    const searchFlight = async () => {
      // Only search if we have all fields AND date was just changed
      if (formData.airline && 
          formData.flightNumber && 
          formData.date && 
          // Check if date was the last field changed
          Object.keys(formData).some(key => key === 'date' && formData[key])) {
        setLoading(true);
        setErrors({});
        setApiResponse(null);
        setSegments(null);
        setSelectedSegment(null);

        try {
          console.log('Starting flight search with:', {
            airline: formData.airline,
            flightNumber: formData.flightNumber,
            date: formData.date
          });

          const response = await findFlight(
            formData.airline,
            formData.flightNumber,
            formData.date
          );

          console.log('Raw API Response:', response);
          
          // Get the flight data from the correct path
          const flightData = response?._raw?.data?.[0];
          console.log('Extracted flight data:', flightData);

          if (!flightData) {
            console.error('No flight data found in response structure:', response);
            throw new Error('No flight data found in response');
          }

          // Check for multiple segments
          const flightSegments = flightData.segments;
          console.log('Flight segments:', flightSegments);
          console.log('Number of segments:', flightSegments?.length);

          if (flightSegments?.length > 1) {
            console.log('Processing multi-segment flight');
            
            // Check airline sector support before showing segments
            const sectorSupport = await checkAirlineSectorSupport(
              formData.airline,
              formData.flightNumber,
              formData.date,
              response._raw
            );
            
            console.log('Airline sector support:', sectorSupport);
            
            if (sectorSupport.supported) {
              setSegments(flightSegments);
              
              // For supported airlines, set up the first segment
              const firstSegment = flightSegments[0];
              const flightPoints = flightData.flightPoints;
              
              console.log('Processing first segment:', {
                segment: firstSegment,
                allPoints: flightPoints
              });

              // Get departure and arrival points for the first segment
              const departurePoint = flightPoints.find(point => point.iataCode === firstSegment.boardPointIataCode);
              const arrivalPoint = flightPoints.find(point => point.iataCode === firstSegment.offPointIataCode);

              console.log('Found points for segment:', {
                departure: departurePoint,
                arrival: arrivalPoint,
                segmentRoute: `${firstSegment.boardPointIataCode}-${firstSegment.offPointIataCode}`
              });

              if (departurePoint && arrivalPoint) {
                const transformedFlight = {
                  flightDesignator: flightData.flightDesignator,
                  scheduledDepartureDate: flightData.scheduledDepartureDate,
                  departure: {
                    airport: firstSegment.boardPointIataCode,
                    scheduledTime: departurePoint.departure.timings[0].value,
                    terminal: '',
                    scheduledDate: departurePoint.departure.timings[0].value.split('T')[0],
                    scheduledDateFormatted: new Date(departurePoint.departure.timings[0].value).toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric'
                    })
                  },
                  arrival: {
                    airport: firstSegment.offPointIataCode,
                    scheduledTime: arrivalPoint.arrival.timings[0].value,
                    terminal: '',
                    scheduledDate: arrivalPoint.arrival.timings[0].value.split('T')[0],
                    scheduledDateFormatted: new Date(arrivalPoint.arrival.timings[0].value).toLocaleDateString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric'
                    })
                  },
                  segment: {
                    duration: firstSegment.scheduledSegmentDuration
                  }
                };

                console.log('Setting transformed flight data:', transformedFlight);

                setApiResponse({
                  flight: transformedFlight,
                  _raw: response._raw
                });
              } else {
                console.error('Could not find departure or arrival points for segment');
              }
            } else {
              // Create a user-friendly sector list
              const sectorList = flightSegments.map(segment => 
                `${segment.boardPointIataCode} → ${segment.offPointIataCode}`
              ).join(', ');

              // Get airline name
              const airline = airlines.find(a => a.code === formData.airline)?.name || formData.airline;

              // Show warning message
              setErrors({ 
                submit: `This flight has multiple sectors (${sectorList}). For ${airline}, we can only show the first sector for now. We're working on supporting all sectors soon!`
              });
              
              // Show first segment only
              console.log('Showing first segment only for unsupported airline');
              const transformedFlight = {
                flightDesignator: flightData.flightDesignator,
                scheduledDepartureDate: flightData.scheduledDepartureDate,
                departure: {
                  airport: flightData.flightPoints[0].iataCode,
                  scheduledTime: flightData.flightPoints[0].departure.timings[0].value,
                  terminal: '',
                  scheduledDate: flightData.flightPoints[0].departure.timings[0].value.split('T')[0],
                  scheduledDateFormatted: new Date(flightData.flightPoints[0].departure.timings[0].value).toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                  })
                },
                arrival: {
                  airport: flightData.flightPoints[1].iataCode,
                  scheduledTime: flightData.flightPoints[1].arrival.timings[0].value,
                  terminal: '',
                  scheduledDate: flightData.flightPoints[1].arrival.timings[0].value.split('T')[0],
                  scheduledDateFormatted: new Date(flightData.flightPoints[1].arrival.timings[0].value).toLocaleDateString('en-US', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                  })
                },
                segment: {
                  duration: flightData.segments[0].scheduledSegmentDuration
                }
              };

              setApiResponse({
                flight: transformedFlight,
                _raw: response._raw
              });
            }
          } else {
            console.log('Processing single-segment flight');
            const transformedFlight = {
              flightDesignator: flightData.flightDesignator,
              scheduledDepartureDate: flightData.scheduledDepartureDate,
              departure: {
                airport: flightData.flightPoints[0].iataCode,
                scheduledTime: flightData.flightPoints[0].departure.timings[0].value,
                terminal: '',
                scheduledDate: flightData.flightPoints[0].departure.timings[0].value.split('T')[0],
                scheduledDateFormatted: new Date(flightData.flightPoints[0].departure.timings[0].value).toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
                })
              },
              arrival: {
                airport: flightData.flightPoints[1].iataCode,
                scheduledTime: flightData.flightPoints[1].arrival.timings[0].value,
                terminal: '',
                scheduledDate: flightData.flightPoints[1].arrival.timings[0].value.split('T')[0],
                scheduledDateFormatted: new Date(flightData.flightPoints[1].arrival.timings[0].value).toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
                })
              },
              segment: {
                duration: flightData.segments[0].scheduledSegmentDuration
              }
            };

            console.log('Setting transformed single-segment flight data:', transformedFlight);

            setApiResponse({
              flight: transformedFlight,
              _raw: response._raw
            });
          }
        } catch (error) {
          console.error('Error finding flight:', error);
          setErrors({ submit: error.message });
        } finally {
          setLoading(false);
        }
      }
    };

    searchFlight();
  }, [formData.date]); // Only depend on date changes

  const handleSignOut = async () => {
    try {
      await clearAllStorage();
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Error signing out:', error);
      navigate('/', { replace: true });
    }
  };

  // Update the handleSectorSelect function
  const handleSectorSelect = (segment) => {
    // Don't do anything if selecting the same segment
    if (selectedSegment === segment) {
      console.log('Same segment selected, skipping update');
      return;
    }
    
    setSelectedSegment(segment);
    
    // Get the raw API response data
    const flightData = apiResponse?._raw?.data?.[0];
    if (!flightData) {
      console.error('No flight data available:', apiResponse);
      return;
    }

    // Find the matching leg for this segment
    const matchingLeg = flightData.legs.find(leg => 
      leg.boardPointIataCode === segment.boardPointIataCode && 
      leg.offPointIataCode === segment.offPointIataCode
    );

    console.log('Processing segment selection:', {
      selectedRoute: `${segment.boardPointIataCode}-${segment.offPointIataCode}`,
      matchingLeg,
      isFullJourney: segment.boardPointIataCode === flightData.flightPoints[0].iataCode && 
                     segment.offPointIataCode === flightData.flightPoints[flightData.flightPoints.length - 1].iataCode
    });

    let newTransformedFlight;

    // Handle full journey (SYD-LHR)
    if (segment.boardPointIataCode === flightData.flightPoints[0].iataCode && 
        segment.offPointIataCode === flightData.flightPoints[flightData.flightPoints.length - 1].iataCode) {
      // This is the full journey segment
      newTransformedFlight = {
        flightDesignator: flightData.flightDesignator,
        scheduledDepartureDate: flightData.scheduledDepartureDate,
        departure: {
          airport: segment.boardPointIataCode,
          scheduledTime: flightData.flightPoints[0].departure.timings[0].value,
          terminal: '',
          scheduledDate: flightData.flightPoints[0].departure.timings[0].value.split('T')[0],
          scheduledDateFormatted: new Date(flightData.flightPoints[0].departure.timings[0].value)
            .toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            })
        },
        arrival: {
          airport: segment.offPointIataCode,
          scheduledTime: flightData.flightPoints[1].arrival.timings[0].value,
          terminal: '',
          scheduledDate: flightData.flightPoints[1].arrival.timings[0].value.split('T')[0],
          scheduledDateFormatted: new Date(flightData.flightPoints[1].arrival.timings[0].value)
            .toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            })
        },
        segment: {
          duration: segment.scheduledSegmentDuration
        }
      };
    }
    // Handle intermediate segments
    else if (matchingLeg) {
      let departureTime, arrivalTime;

      // For SYD-SIN segment
      if (segment.boardPointIataCode === flightData.flightPoints[0].iataCode) {
        departureTime = flightData.flightPoints[0].departure.timings[0].value;
        const departureDate = new Date(departureTime);
        const durationMinutes = parseDuration(matchingLeg.scheduledLegDuration);
        const arrivalDate = new Date(departureDate.getTime() + (durationMinutes * 60000));
        
        // Get destination timezone info
        const destTimezone = getAirportTimezone(segment.offPointIataCode);
        const destHours = (arrivalDate.getUTCHours() + destTimezone.offset) % 24;
        const destDate = new Date(arrivalDate);
        if (arrivalDate.getUTCHours() + destTimezone.offset >= 24) {
          destDate.setUTCDate(destDate.getUTCDate() + 1);
        }
        arrivalTime = `${destDate.toISOString().split('T')[0]}T${String(destHours).padStart(2, '0')}:${String(arrivalDate.getUTCMinutes()).padStart(2, '0')}${destTimezone.format}`;
      }
      // For intermediate segment
      else if (segment.boardPointIataCode === flightData.legs[0].offPointIataCode) {
        const finalArrival = flightData.flightPoints[1].arrival.timings[0].value;
        const durationMinutes = parseDuration(matchingLeg.scheduledLegDuration);
        const finalArrivalDate = new Date(finalArrival);
        const departureDate = new Date(finalArrivalDate.getTime() - (durationMinutes * 60000));
        
        // Get origin timezone info
        const originTimezone = getAirportTimezone(segment.boardPointIataCode);
        const originHours = (departureDate.getUTCHours() + originTimezone.offset) % 24;
        const originDate = new Date(departureDate);
        if (departureDate.getUTCHours() + originTimezone.offset >= 24) {
          originDate.setUTCDate(originDate.getUTCDate() + 1);
        }
        departureTime = `${originDate.toISOString().split('T')[0]}T${String(originHours).padStart(2, '0')}:${String(departureDate.getUTCMinutes()).padStart(2, '0')}${originTimezone.format}`;
        arrivalTime = finalArrival;
      }

      if (departureTime && arrivalTime) {
        newTransformedFlight = {
          flightDesignator: flightData.flightDesignator,
          scheduledDepartureDate: flightData.scheduledDepartureDate,
          departure: {
            airport: segment.boardPointIataCode,
            scheduledTime: departureTime,
            terminal: '',
            scheduledDate: departureTime?.split('T')[0],
            scheduledDateFormatted: new Date(departureTime).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            })
          },
          arrival: {
            airport: segment.offPointIataCode,
            scheduledTime: arrivalTime,
            terminal: '',
            scheduledDate: arrivalTime?.split('T')[0],
            scheduledDateFormatted: new Date(arrivalTime).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            })
          },
          segment: {
            duration: matchingLeg.scheduledLegDuration
          }
        };
      }
    }

    // Only update state if we have a valid transformed flight
    if (newTransformedFlight) {
      console.log('Updating flight data:', {
        route: `${segment.boardPointIataCode}-${segment.offPointIataCode}`,
        departure: newTransformedFlight.departure.scheduledTime,
        arrival: newTransformedFlight.arrival.scheduledTime
      });

      setApiResponse(prev => {
        // Only update if the data has actually changed
        if (JSON.stringify(prev?.flight) === JSON.stringify(newTransformedFlight)) {
          console.log('Flight data unchanged, skipping update');
          return prev;
        }
        return {
          flight: newTransformedFlight,
          _raw: prev._raw
        };
      });
    } else {
      console.error('Could not create transformed flight for segment:', segment);
    }
  };

  // Then in your handleCabinSelect function:
  const handleCabinSelect = (cabin) => {
    const bookingCode = CABIN_TO_BOOKING_CODE[cabin];
    console.log('Selected cabin:', {
      userSelection: cabin,
      bookingCode: bookingCode
    });
    
    // Use this booking code when making seatmap API calls
    // ... rest of your cabin selection logic
  };

  return (
    <>
      <DetailPageContainer>
        <HeaderArea>
          <MainHeader onSignOut={handleSignOut} />
          <SubHeader 
            mainPage="My Flights"
            subPage="Add Flight"
            onBack={() => navigate('/flights')}
            onMainClick={() => navigate('/flights')}
          />
        </HeaderArea>
        <ContentArea>
          <Form>
            <FormGroup ref={dropdownRef}>
              <Label>Airline</Label>
              <Select
                value={formData.airline}
                onChange={(e) => {
                  setFormData({...formData, airline: e.target.value});
                }}
                disabled={airlinesLoading}
              >
                <option value="">
                  {airlinesLoading ? 'Loading airlines...' : 'Select an airline'}
                </option>
                {!airlinesLoading && airlines.map(airline => (
                  <option 
                    key={airline.code}
                    value={airline.code}
                    data-logo={airline.logo_url}  // Add logo URL as data attribute
                  >
                    {airline.name} ({airline.code})
                  </option>
                ))}
              </Select>
              {errors.airline && <ErrorMessage>{errors.airline}</ErrorMessage>}
            </FormGroup>

            <FormGroup>
              <Label>Flight Number</Label>
              <Input
                type="text"
                value={formData.flightNumber}
                onChange={handleFlightNumberChange}  // Use new handler
                placeholder="e.g. 1435"
              />
              {errors.flightNumber && <ErrorMessage>{errors.flightNumber}</ErrorMessage>}
            </FormGroup>

            <FormGroup>
              <Label>Date</Label>
              <StyledDatePicker
                selected={formData.date ? new Date(formData.date) : null}
                onChange={(date) => setFormData({...formData, date: date.toISOString()})}
                dateFormat="MMMM d, yyyy"
                minDate={new Date()}
                placeholderText="Select date"
              />
              {errors.date && <ErrorMessage>{errors.date}</ErrorMessage>}
            </FormGroup>

            {errors.submit && <ErrorMessage>{errors.submit}</ErrorMessage>}
          </Form>

          {/* Always show sector selection if we have segments */}
          {segments && (
            <SectorSelection 
              segments={segments} 
              onSelectSector={handleSectorSelect}
              selectedSegment={selectedSegment}
            />
          )}

          {/* Always show cabin class selection */}
          <CabinFormGroup>
            <Label>Cabin Class</Label>
            <CabinGrid>
              <CabinButton
                type="button"
                $selected={formData.cabin === 'Economy'}
                onClick={() => setFormData({...formData, cabin: 'Economy'})}
              >
                Economy
              </CabinButton>
              <CabinButton
                type="button"
                $selected={formData.cabin === 'Premium Economy'}
                onClick={() => setFormData({...formData, cabin: 'Premium Economy'})}
              >
                Premium Economy
              </CabinButton>
              <CabinButton
                type="button"
                $selected={formData.cabin === 'Business'}
                onClick={() => setFormData({...formData, cabin: 'Business'})}
              >
                Business
              </CabinButton>
              <CabinButton
                type="button"
                $selected={formData.cabin === 'First'}
                onClick={() => setFormData({...formData, cabin: 'First'})}
              >
                First
              </CabinButton>
            </CabinGrid>
            {errors.cabin && <ErrorMessage>{errors.cabin}</ErrorMessage>}
          </CabinFormGroup>

          {/* Show flight display only if we have selected a segment or have a direct flight */}
          {apiResponse && ((!segments || selectedSegment) ? (
            <FlightDisplay 
              data={{
                flight: apiResponse.flight || apiResponse._raw?.data?.[0],
                _raw: apiResponse._raw
              }}
              onSelect={handleSelectFlight}
            />
          ) : null)}

          {/* Always show debug panel when API is called */}
          {(loading || apiResponse) && (
            <DebugContainer>
              <DebugTitle>API Response</DebugTitle>
              <DebugContent>
                {loading ? (
                  <LoadingMessage>Loading flight data...</LoadingMessage>
                ) : (
                  JSON.stringify(apiResponse, null, 2)
                )}
              </DebugContent>
            </DebugContainer>
          )}
        </ContentArea>
      </DetailPageContainer>

      {showDuplicateError && (
        <ModalOverlay>
          <ModalContent>
            <ModalTitle>Flight Already Added</ModalTitle>
            <BodyM>You have already added this flight for this date.</BodyM>
            <ModalButton onClick={() => setShowDuplicateError(false)}>
              OK
            </ModalButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
}

AddFlightPage.propTypes = {
  clearAllStorage: PropTypes.func.isRequired
};

export default AddFlightPage; 