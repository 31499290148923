export const colors = {
    // Brand Colors (New)
    brand: {
      primary: '#006FFD',  // Using highlight.darkest as primary
      gold: '#FFB800',     // Adding gold for ratings
    },

    // Highlight Colors (Blue)
    highlight: {
      darkest: '#006FFD',
      dark: '#2897FF',
      medium: '#6FBAFF',
      light: '#B4DBFF',
      lightest: '#EAF2FF'
    },
  
    // Neutral Colors
    neutral: {
      light: {
        lightest: '#FFFFFF',
        light: '#F5F5F5',
        dark: '#E5E5E5',
        darkest: '#D4D4D4',
      },
      dark: {
        lightest: '#737373',
        light: '#525252',
        medium: '#404040',
        dark: '#262626',
        darkest: '#171717',
        base: '#262626'    // Adding base for text
      }
    },
  
    // Support Colors
    support: {
      success: {
        dark: '#298267',
        medium: '#3AC0A0',
        light: '#E7F4E8'
      },
  
      warning: {
        dark: '#E86339',
        medium: '#FFB37C',
        light: '#FFF4E4'
      },
  
      error: {
        dark: '#ED3241',
        medium: '#FF616D',
        light: '#FFE2E5'
      }
    }
  };