import React, { useState } from 'react';
import styled from 'styled-components';
import { LAYOUT } from '../../styles/layout';
import SideMenu from '../SideMenu/SideMenu';

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${LAYOUT.MAIN_HEADER_HEIGHT}px;
  background: white;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid ${props => props.theme.colors.neutral.light.dark};

  @media (min-width: ${LAYOUT.MOBILE_WIDTH}px) {
    width: ${LAYOUT.MOBILE_WIDTH}px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const MenuButton = styled.button`
  width: ${LAYOUT.MAIN_HEADER_HEIGHT}px;
  height: ${LAYOUT.MAIN_HEADER_HEIGHT}px;
  padding: 16px;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  
  span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: ${props => props.theme.colors.highlight.darkest};
    transition: all 0.3s ease;
  }
`;

const CenterLogoContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CenterLogo = styled.img`
  height: 100%;
  width: auto;
  object-fit: contain;
`;

const SquareLogoContainer = styled.div`
  width: ${LAYOUT.MAIN_HEADER_HEIGHT}px;
  height: ${LAYOUT.MAIN_HEADER_HEIGHT}px;
  padding: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SquareLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

function MainHeader({ onSignOut }) {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <HeaderContainer>
        <MenuButton onClick={() => setMenuOpen(true)}>
          <span />
          <span />
          <span />
        </MenuButton>
        
        <CenterLogoContainer>
          <CenterLogo src="/templogo.png" alt="FlyerCopilot" />
        </CenterLogoContainer>
        
        <SquareLogoContainer>
          <SquareLogo src="/logo.png" alt="FlyerCopilot Icon" />
        </SquareLogoContainer>
      </HeaderContainer>
      
      {menuOpen && (
        <SideMenu 
          isOpen={menuOpen}
          onClose={() => setMenuOpen(false)}
          onSignOut={onSignOut}
        />
      )}
    </>
  );
}

export default MainHeader; 