import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { supabase } from '../../lib/supabase';

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 4px;
  box-sizing: border-box;
`;

const Label = styled.div`
  height: 33%;
  font-size: 12px;
  color: ${props => props.theme.colors.neutral.dark.base};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InputContainer = styled.div`
  height: 67%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BookingRef = styled.span`
  color: #A99968;
  font-weight: 600;
  font-size: 16px;
  padding: 6px 16px;
  border: 1px solid #A99968;
  border-radius: 16px;
  background-color: rgba(169, 153, 104, 0.05);
  box-shadow: 0 1px 2px rgba(169, 153, 104, 0.1);
  cursor: pointer;
  transition: all 0.2s ease;
  width: fit-content;
  max-width: 90%;
  text-align: center;

  &:hover {
    background-color: rgba(169, 153, 104, 0.1);
  }

  input {
    font-size: 16px;
    font-weight: 600;
    width: 72px;
    text-align: center;
    border: none;
    outline: none;
    padding: 0;
    background: transparent;
    text-transform: uppercase;
    color: #A99968;

    &::placeholder {
      color: rgba(169, 153, 104, 0.25);
    }
  }

  ${props => props.$hasError && `
    border-color: ${props.theme.colors.error};
    color: ${props.theme.colors.error};
  `}
`;

const ErrorMessage = styled.div`
  position: absolute;
  bottom: -32px;
  left: 50%;
  transform: translateX(-50%);
  background: #D32F2F;
  color: white;
  font-size: 11px;
  text-align: center;
  padding: 4px 8px;
  border-radius: 4px;
  width: max-content;
  line-height: 1.2;
  z-index: 1;
`;

function BookingRefSelector({ bookingRef, flightId, onUpdate }) {
  const [isEditing, setIsEditing] = useState(false);
  const [tempBookingRef, setTempBookingRef] = useState(bookingRef || '');
  const [error, setError] = useState('');

  const validateBookingRef = (value) => {
    // Allow empty value for deletion
    if (!value.trim()) return true;
    
    // Must be 6 characters, alphanumeric only
    const regex = /^[A-Z0-9]{6}$/;
    return regex.test(value.trim());
  };

  const handleBookingRefUpdate = async (value) => {
    setError('');
    
    // Handle deletion case
    if (!value.trim()) {
      try {
        const { error: dbError } = await supabase
          .from('flights')
          .update({ pnr: null })
          .eq('id', flightId)
          .single();

        if (dbError) throw dbError;
        
        setTempBookingRef('');
        if (onUpdate) onUpdate(null);
        setIsEditing(false);
        return;
      } catch (error) {
        console.error('Error clearing booking reference:', error);
        setError('Failed to clear booking reference');
        return;
      }
    }

    // Validate input
    if (!validateBookingRef(value)) {
      setError('6 chars, A-Z & 0-9 only');
      return;
    }

    try {
      const { error: dbError } = await supabase
        .from('flights')
        .update({ pnr: value.trim().toUpperCase() })
        .eq('id', flightId)
        .single();

      if (dbError) throw dbError;
      
      const updatedBookingRef = value.trim().toUpperCase();
      setTempBookingRef(updatedBookingRef);
      if (onUpdate) onUpdate(updatedBookingRef);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating booking reference:', error);
      setError('Failed to update booking reference');
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.toUpperCase();
    setTempBookingRef(value);
    setError(''); // Clear error when user types
  };

  return (
    <SelectorContainer>
      <Label>Booking Ref.</Label>
      <InputContainer>
        <BookingRef 
          onClick={() => setIsEditing(true)}
          $isEditing={isEditing}
          $isAdd={!bookingRef}
          $hasError={!!error}
        >
          {isEditing ? (
            <input
              type="text"
              value={tempBookingRef}
              onChange={handleInputChange}
              onBlur={() => handleBookingRefUpdate(tempBookingRef)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleBookingRefUpdate(tempBookingRef);
                }
              }}
              autoFocus
              maxLength={6}
              onClick={(e) => e.stopPropagation()}
              placeholder="ABC123"
            />
          ) : (
            bookingRef || 'Add'
          )}
        </BookingRef>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </InputContainer>
    </SelectorContainer>
  );
}

BookingRefSelector.propTypes = {
  bookingRef: PropTypes.string,
  flightId: PropTypes.string.isRequired,
  onUpdate: PropTypes.func
};

export default BookingRefSelector; 