import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { LAYOUT } from '../styles/layout';
import MainHeader from '../components/MainHeader/MainHeader';
import SubHeader from '../components/SubHeader/SubHeader';
import FlightCard from '../components/FlightCard/FlightCard';
import { getSimplifiedSeatmap } from '../api/seatmaps';
import { supabase } from '../lib/supabase';
import NewSeatmapContainer from '../components/Seatmaps/NewSeatmapContainer';
import Seat from '../components/Seatmaps/Seat';

const PageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  width: min(428px, 100vw);
  height: 100vh;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  transform: translateX(-50%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const ContentContainer = styled.div`
  margin-top: ${LAYOUT.TOTAL_HEADER_HEIGHT}px;
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 0 ${LAYOUT.CONTENT_PADDING}px;
  padding-bottom: 40px;
  box-sizing: border-box;
`;

const FlightCardContainer = styled.div`
  margin-bottom: 12px;
`;

const DebugPanel = styled.div`
  margin-top: 12px;
  padding: 16px;
  background: ${props => props.theme.colors.neutral.light.lightest};
  border-radius: 8px;
`;

const DebugHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const DebugTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.colors.neutral.dark.darkest};
`;

const DebugToggle = styled.button`
  padding: 4px 8px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 4px;
  background: white;
  font-size: 12px;
  cursor: pointer;
  
  &:hover {
    background: ${props => props.theme.colors.neutral.light.lightest};
  }
`;

const DebugContent = styled.pre`
  font-size: 12px;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-height: 400px;
  overflow-y: auto;
  padding: 12px;
  background: white;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
`;

const LegendPanel = styled.div`
  padding: 8px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
`;

const LegendGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  flex-wrap: nowrap;
`;

const LegendText = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.neutral.dark.darkest};
  min-width: max-content;
`;

const DeckTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
  color: ${props => props.theme.colors.neutral.dark.darkest};
`;

const DeckContainer = styled.div`
  margin-bottom: 16px;
`;

const CabinSelectorPanel = styled.div`
  padding: 12px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
`;

const CabinOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`;

const CabinOption = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 4px;
  cursor: pointer;
  background: ${props => props.$isSelected ? props.theme.colors.highlight.lightest : 'white'};
  color: ${props => props.theme.colors.neutral.dark.base};
  font-size: 14px;

  &:hover {
    background: ${props => props.theme.colors.neutral.light.lightest};
  }

  input {
    margin: 0;
  }
`;

const CabinSection = styled.div`
  margin-bottom: 24px;
`;

const CabinHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 1px;
`;

const CabinTitle = styled.h3`
    font-size: 18px;
    font-weight: 500;
    color: ${props => props.theme.colors.neutral.dark.darkest};
`;

const LoadingMessage = styled.div`
    padding: 12px;
    color: ${props => props.theme.colors.neutral.dark.base};
    text-align: center;
    background: ${props => props.theme.colors.neutral.light.lightest};
    border-radius: 8px;
    margin-bottom: 16px;
`;

const ErrorMessage = styled(LoadingMessage)`
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  color: ${props => props.theme.colors.neutral.dark.darkest};
  line-height: 1.4;
  font-size: 12px;
`;

const LastUpdated = styled.div`
    font-size: 10px;
    color: ${props => props.theme.colors.neutral.dark.base};
    text-align: right;
`;

const RefreshButton = styled.button`
    background: none;
    border: none;
    padding: 0;
    margin-left: 8px;
    cursor: pointer;
    color: ${props => props.theme.colors.neutral.dark.base};
    display: inline-flex;
    align-items: center;
    font-size: 10px;
    opacity: ${props => props.$isRefreshing ? 0.5 : 0.7};
    transition: opacity 0.2s;
    pointer-events: ${props => props.$isRefreshing ? 'none' : 'auto'};

    &:hover {
        opacity: ${props => props.$isRefreshing ? 0.5 : 1};
    }

    svg {
        width: 12px;
        height: 12px;
        transform: ${props => props.$isRefreshing ? 'rotate(180deg)' : 'none'};
        transition: transform 0.3s;
    }
`;

const RefreshIcon = () => (
    <svg viewBox="0 0 24 24" fill="currentColor">
        <path d="M17.65 6.35A7.958 7.958 0 0012 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0112 18c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>
    </svg>
);

const LastUpdatedContainer = styled.div`
    display: flex;
    align-items: center;
`;

const SeatInput = styled.input`
  width: 40px;
  height: 24px;
  padding: 0 4px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 4px;
  font-size: 14px;
  margin-left: 4px;
  text-align: center;

  &::placeholder {
    color: ${props => props.theme.colors.neutral.dark.base};
    opacity: 0.5;
  }

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.highlight.darkest};
  }
`;

const AddSeatButton = styled.button`
  padding: 4px 8px;
  margin-left: 4px;
  border: none;
  border-radius: 4px;
  background: ${props => props.theme.colors.highlight.darkest};
  color: white;
  font-size: 12px;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    opacity: 0.9;
  }
`;

// Add a constant for cabin order
const CABIN_ORDER = [
  'FIRST',
  'BUSINESS',
  'PREMIUM_ECONOMY',
  'ECONOMY'
];

// Keep this outside
const getSeatmapTimestampField = (cabin) => {
    switch (cabin) {
        case 'FIRST':
            return 'first_class_seatmap_checked';
        case 'BUSINESS':
            return 'business_class_seatmap_checked';
        case 'PREMIUM_ECONOMY':
            return 'premium_economy_seatmap_checked';
        case 'ECONOMY':
            return 'economy_class_seatmap_checked';
        default:
            return null;
    }
};

const getRelativeTimeString = (timestamp) => {
    if (!timestamp) return 'never';

    const now = new Date();
    const lastUpdate = new Date(timestamp);
    const diffInSeconds = Math.floor((now - lastUpdate) / 1000);

    if (diffInSeconds < 60) return 'now';
    
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) return `${diffInMinutes} minute${diffInMinutes > 1 ? 's' : ''} ago`;
    
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
    
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 30) return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
    
    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) return `${diffInMonths} month${diffInMonths > 1 ? 's' : ''} ago`;
    
    const diffInYears = Math.floor(diffInDays / 365);
    return `${diffInYears} year${diffInYears > 1 ? 's' : ''} ago`;
};

function SeatmapPage() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const initialCabin = searchParams.get('cabin')?.toUpperCase() || 'ECONOMY';
  const [refreshingCabin, setRefreshingCabin] = useState(null);
  const [localTimestamps, setLocalTimestamps] = useState({});
  const [currentSeat, setCurrentSeat] = useState(null);
  const [seatWarning, setSeatWarning] = useState(null);
  const [tempSeat, setTempSeat] = useState('');

  console.log('Initial cabin from URL:', {
    rawCabin: searchParams.get('cabin'),
    normalizedCabin: initialCabin,
    defaultedCabin: initialCabin || 'ECONOMY'
  });

  const [selectedCabins, setSelectedCabins] = useState(() => {
    const initial = new Set([initialCabin]);
    console.log('Initializing selected cabins:', {
      rawInitialCabin: searchParams.get('cabin'),
      normalizedCabin: initialCabin,
      selectedCabins: Array.from(initial)
    });
    return initial;
  });

  const navigate = useNavigate();
  const [showDebug, setShowDebug] = useState(false);

  const queryClient = useQueryClient();

  // First, store the flight data query result
  const flightDataQuery = useQuery({
    queryKey: ['flight', id],
    queryFn: async () => {
        console.log('Fetching flight data from database...');
        const { data, error } = await supabase
            .from('flights')
            .select(`
                *,
                airlines (
                    name,
                    logo_url,
                    seat_back_color,
                    seat_base_color,
                    seat_cushion_color,
                    seat_person_color
                )
            `)
            .eq('id', id)
            .single();

        if (error) throw error;
        console.log('Flight data retrieved:', data);
        return data;
    }
  });

  // Then modify the seatmap queries to depend on flight data
  const firstClassQuery = useQuery({
    queryKey: ['seatmap', id, 'FIRST', refreshingCabin === 'FIRST'],
    queryFn: async () => {
      try {
        if (!refreshingCabin && flightDataQuery.data?.first_class_seatmap_error) {
          console.log('Using cached First Class error state:', {
            error: flightDataQuery.data.first_class_seatmap_error,
            timestamp: flightDataQuery.data.first_class_seatmap_checked
          });
          throw new Error('Seatmap not available');
        }

        if (!refreshingCabin && flightDataQuery.data?.first_class_seatmap_payload) {
          return {
            requestDetails: { seatmap: null },
            response: {
              seatmap: flightDataQuery.data.first_class_seatmap_payload,
              seatCounts: {
                first_seats: flightDataQuery.data.first_seats,
                business_seats: flightDataQuery.data.business_seats,
                premium_economy_seats: flightDataQuery.data.premium_economy_seats,
                economy_seats: flightDataQuery.data.economy_seats
              }
            }
          };
        }

        const result = await getSimplifiedSeatmap({ id }, { cabin: 'FIRST', class: 'F' });
        
        const { data, error: updateError } = await supabase
          .from('flights')
          .update({
            first_class_seatmap_payload: result.response.seatmap,
            first_class_seatmap_checked: new Date().toISOString(),
            first_class_seatmap_error: null
          })
          .eq('id', id)
          .select();

        if (!updateError) {
          setLocalTimestamps(prev => ({
            ...prev,
            FIRST: new Date().toISOString()
          }));
        }

        return result;

      } catch (error) {
        const { data, error: updateError } = await supabase
          .from('flights')
          .update({
            first_class_seatmap_error: true,
            first_class_seatmap_checked: new Date().toISOString(),
            first_class_seatmap_payload: null
          })
          .eq('id', id)
          .select();

        if (updateError) {
          console.error('Failed to update First Class error state:', updateError);
        }

        throw error;
      }
    },
    enabled: !!id && selectedCabins.has('FIRST') && !!flightDataQuery.data,
    retry: false,
    refetchOnWindowFocus: false
  });

  const businessQuery = useQuery({
    queryKey: ['seatmap', id, 'BUSINESS', refreshingCabin === 'BUSINESS'],
    queryFn: async () => {
      try {
        if (!refreshingCabin && flightDataQuery.data?.business_class_seatmap_error) {
          console.log('Using cached Business Class error state:', {
            error: flightDataQuery.data.business_class_seatmap_error,
            timestamp: flightDataQuery.data.business_class_seatmap_checked
          });
          throw new Error('Seatmap not available');
        }

        if (!refreshingCabin && flightDataQuery.data?.business_class_seatmap_payload) {
          return {
            requestDetails: { seatmap: null },
            response: {
              seatmap: flightDataQuery.data.business_class_seatmap_payload,
              seatCounts: {
                first_seats: flightDataQuery.data.first_seats,
                business_seats: flightDataQuery.data.business_seats,
                premium_economy_seats: flightDataQuery.data.premium_economy_seats,
                economy_seats: flightDataQuery.data.economy_seats
              }
            }
          };
        }

        const result = await getSimplifiedSeatmap({ id }, { cabin: 'BUSINESS', class: 'J' });
        
        const { data, error: updateError } = await supabase
          .from('flights')
          .update({
            business_class_seatmap_payload: result.response.seatmap,
            business_class_seatmap_checked: new Date().toISOString(),
            business_class_seatmap_error: null
          })
          .eq('id', id)
          .select();

        if (!updateError) {
          setLocalTimestamps(prev => ({
            ...prev,
            BUSINESS: new Date().toISOString()
          }));
        }

        return result;

      } catch (error) {
        const { data, error: updateError } = await supabase
          .from('flights')
          .update({
            business_class_seatmap_error: true,
            business_class_seatmap_checked: new Date().toISOString(),
            business_class_seatmap_payload: null
          })
          .eq('id', id)
          .select();

        if (updateError) {
          console.error('Failed to update Business Class error state:', updateError);
        }

        throw error;
      }
    },
    enabled: !!id && selectedCabins.has('BUSINESS') && !!flightDataQuery.data,
    retry: false,
    refetchOnWindowFocus: false
  });

  const premiumQuery = useQuery({
    queryKey: ['seatmap', id, 'PREMIUM_ECONOMY', refreshingCabin === 'PREMIUM_ECONOMY'],
    queryFn: async () => {
      try {
        if (!refreshingCabin && flightDataQuery.data?.premium_economy_seatmap_error) {
          console.log('Using cached Premium Economy error state:', {
            error: flightDataQuery.data.premium_economy_seatmap_error,
            timestamp: flightDataQuery.data.premium_economy_seatmap_checked
          });
          throw new Error('Seatmap not available');
        }

        if (!refreshingCabin && flightDataQuery.data?.premium_economy_seatmap_payload) {
          return {
            requestDetails: { seatmap: null },
            response: {
              seatmap: flightDataQuery.data.premium_economy_seatmap_payload,
              seatCounts: {
                first_seats: flightDataQuery.data.first_seats,
                business_seats: flightDataQuery.data.business_seats,
                premium_economy_seats: flightDataQuery.data.premium_economy_seats,
                economy_seats: flightDataQuery.data.economy_seats
              }
            }
          };
        }

        const result = await getSimplifiedSeatmap({ id }, { cabin: 'PREMIUM_ECONOMY', class: 'W' });
        
        const { data, error: updateError } = await supabase
          .from('flights')
          .update({
            premium_economy_seatmap_payload: result.response.seatmap,
            premium_economy_seatmap_checked: new Date().toISOString(),
            premium_economy_seatmap_error: null
          })
          .eq('id', id)
          .select();

        if (!updateError) {
          setLocalTimestamps(prev => ({
            ...prev,
            PREMIUM_ECONOMY: new Date().toISOString()
          }));
        }

        return result;

      } catch (error) {
        const { data, error: updateError } = await supabase
          .from('flights')
          .update({
            premium_economy_seatmap_error: true,
            premium_economy_seatmap_checked: new Date().toISOString(),
            premium_economy_seatmap_payload: null
          })
          .eq('id', id)
          .select();

        if (updateError) {
          console.error('Failed to update Premium Economy error state:', updateError);
        }

        throw error;
      }
    },
    enabled: !!id && selectedCabins.has('PREMIUM_ECONOMY') && !!flightDataQuery.data,
    retry: false,
    refetchOnWindowFocus: false
  });

  const economyQuery = useQuery({
    queryKey: ['seatmap', id, 'ECONOMY', refreshingCabin === 'ECONOMY'],
    queryFn: async () => {
      try {
        if (!refreshingCabin && flightDataQuery.data?.economy_class_seatmap_error) {
          console.log('Using cached Economy error state:', {
            error: flightDataQuery.data.economy_class_seatmap_error,
            timestamp: flightDataQuery.data.economy_class_seatmap_checked
          });
          throw new Error('Seatmap not available');
        }

        if (!refreshingCabin && flightDataQuery.data?.economy_class_seatmap_payload) {
          return {
            requestDetails: { seatmap: null },
            response: {
              seatmap: flightDataQuery.data.economy_class_seatmap_payload,
              seatCounts: {
                first_seats: flightDataQuery.data.first_seats,
                business_seats: flightDataQuery.data.business_seats,
                premium_economy_seats: flightDataQuery.data.premium_economy_seats,
                economy_seats: flightDataQuery.data.economy_seats
              }
            }
          };
        }

        const result = await getSimplifiedSeatmap({ id }, { cabin: 'ECONOMY', class: 'Y' });
        
        const { data, error: updateError } = await supabase
          .from('flights')
          .update({
            economy_class_seatmap_payload: result.response.seatmap,
            economy_class_seatmap_checked: new Date().toISOString(),
            economy_class_seatmap_error: null
          })
          .eq('id', id)
          .select();

        if (!updateError) {
          setLocalTimestamps(prev => ({
            ...prev,
            ECONOMY: new Date().toISOString()
          }));
        }

        return result;

      } catch (error) {
        const { data, error: updateError } = await supabase
          .from('flights')
          .update({
            economy_class_seatmap_error: true,
            economy_class_seatmap_checked: new Date().toISOString(),
            economy_class_seatmap_payload: null
          })
          .eq('id', id)
          .select();

        if (updateError) {
          console.error('Failed to update Economy error state:', updateError);
        }

        throw error;
      }
    },
    enabled: !!id && selectedCabins.has('ECONOMY') && !!flightDataQuery.data,
    retry: false,
    refetchOnWindowFocus: false
  });

  const queriesByCabin = {
    'FIRST': firstClassQuery,
    'BUSINESS': businessQuery,
    'PREMIUM_ECONOMY': premiumQuery,
    'ECONOMY': economyQuery
  };

  const isLoading = Object.values(queriesByCabin).some(query => 
    query.isEnabled && query.isLoading
  );

  const error = Object.values(queriesByCabin).find(query => 
    query.isEnabled && query.error
  )?.error;

  const debugData = selectedCabins.size === 1 ? {
    request: queriesByCabin[Array.from(selectedCabins)[0]]?.data?.requestDetails,
    response: queriesByCabin[Array.from(selectedCabins)[0]]?.data?.response
  } : {
    requests: Object.fromEntries(
      Array.from(selectedCabins).map(cabin => [
        cabin,
        queriesByCabin[cabin]?.data?.requestDetails
      ])
    ),
    responses: Object.fromEntries(
      Array.from(selectedCabins).map(cabin => [
        cabin,
        queriesByCabin[cabin]?.data?.response
      ])
    )
  };

  const cabinOptions = [
    { value: 'FIRST', label: 'First Class' },
    { value: 'BUSINESS', label: 'Business Class' },
    { value: 'PREMIUM_ECONOMY', label: 'Premium Economy' },
    { value: 'ECONOMY', label: 'Economy' }
  ];

  const handleCabinToggle = (cabinValue) => {
    const normalizedCabin = cabinValue.toUpperCase();
    console.log('Toggling cabin:', {
      rawValue: cabinValue,
      normalizedValue: normalizedCabin,
      currentlySelected: Array.from(selectedCabins),
      willBeSelected: !selectedCabins.has(normalizedCabin)
    });

    const newSelected = new Set(selectedCabins);
    if (newSelected.has(normalizedCabin)) {
      if (newSelected.size > 1) {
        newSelected.delete(normalizedCabin);
      }
    } else {
      newSelected.add(normalizedCabin);
    }

    setSelectedCabins(newSelected);

    // Update URL with normalized cabin values
    const searchParams = new URLSearchParams();
    newSelected.forEach(cabin => {
      searchParams.append('cabin', cabin);
    });
    navigate(`${window.location.pathname}?${searchParams.toString()}`);

    // Force refresh of both flight data and relevant seatmap queries
    flightDataQuery.refetch();
    
    // Refetch the seatmap query for the toggled cabin
    const queryToRefetch = queriesByCabin[normalizedCabin];
    if (queryToRefetch) {
      queryToRefetch.refetch();
    }
  };

  // Log what's being rendered
  console.log('Render state:', {
    selectedCabins: Array.from(selectedCabins),
    queries: {
      first: {
        enabled: firstClassQuery.enabled,
        isLoading: firstClassQuery.isLoading,
        hasData: !!firstClassQuery.data
      },
      business: {
        enabled: businessQuery.enabled,
        isLoading: businessQuery.isLoading,
        hasData: !!businessQuery.data
      },
      premium: {
        enabled: premiumQuery.enabled,
        isLoading: premiumQuery.isLoading,
        hasData: !!premiumQuery.data
      },
      economy: {
        enabled: economyQuery.enabled,
        isLoading: economyQuery.isLoading,
        hasData: !!economyQuery.data
      }
    }
  });

  // Add validation logging for queries
  useEffect(() => {
    console.log('Query state validation:', {
      selectedCabins: Array.from(selectedCabins),
      firstClassEnabled: selectedCabins.has('FIRST'),
      firstClassQuery: {
        enabled: firstClassQuery.enabled,
        isLoading: firstClassQuery.isLoading,
        hasData: !!firstClassQuery.data
      }
    });
  }, [selectedCabins, firstClassQuery]);

  // Sort the selected cabins according to our preferred order
  const sortedSelectedCabins = Array.from(selectedCabins).sort(
    (a, b) => CABIN_ORDER.indexOf(a) - CABIN_ORDER.indexOf(b)
  );

  // Move updateSeatmapTimestamp inside the component
  const updateSeatmapTimestamp = async (cabin) => {
      const timestampField = getSeatmapTimestampField(cabin);
      if (!timestampField) {
          console.error('Invalid cabin for timestamp update:', cabin);
          return;
      }

      const timestamp = new Date().toISOString();
      const updateData = {
          [timestampField]: timestamp
      };

      console.log('Attempting timestamp update:', {
          cabin,
          timestampField,
          timestamp,
          flightId: id,
          updateData
      });

      try {
          const { data, error } = await supabase
              .from('flights')
              .update(updateData)
              .eq('id', id)
              .select();

          if (error) {
              console.error('Timestamp update failed:', {
                  error,
                  cabin,
                  timestampField,
                  updateData
              });
              throw error;
          }
          
          console.log('Timestamp update succeeded:', {
              success: !error,
              updatedData: data,
              timestampField,
              timestamp
          });

          return data;
      } catch (err) {
          console.error(`Error in updateSeatmapTimestamp for ${cabin}:`, err);
          throw err;
      }
  };

  // Add effect to sync local timestamps with database data
  useEffect(() => {
    if (flightDataQuery.data) {
        console.log('Database timestamps update:', {
            flightData: flightDataQuery.data,
            timestamps: {
                FIRST: flightDataQuery.data[getSeatmapTimestampField('FIRST')],
                BUSINESS: flightDataQuery.data[getSeatmapTimestampField('BUSINESS')],
                PREMIUM_ECONOMY: flightDataQuery.data[getSeatmapTimestampField('PREMIUM_ECONOMY')],
                ECONOMY: flightDataQuery.data[getSeatmapTimestampField('ECONOMY')]
            }
        });

        // Create new timestamps object with all cabin timestamps
        const newTimestamps = {};
        let hasChanges = false;

        CABIN_ORDER.forEach(cabin => {
            const dbTimestamp = flightDataQuery.data[getSeatmapTimestampField(cabin)];
            const localTimestamp = localTimestamps[cabin];
            
            // Use the most recent timestamp between local and DB
            let finalTimestamp;
            if (dbTimestamp && localTimestamp) {
                const dbDate = new Date(dbTimestamp);
                const localDate = new Date(localTimestamp);
                finalTimestamp = dbDate > localDate ? dbTimestamp : localTimestamp;
            } else {
                finalTimestamp = dbTimestamp || localTimestamp;
            }

            newTimestamps[cabin] = finalTimestamp;
            
            // Check if this timestamp is different from the current one
            if (finalTimestamp !== localTimestamps[cabin]) {
                hasChanges = true;
            }
        });

        // Only update if there are actual changes
        if (hasChanges) {
            console.log('Setting local timestamps:', newTimestamps);
            setLocalTimestamps(newTimestamps);
        }
    }
  }, [flightDataQuery.data]); // Remove localTimestamps from dependencies

  // Get current seat from both URL and flight data
  useEffect(() => {
    const currentSeatParam = searchParams.get('currentSeat');
    const flightSeatNumber = flightDataQuery.data?.seat_number;
    
    console.log('Checking seat sources:', {
      fromURL: currentSeatParam,
      fromFlightData: flightSeatNumber,
      currentState: currentSeat
    });

    // Priority: URL param > flight data > null
    const newSeatValue = currentSeatParam || flightSeatNumber || null;
    
    if (newSeatValue !== currentSeat) {
      console.log('Updating current seat:', newSeatValue);
      setCurrentSeat(newSeatValue);
    }
  }, [searchParams, flightDataQuery.data, currentSeat]);

  const handleSeatUpdate = async (seatNumber) => {
    try {
      const { error } = await supabase
        .from('flights')
        .update({ seat_number: seatNumber })
        .eq('id', id);

      if (error) throw error;
      
      // Update current seat state
      setCurrentSeat(seatNumber);
      
      // Refetch flight data
      flightDataQuery.refetch();

      // Update URL to include the new seat number
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('currentSeat', seatNumber);
      navigate(`?${newSearchParams.toString()}`, { replace: true });
    } catch (error) {
      console.error('Error updating seat:', error);
    }
  };

  return (
    <PageContainer>
      <MainHeader />
      <SubHeader 
        mainPage="My Flights"
        subPage={flightDataQuery.data ? `${flightDataQuery.data.airline_code} ${flightDataQuery.data.flight_number}` : 'Loading...'}
        subSubPage="Seatmap"
        onBack={() => navigate(`/flights/${id}`)}
        onMainClick={() => navigate('/flights')}
        onSubClick={() => navigate(`/flights/${id}`)}
      />
      <ContentContainer>
        {flightDataQuery.data && (
          <FlightCardContainer>
            <FlightCard 
              airlineLogo={flightDataQuery.data.airlines?.logo_url}
              airlineCode={flightDataQuery.data.airline_code}
              flightNumber={flightDataQuery.data.flight_number}
              date={flightDataQuery.data.departure_time.split('T')[0]}
              departureTime={flightDataQuery.data.departure_time}
              departureAirport={flightDataQuery.data.departure_airport_code}
              departureCode={flightDataQuery.data.departure_airport_code}
              arrivalAirport={flightDataQuery.data.arrival_airport_code}
              arrivalCode={flightDataQuery.data.arrival_airport_code}
              arrivalTime={flightDataQuery.data.arrival_time}
              distance={flightDataQuery.data.distance_miles}
              duration={flightDataQuery.data.duration_mins}
            />
          </FlightCardContainer>
        )}

        <CabinSelectorPanel>
          <CabinOptions>
            {cabinOptions.map(option => (
              <CabinOption 
                key={option.value}
                $isSelected={selectedCabins.has(option.value)}
              >
                <input
                  type="checkbox"
                  checked={selectedCabins.has(option.value)}
                  onChange={() => handleCabinToggle(option.value)}
                />
                {option.label}
              </CabinOption>
            ))}
          </CabinOptions>
        </CabinSelectorPanel>

        <LegendPanel>
          <LegendGrid>
            {[
              { type: 'Available', props: { isAvailable: true } },
              { type: 'Occupied', props: { isAvailable: false, isOccupied: true } },
              { type: 'Blocked', props: { isAvailable: false, isOccupied: false, isBlocked: true } },
              { 
                type: flightDataQuery.data?.seat_number 
                  ? `Your Seat - ${flightDataQuery.data.seat_number}` 
                  : 'Your Seat', 
                props: { isAvailable: true, isSelected: true },
                showInput: !flightDataQuery.data?.seat_number
              }
            ].map(({ type, props, showInput }) => (
              <LegendItem key={type}>
                <Seat 
                  size={24}
                  {...props}
                  backColor={flightDataQuery.data?.airlines?.seat_back_color}
                  baseColor={flightDataQuery.data?.airlines?.seat_base_color}
                  cushionColor={flightDataQuery.data?.airlines?.seat_cushion_color}
                />
                <LegendText>{type}</LegendText>
                {showInput && (
                  <>
                    <SeatInput 
                      placeholder="12A"
                      value={tempSeat}
                      onChange={(e) => setTempSeat(e.target.value)}
                      maxLength={4}
                    />
                    <AddSeatButton onClick={() => {
                      if (tempSeat) {
                        handleSeatUpdate(tempSeat.toUpperCase());
                        setTempSeat('');
                      }
                    }}>
                      Add
                    </AddSeatButton>
                  </>
                )}
              </LegendItem>
            ))}
          </LegendGrid>
        </LegendPanel>

        {sortedSelectedCabins.map(selectedCabin => {
          const query = queriesByCabin[selectedCabin];
          
          return (
            <CabinSection key={selectedCabin}>
              <CabinHeader>
                <CabinTitle>
                  {cabinOptions.find(opt => opt.value === selectedCabin)?.label}
                </CabinTitle>
                <LastUpdatedContainer>
                  <LastUpdated>
                    {(() => {
                        const localTimestamp = localTimestamps[selectedCabin];
                        const dbTimestamp = flightDataQuery.data?.[getSeatmapTimestampField(selectedCabin)];
                        const finalTimestamp = localTimestamp || dbTimestamp;
                        
                        console.log('Timestamp render:', {
                            cabin: selectedCabin,
                            localTimestamp,
                            dbTimestamp,
                            finalTimestamp,
                            formatted: getRelativeTimeString(finalTimestamp)
                        });
                        
                        return `Last updated - ${getRelativeTimeString(finalTimestamp)}`;
                    })()}
                  </LastUpdated>
                  <RefreshButton 
                    onClick={async () => {
                        setRefreshingCabin(selectedCabin);
                        try {
                            await queriesByCabin[selectedCabin].refetch({ cancelRefetch: true });
                            // Force invalidate the query to ensure fresh data
                            queryClient.invalidateQueries(['seatmap', id, selectedCabin]);
                        } finally {
                            setRefreshingCabin(null);
                        }
                    }}
                    title="Refresh seatmap"
                    $isRefreshing={refreshingCabin === selectedCabin}
                    disabled={refreshingCabin === selectedCabin}
                  >
                    <RefreshIcon />
                  </RefreshButton>
                </LastUpdatedContainer>
              </CabinHeader>

              {query?.isLoading && (
                <LoadingMessage>
                  Loading {selectedCabin.toLowerCase()} seats...
                </LoadingMessage>
              )}

              {query?.error && (
                <ErrorMessage>
                  Seatmap not available for this cabin at this time
                </ErrorMessage>
              )}

              {/* Only show deck data if we have successful query data */}
              {query?.data?.response?.seatmap?.data?.[0]?.decks?.map((deck, index, decks) => (
                <DeckContainer key={`${deck.deckType}-${selectedCabin}`}>
                  {decks.length > 1 && (
                    <DeckTitle>
                      {deck.deckType === 'UPPER' ? 'Upper Deck' : 'Main Deck'}
                    </DeckTitle>
                  )}
                  <NewSeatmapContainer 
                    seatmapData={{
                      data: [{
                        decks: [deck]
                      }],
                      dictionaries: query.data.response.seatmap.dictionaries
                    }}
                    backColor={flightDataQuery.data?.airlines?.seat_back_color}
                    baseColor={flightDataQuery.data?.airlines?.seat_base_color}
                    cushionColor={flightDataQuery.data?.airlines?.seat_cushion_color}
                    cabinFilter={selectedCabin}
                    currentSeat={currentSeat}
                  />
                </DeckContainer>
              ))}
            </CabinSection>
          );
        })}

        <DebugPanel>
          <DebugHeader>
            <DebugTitle>API Response Data</DebugTitle>
            <DebugToggle onClick={() => setShowDebug(!showDebug)}>
              {showDebug ? 'Hide' : 'Show'} Raw Data
            </DebugToggle>
          </DebugHeader>
          {showDebug && (
            <DebugContent>
              {JSON.stringify(debugData, null, 2)}
            </DebugContent>
          )}
        </DebugPanel>
      </ContentContainer>
    </PageContainer>
  );
}

export default SeatmapPage; 