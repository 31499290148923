import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { LAYOUT } from '../../styles/layout';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../lib/supabase';

const SelectorContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const IconContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50%;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const SeatIcon = styled.img`
  width: 100%;
  height: 100%;
  max-width: 48px;
  max-height: 48px;
  object-fit: contain;
`;

const NumberContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SeatNumber = styled.span`
  color: ${props => props.theme.colors.neutral.dark.darkest};
  font-weight: ${props => props.$isAdd ? '400' : '600'};
  font-size: 24px;
  cursor: pointer;
  background: ${props => props.$isEditing ? '#F5F7FF' : 'transparent'};
  padding: 4px 8px;
  border-radius: 8px;
  transition: background-color 0.2s;
  width: fit-content;
  min-width: 48px;
  text-align: center;

  &:hover {
    background: #F5F7FF;
  }

  input {
    font-size: 24px;
    font-weight: 600;
    width: 48px;
    text-align: center;
    border: none;
    outline: none;
    padding: 0;
    background: transparent;
  }
`;

function SeatSelector({ seatNumber, flightId, flight, onUpdate }) {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [tempSeatNumber, setTempSeatNumber] = useState(seatNumber || '');

  const handleSeatUpdate = async (value) => {
    if (value.trim()) {
      try {
        const { error } = await supabase
          .from('flights')
          .update({ seat_number: value.trim() })
          .eq('id', flightId)
          .single();

        if (error) throw error;
        
        // Update local state and notify parent
        const updatedSeatNumber = value.trim();
        setTempSeatNumber(updatedSeatNumber);
        if (onUpdate) onUpdate(updatedSeatNumber);
        setIsEditing(false);
      } catch (error) {
        console.error('Error updating seat:', error);
      }
    }
  };

  const handleSeatmapClick = (e) => {
    e.stopPropagation();
    console.log('Navigating to seatmap with:', {
      flightId: flight.id,
      cabin: flight.seat_class,
      currentSeat: flight.seat_number,
      fullFlightData: flight
    });

    if (flight.seat_class) {
      navigate(`/flights/${flight.id}/seat?cabin=${flight.seat_class}&currentSeat=${flight.seat_number || ''}`);
    } else {
      // TODO: Handle no cabin class case
      console.warn('No cabin class selected');
    }
  };

  return (
    <SelectorContainer>
      <IconContainer onClick={handleSeatmapClick}>
        <SeatIcon src="/icons/seaticon.png" alt="View Seatmap" />
      </IconContainer>
      <NumberContainer>
        <SeatNumber 
          onClick={() => setIsEditing(true)}
          $isEditing={isEditing}
          $isAdd={!seatNumber}
        >
          {isEditing ? (
            <input
              type="text"
              value={tempSeatNumber}
              onChange={(e) => setTempSeatNumber(e.target.value.toUpperCase())}
              onBlur={() => handleSeatUpdate(tempSeatNumber)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSeatUpdate(tempSeatNumber);
                }
              }}
              autoFocus
              onClick={(e) => e.stopPropagation()}
            />
          ) : (
            seatNumber || 'Add'
          )}
        </SeatNumber>
      </NumberContainer>
    </SelectorContainer>
  );
}

SeatSelector.propTypes = {
  seatNumber: PropTypes.string,
  flightId: PropTypes.string.isRequired,
  flight: PropTypes.object.isRequired,
  onUpdate: PropTypes.func
};

export default SeatSelector; 