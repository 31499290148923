import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import styled from 'styled-components';
import { trackApiCall } from '../../lib/api-tracking';

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
`;

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

function FlightMap({ departure, arrival }) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const hasTrackedRef = useRef(false);

  useEffect(() => {
    if (!departure || !arrival) return;
    if (map.current) return;

    if (!hasTrackedRef.current) {
      trackApiCall('mapbox', 'map_load');
      hasTrackedRef.current = true;
    }

    // Calculate bounds to fit both points
    const bounds = new mapboxgl.LngLatBounds(
      [departure.longitude, departure.latitude],
      [arrival.longitude, arrival.latitude]
    );

    // Initialize map with more colorful style
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/navigation-day-v1', // More colorful style
      bounds: bounds,
      padding: { top: 50, bottom: 50, left: 50, right: 50 }, // Padding around bounds
      fitBoundsOptions: { maxZoom: 12 } // Prevent too much zoom
    });

    map.current.addControl(new mapboxgl.NavigationControl());

    map.current.on('load', () => {
      // Add departure marker with custom color
      new mapboxgl.Marker({ color: '#006FFD' })
        .setLngLat([departure.longitude, departure.latitude])
        .setPopup(
          new mapboxgl.Popup({ offset: 25 })
            .setHTML(`
              <div style="padding: 8px;">
                <h3 style="margin: 0; color: #006FFD;">${departure.city}</h3>
                <p style="margin: 4px 0 0 0;">${departure.code}</p>
              </div>
            `)
        )
        .addTo(map.current);

      // Add arrival marker
      new mapboxgl.Marker({ color: '#2563eb' }) // Slightly different blue for arrival
        .setLngLat([arrival.longitude, arrival.latitude])
        .setPopup(
          new mapboxgl.Popup({ offset: 25 })
            .setHTML(`
              <div style="padding: 8px;">
                <h3 style="margin: 0; color: #2563eb;">${arrival.city}</h3>
                <p style="margin: 4px 0 0 0;">${arrival.code}</p>
              </div>
            `)
        )
        .addTo(map.current);

      // Add the flight path line with gradient
      map.current.addSource('route', {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'LineString',
            'coordinates': [
              [departure.longitude, departure.latitude],
              [arrival.longitude, arrival.latitude]
            ]
          }
        }
      });

      map.current.addLayer({
        'id': 'route',
        'type': 'line',
        'source': 'route',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': '#006FFD',
          'line-width': 3,
          'line-opacity': 0.8,
          'line-dasharray': [2, 2]
        }
      });
    });

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [departure, arrival]);

  return <MapContainer ref={mapContainer} />;
}

export default FlightMap; 