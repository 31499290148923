export const AIRPORT_TIMEZONES = {
  'SIN': {
    offset: 8,
    format: '+08:00'
  },
  'SYD': {
    offset: 11,
    format: '+11:00'
  },
  'LHR': {
    offset: 0,
    format: 'Z'
  }
  // Add more airports as needed
};

export const getAirportTimezone = (airportCode) => {
  const timezone = AIRPORT_TIMEZONES[airportCode];
  if (!timezone) {
    console.warn(`No timezone data for airport: ${airportCode}, defaulting to UTC`);
    return { offset: 0, format: 'Z' };
  }
  return timezone;
}; 