export const validatePassword = (password) => {
  const requirements = {
    minLength: password.length >= 8,
    hasUpperCase: /[A-Z]/.test(password),
    hasLowerCase: /[a-z]/.test(password),
    hasNumber: /[0-9]/.test(password),
    hasSpecial: /[!@#$%^&*(),.?":{}|<>]/.test(password),
  };

  const messages = {
    minLength: 'At least 8 characters',
    hasUpperCase: 'One uppercase letter',
    hasLowerCase: 'One lowercase letter',
    hasNumber: 'One number',
    hasSpecial: 'One special character',
  };

  return {
    isValid: Object.values(requirements).every(Boolean),
    requirements,
    messages,
  };
}; 