export const COLORS = {
  primary: {
    base: '#1976D2',
    light: '#42A5F5',
    dark: '#1565C0'
  },
  neutral: {
    dark: {
      base: '#333333',
      light: '#666666'
    },
    light: {
      base: '#F5F5F5',
      dark: '#E0E0E0'
    }
  },
  error: '#D32F2F',
  success: '#2E7D32',
  warning: '#ED6C02'
}; 