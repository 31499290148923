import { supabase } from './supabase';

export async function trackApiCall(apiName, endpoint) {
  try {
    console.log('Tracking API call:', { apiName, endpoint });
    
    const { data, error } = await supabase.rpc('increment_api_call', {
      p_api_name: apiName,
      p_endpoint: endpoint
    });
    
    if (error) {
      console.error('Error in trackApiCall:', error);
      throw error;
    }
    
    console.log('API call tracked successfully:', data);
    return data;
  } catch (error) {
    console.error('Error tracking API call:', error.message);
    // Don't throw the error to prevent app disruption
  }
} 