import React, { useState } from 'react';
import styled from 'styled-components';
import { H1, BodyM } from '../styles/typography';
import { supabase } from '../lib/supabase';
import { validatePassword } from '../utils/validation';

// Use same styled components as LoginPage
const PageContainer = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.colors.neutral.light.lightest};
`;

const LogoContainer = styled.div`
  width: 100%;
  height: 208px;
  background-color: ${props => props.theme.colors.highlight.lightest};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  padding-top: 6px;
`;

const Logo = styled.img`
  width: 196px;
  height: 196px;
`;

const Title = styled(H1)`
  margin-bottom: 16px;
  padding: 0 24px;
`;

const Form = styled.form`
  width: 100%;
  max-width: 428px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
  box-sizing: border-box;
`;

const Input = styled.input`
  width: 100%;
  padding: 16px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.highlight.darkest};
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 16px;
  background-color: ${props => props.theme.colors.highlight.darkest};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin: 4px 0;
`;

const ErrorMessage = styled(BodyM)`
  color: ${props => props.theme.colors.support.error.dark};
  text-align: center;
  margin-bottom: 16px;
`;

const LoginContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: baseline;
  justify-content: center;
  margin-top: auto;
`;

const LoginLink = styled(BodyM)`
  color: ${props => props.theme.colors.highlight.darkest};
  cursor: pointer;
`;

const Requirements = styled.div`
  margin: 8px 0;
  font-size: 14px;
`;

const Requirement = styled.div`
  color: ${props => props.$met 
    ? props.theme.colors.support.success.dark 
    : props.theme.colors.neutral.dark.light};
  display: flex;
  align-items: center;
  gap: 8px;
  
  &:before {
    content: '${props => props.$met ? '✓' : '○'}';
  }
`;

const SuccessMessage = styled(BodyM)`
  color: ${props => props.theme.colors.support.success.dark};
  text-align: center;
  margin-bottom: 16px;
`;

function RegisterPage({ onBackToLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validation, setValidation] = useState(validatePassword(''));
  const [success, setSuccess] = useState(false);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setValidation(validatePassword(newPassword));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    if (!validation.isValid) {
      setError("Password doesn't meet requirements");
      return;
    }

    try {
      setError(null);
      setLoading(true);
      
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          emailRedirectTo: 'https://flyercopilot.com/auth/callback'
        }
      });

      if (error) throw error;

      if (data?.user) {
        setSuccess(true);
        setError('Please check your email to verify your account.');
      }
    } catch (error) {
      console.error('Registration error:', error);
      if (error.message.includes('already registered')) {
        setError('This email is already registered. Please try logging in instead.');
      } else if (error.message.includes('valid email')) {
        setError('Please enter a valid email address.');
      } else {
        setError(error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer>
      <LogoContainer>
        <Logo src="/logo.png" alt="FlyerCopilot Logo" />
      </LogoContainer>
      <Title>Create Account</Title>
      <Form onSubmit={handleRegister}>
        <Input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={loading}
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={handlePasswordChange}
          disabled={loading}
          autoComplete="new-password"
        />
        <Requirements>
          {Object.entries(validation.requirements).map(([key, met]) => (
            <Requirement key={key} $met={met}>
              {validation.messages[key]}
            </Requirement>
          ))}
        </Requirements>
        <Input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          disabled={loading}
          autoComplete="new-password"
        />
        <Button type="submit" disabled={loading || !validation.isValid}>
          {loading ? 'Creating Account...' : 'Create Account'}
        </Button>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {success && (
          <SuccessMessage>
            Account created! Please check your email to verify your account before logging in.
          </SuccessMessage>
        )}
        <LoginContainer>
          <BodyM>Already have an account?</BodyM>
          <LoginLink onClick={onBackToLogin}>Login</LoginLink>
        </LoginContainer>
      </Form>
    </PageContainer>
  );
}

export default RegisterPage; 