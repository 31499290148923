import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { H3 } from '../../styles/typography';
import TransportOptionCard from '../TransportOptionCard/TransportOptionCard';

const CardContainer = styled.div`
  width: calc(100% - 8px);
  padding: 8px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 8px;
  margin: 8px 4px 0;
  box-sizing: border-box;
  max-height: 160px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  margin-bottom: 8px;
`;

const HeaderText = styled.h4`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
`;

const ScrollContent = styled.div`
  flex: 1;
  overflow-y: auto;
  
  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.neutral.light.dark};
    border-radius: 3px;
  }
`;

function AirportTransportCard({ airport }) {
  console.log('=== AirportTransportCard Debug ===');
  console.log('Full airport object:', airport);
  console.log('Airport name:', airport?.name);
  console.log('Airport metadata:', airport?.metadata);

  // Helper function to get transport option name from subcategory_id
  const getTransportName = (subcategoryId) => {
    const transportTypes = {
      1: "Taxi",
      2: "Private Transfer",
      3: "Bus",
      4: "Train",
      5: "Check-in",
    };
    return transportTypes[subcategoryId] || "Other";
  };

  const filteredOptions = airport?.metadata?.filter(item => item.subcategory_id <= 4) || [];
  console.log('Filtered transport options:', filteredOptions);

  return (
    <CardContainer>
      <Header>
        <HeaderText>Getting to {airport?.name}</HeaderText>
      </Header>
      <ScrollContent>
        {filteredOptions.map((option, index) => {
          console.log('Rendering transport option:', {
            subcategory_id: option.subcategory_id,
            title: getTransportName(option.subcategory_id),
            rating: option.rating,
            description: option.description
          });
          return (
            <TransportOptionCard
              key={index}
              title={getTransportName(option.subcategory_id)}
              rating={option.rating}
              description={option.description}
              url={option.url}
            />
          );
        })}
      </ScrollContent>
    </CardContainer>
  );
}

AirportTransportCard.propTypes = {
  airport: PropTypes.shape({
    name: PropTypes.string.isRequired,
    metadata: PropTypes.arrayOf(
      PropTypes.shape({
        subcategory_id: PropTypes.number.isRequired,
        rating: PropTypes.number,
        description: PropTypes.string.isRequired,
        url: PropTypes.string
      })
    )
  }).isRequired
};

export default AirportTransportCard; 