export const LAYOUT = {
  // Core widths
  MOBILE_WIDTH: 390,
  CONTENT_PADDING: 12,
  
  // Header heights
  MAIN_HEADER_HEIGHT: 56,
  SUB_HEADER_HEIGHT: 44,
  TOTAL_HEADER_HEIGHT: 100, // MAIN_HEADER_HEIGHT + SUB_HEADER_HEIGHT
  
  // Helper functions
  getContentWidth: () => LAYOUT.MOBILE_WIDTH - (LAYOUT.CONTENT_PADDING * 2),
}; 