import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';

const SeatContainer = styled.div`
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  position: relative;
  cursor: pointer;
  opacity: ${props => props.$isAvailable ? 1 : 0.5};
  perspective: 800px;
  transform: ${props => props.$isRearFacing ? 'rotateY(180deg)' : ''};
  transform-origin: center center;
  box-sizing: border-box;
`;

const SeatSVG = styled.svg`
  width: 100%;
  height: 100%;
  transform: rotateX(15deg);
  transform-origin: center bottom;

  @keyframes glowAndScale {
    0% { 
      filter: drop-shadow(0 0 4px rgba(39, 73, 196, 0.5));
      transform: scale(1) rotateX(15deg);
    }
    50% { 
      filter: drop-shadow(0 0 20px rgba(0, 255, 0, 0.8));
      transform: scale(1.1) rotateX(15deg);
    }
    100% { 
      filter: drop-shadow(0 0 4px rgba(39, 73, 196, 0.5));
      transform: scale(1) rotateX(15deg);
    }
  }

  @keyframes colorPulse {
    0% { 
      --seat-color: #2749C4;
      --seat-back-color: #1939B4;
    }
    50% { 
      --seat-color: #00CC00;
      --seat-back-color: #009900;
    }
    100% { 
      --seat-color: #2749C4;
      --seat-back-color: #1939B4;
    }
  }

  animation: ${props => props.$isSelected ? 
    'glowAndScale 2s infinite ease-in-out, colorPulse 2s infinite ease-in-out' 
    : 'none'};

  .seat-back {
    fill: ${props => props.$isSelected ? 'var(--seat-back-color, #1939B4)' : props.$backColor};
    stroke: ${props => props.theme.colors.neutral.dark.darkest};
    stroke-width: 1;
  }

  .seat-base {
    fill: ${props => props.$isSelected ? 'var(--seat-color, #2749C4)' : props.$baseColor};
    stroke: ${props => props.theme.colors.neutral.dark.darkest};
    stroke-width: 1;
  }

  .seat-cushion {
    fill: ${props => props.$cushionColor};
    stroke: ${props => props.theme.colors.neutral.dark.darkest};
    stroke-width: 1;
  }

  .person {
    fill: white;
    stroke: ${props => props.theme.colors.neutral.dark.darkest};
    stroke-width: 0.75;
  }
`;

const Seat = ({ 
  isAvailable = true, 
  isSelected = false,
  isOccupied = false,
  isRearFacing = false,
  isLieFlat = false,
  seatNumber,
  backColor,
  baseColor,
  cushionColor,
  size,
  onClick 
}) => {
  console.log('Seat render:', { 
    isSelected,
    isAvailable,
    isOccupied,
    isRearFacing,
    isLieFlat,
    seatNumber,
    backColor,
    baseColor,
    cushionColor,
    size
  });

  const theme = useTheme();
  const genericColors = {
    dark: '#9CA3AF',
    medium: '#D1D5DB',
    light: theme.colors.neutral.light.dark
  };

  const getElementColor = (customColor, state) => {
    if (customColor) return customColor;
    
    if (isOccupied || (!isAvailable && !isSelected)) {
      return genericColors.light;
    }
    return state === 'back' ? genericColors.dark : genericColors.medium;
  };

  const personPath = isLieFlat ? 
    // Lie-flat person with legs - centered on cushion
    "M8 28 L16 28 L14 20 L10 20 Z " +  // Body moved up, centered
    "M12 29 A2.5 2.5 0 0 1 12 34 A2.5 2.5 0 0 1 12 29 " +  // Head moved up
    "M11 20 L10 12 L11 12 L12 16 L13 12 L14 12 L13 20 Z"    // Legs moved up
    :
    // Regular upright person
    "M7 4 L17 4 L15 10 L9 10 Z M12 13 A2.5 2.5 0 0 1 12 18 A2.5 2.5 0 0 1 12 13";

  return (
    <SeatContainer 
      $isAvailable={isAvailable}
      onClick={onClick}
      size={size}
    >
      <SeatSVG 
        viewBox={isLieFlat ? "0 0 24 40" : "0 0 24 24"} 
        $isSelected={isSelected}
        $isOccupied={isOccupied}
        $isRearFacing={isRearFacing}
        $backColor={getElementColor(backColor, 'back')}
        $baseColor={getElementColor(baseColor, 'base')}
        $cushionColor={getElementColor(cushionColor, 'cushion')}
      >
        {/* Base components */}
        <rect 
          className="seat-base"
          x="4" 
          y={isLieFlat ? "2" : "2"}
          width="16" 
          height={isLieFlat ? "32" : "16"} 
          rx="2"
        />
        <rect 
          className="seat-cushion"
          x="6" 
          y={isLieFlat ? "4" : "4"}
          width="12" 
          height={isLieFlat ? "28" : "12"} 
          rx="1"
        />
        <path 
          className="seat-back"
          d={isLieFlat ? 
            "M3 34 L21 34 L19 38 L5 38 Z" : 
            "M3 18 L21 18 L19 22 L5 22 Z"
          }
        />
        
        {/* Person with conditional path */}
        {(isOccupied || isSelected) && (
          <path
            className="person"
            d={personPath}
          />
        )}
      </SeatSVG>
    </SeatContainer>
  );
};

Seat.propTypes = {
  isAvailable: PropTypes.bool,
  isSelected: PropTypes.bool,
  isOccupied: PropTypes.bool,
  isRearFacing: PropTypes.bool,
  isLieFlat: PropTypes.bool,
  seatNumber: PropTypes.string,
  backColor: PropTypes.string,
  baseColor: PropTypes.string,
  cushionColor: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func
};

export default Seat; 