import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { LAYOUT } from '../constants/layout';
import { COLORS } from '../constants/colors';
import { supabase } from '../lib/supabase';
import { useNavigate } from 'react-router-dom';
import MainHeader from '../components/MainHeader/MainHeader';
import SubHeader from '../components/SubHeader/SubHeader';
import { getFlightAvailability } from '../api/flights';

const PageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: min(${LAYOUT.MOBILE_WIDTH}px, 100vw);
  height: 100vh;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const HeaderArea = styled.div`
  width: 100%;
  flex-shrink: 0;
  height: ${LAYOUT.HEADER_HEIGHT + LAYOUT.SUBHEADER_HEIGHT}px;
`;

const ContentArea = styled.div`
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: ${LAYOUT.HEADER_HEIGHT + LAYOUT.SUBHEADER_HEIGHT}px;
  margin-top: -${LAYOUT.HEADER_HEIGHT + LAYOUT.SUBHEADER_HEIGHT}px;
`;

const SearchForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: ${LAYOUT.CONTENT_WIDTH}px;
  margin: 0 auto;
  padding: ${LAYOUT.PADDING}px;
  padding-top: ${LAYOUT.PADDING}px;
`;

const SavedFlightContainer = styled.div`
  background: white;
  border: 1px solid ${COLORS.neutral.light.dark};
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 16px;
`;

const SavedFlightLabel = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: ${COLORS.neutral.dark.base};
`;

const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 4px;
  font-weight: 500;
  color: ${COLORS.neutral.dark.base};
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid ${COLORS.neutral.light.dark};
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:focus {
    outline: none;
    border-color: ${COLORS.primary.base};
    box-shadow: 0 0 0 2px ${COLORS.primary.light}20;
  }
`;

const Button = styled.button`
  width: 100%;
  background: ${COLORS.primary.base};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background: ${COLORS.primary.dark};
  }

  &:disabled {
    background: ${COLORS.neutral.light.dark};
    cursor: not-allowed;
  }
`;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid ${COLORS.neutral.light.dark};
  border-radius: 50%;
  border-top-color: ${COLORS.primary.base};
  animation: spin 1s ease-in-out infinite;
  margin: 20px auto;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;

const ErrorMessage = styled.div`
  color: ${COLORS.error};
  background: ${COLORS.error}10;
  padding: 8px 12px;
  border-radius: 4px;
  margin: 8px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;

  &::before {
    content: "⚠";
  }
`;

const AutocompleteWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  margin: 4px 0 0 0;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid ${COLORS.neutral.light.dark};
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
`;

const DropdownItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  background: ${props => props.$isPopular ? props.theme.colors.neutral.light.base : 'transparent'};

  &:hover {
    background: ${COLORS.neutral.light.base};
  }
`;

const ItemCode = styled.span`
  font-weight: 500;
  color: ${COLORS.neutral.dark.base};
`;

const ItemName = styled.span`
  color: ${COLORS.neutral.dark.light};
  font-size: 0.9em;
`;

// Add this helper function at the top
const formatCityName = (name) => {
  return name
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const Autocomplete = ({ 
  options, 
  value, 
  onChange, 
  placeholder, 
  label, 
  id, 
  required 
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const wrapperRef = useRef(null);

  // Format the display text based on whether it's an airport or airline
  const formatDisplayText = (option, showFullText = true) => {
    if (!option) return '';
    if (!showFullText) return option.code;
    const formattedName = id.includes('airline') ? 
      option.name : 
      formatCityName(option.name);
    return `${option.code} - ${formattedName}`;
  };

  // Sort options by popularity and then alphabetically
  const sortOptions = (opts) => {
    return [...opts].sort((a, b) => {
      // First sort by popularity
      if (a.is_popular && !b.is_popular) return -1;
      if (!a.is_popular && b.is_popular) return 1;
      // Then sort alphabetically by code
      return a.code.localeCompare(b.code);
    });
  };

  // Initialize with current value and sorted options
  useEffect(() => {
    const option = options.find(opt => opt.code === value);
    if (option) {
      setSelectedOption(option);
      setSearchTerm(formatDisplayText(option, !isOpen));
    }
    setFilteredOptions(sortOptions(options));
  }, [value, options, id, isOpen]);

  // Filter and sort options based on search term
  useEffect(() => {
    if (!searchTerm.trim()) {
      setFilteredOptions(sortOptions(options));
    } else {
      const searchLower = searchTerm.toLowerCase();
      const filtered = options.filter(option => 
        option.code.toLowerCase().includes(searchLower) ||
        option.name.toLowerCase().includes(searchLower)
      );
      setFilteredOptions(sortOptions(filtered));
    }
  }, [searchTerm, options]);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
        if (selectedOption) {
          setSearchTerm(formatDisplayText(selectedOption, true));
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [selectedOption]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setSearchTerm(newValue);
    setIsOpen(true);
  };

  const handleSelect = (option) => {
    setSelectedOption(option);
    setSearchTerm(formatDisplayText(option, true));
    onChange({ target: { name: id, value: option.code }});
    setIsOpen(false);
  };

  const handleFocus = () => {
    setIsOpen(true);
    if (selectedOption) {
      setSearchTerm(formatDisplayText(selectedOption, false));
    }
    if (!selectedOption) {
      setFilteredOptions(options);
    }
  };

  const handleBlur = (e) => {
    // Don't update if clicking on dropdown
    if (wrapperRef.current && wrapperRef.current.contains(e.relatedTarget)) {
      return;
    }
    
    // Small delay to allow click events on dropdown
    setTimeout(() => {
      if (selectedOption && !isOpen) {
        setSearchTerm(formatDisplayText(selectedOption, true));
      }
    }, 100);
  };

  return (
    <FormGroup ref={wrapperRef}>
      <Label htmlFor={id}>{label}</Label>
      <AutocompleteWrapper>
        <Input
          type="text"
          id={id}
          value={searchTerm}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          required={required}
          autoComplete="off"
        />
        {isOpen && filteredOptions.length > 0 && (
          <DropdownList>
            {filteredOptions.map((option) => (
              <DropdownItem
                key={option.code}
                onClick={() => handleSelect(option)}
                tabIndex={0}
                $isPopular={option.is_popular}
              >
                <ItemCode>{option.code}</ItemCode>
                <ItemName>
                  {id.includes('airline') ? option.name : formatCityName(option.name)}
                  {option.is_popular && <PopularBadge>Popular</PopularBadge>}
                </ItemName>
              </DropdownItem>
            ))}
          </DropdownList>
        )}
      </AutocompleteWrapper>
    </FormGroup>
  );
};

const AvailabilityPanel = styled.div`
  margin-top: 24px;
  padding: 20px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  h3 {
    margin: 0 0 16px 0;
    color: ${COLORS.neutral.dark.base};
    font-size: 18px;
    font-weight: 600;
  }
`;

const CabinGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
`;

const CabinCard = styled.div`
  padding: 16px;
  background-color: ${COLORS.neutral.light.base};
  border-radius: 12px;
  border: 1px solid ${COLORS.neutral.light.dark};
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

const CabinName = styled.div`
  font-weight: 600;
  color: ${COLORS.neutral.dark.base};
  margin-bottom: 12px;
  font-size: 16px;
`;

const BookingCodesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const BookingCode = styled.div`
  font-size: 14px;
  color: ${props => props.$hasSeats ? 
    COLORS.neutral.dark.base :
    COLORS.neutral.dark.light};
  padding: 4px 8px;
  background-color: ${props => props.$hasSeats ?
    `${COLORS.neutral.light.dark}20` :
    `${COLORS.neutral.light.dark}10`};
  border-radius: 4px;
  display: inline-block;
  font-family: monospace;
  font-weight: ${props => props.$hasSeats ? 500 : 400};
  opacity: ${props => props.$hasSeats ? 1 : 0.7};
`;

const SimpleBookingList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 12px;
  background: ${COLORS.neutral.light.base};
  border-radius: 8px;
`;

const renderSimpleAvailability = (data) => {
  console.log('renderSimpleAvailability called with data:', data);
  
  if (!data?.data?.[0]?.segments?.[0]?.availabilityClasses) {
    console.log('No availability classes found in data');
    return null;
  }

  const availableClasses = data.data[0].segments[0].availabilityClasses;
  console.log('Available classes:', availableClasses);

  return (
    <AvailabilityPanel>
      <h3>Booking Classes</h3>
      <SimpleBookingList>
        {availableClasses.map((classInfo, index) => (
          <BookingCode 
            key={index}
            $hasSeats={classInfo.numberOfBookableSeats > 0}
          >
            {classInfo.class}{classInfo.numberOfBookableSeats}
          </BookingCode>
        ))}
      </SimpleBookingList>
    </AvailabilityPanel>
  );
};

const renderCabinAvailability = (data, cabins) => {
  console.log('renderCabinAvailability called with:', { data, cabins });

  if (!data?.data?.[0]?.segments?.[0]?.availabilityClasses) {
    console.log('No availability classes found in data');
    return null;
  }

  const availableClasses = data.data[0].segments[0].availabilityClasses;
  console.log('Available classes:', availableClasses);
  
  // Create a map of available seats by booking code
  const availabilityMap = availableClasses.reduce((acc, classInfo) => {
    acc[classInfo.class] = classInfo.numberOfBookableSeats;
    return acc;
  }, {});
  console.log('Availability map:', availabilityMap);

  // Group ALL possible booking codes by cabin
  const groupedClasses = {};
  
  // Helper to add booking codes to a cabin
  const addCodesToCabin = (cabinName, codes) => {
    if (cabinName && codes?.length > 0) {
      groupedClasses[cabinName] = codes.map(code => ({
        class: code,
        numberOfBookableSeats: availabilityMap[code] || 0
      }));
    }
  };

  // Add all possible booking codes for each cabin
  if (cabins.first_class_name) {
    addCodesToCabin(cabins.first_class_name, cabins.first_booking_codes);
  }
  if (cabins.business_class_name) {
    addCodesToCabin(cabins.business_class_name, cabins.business_booking_codes);
  }
  if (cabins.premium_economy_name) {
    addCodesToCabin(cabins.premium_economy_name, cabins.premium_booking_codes);
  }
  if (cabins.economy_class_name) {
    addCodesToCabin(cabins.economy_class_name, cabins.economy_booking_codes);
  }

  console.log('Grouped classes by cabin:', groupedClasses);

  // Add any booking codes that weren't in our mapping to an "Other" category
  const allDefinedCodes = [
    ...(cabins.first_booking_codes || []),
    ...(cabins.business_booking_codes || []),
    ...(cabins.premium_booking_codes || []),
    ...(cabins.economy_booking_codes || [])
  ];

  const unmappedClasses = availableClasses.filter(
    classInfo => !allDefinedCodes.includes(classInfo.class)
  );

  if (unmappedClasses.length > 0) {
    groupedClasses['Other'] = unmappedClasses;
    console.log('Unmapped classes:', unmappedClasses);
  }

  return (
    <AvailabilityPanel>
      <h3>Seat Availability by Cabin</h3>
      <CabinGrid>
        {Object.entries(groupedClasses).map(([cabinName, classes]) => (
          <CabinCard key={cabinName}>
            <CabinName>{cabinName}</CabinName>
            <BookingCodesContainer>
              {classes.map((classInfo, index) => (
                <BookingCode 
                  key={index}
                  $hasSeats={classInfo.numberOfBookableSeats > 0}
                >
                  {classInfo.class}{classInfo.numberOfBookableSeats}
                </BookingCode>
              ))}
            </BookingCodesContainer>
          </CabinCard>
        ))}
      </CabinGrid>
    </AvailabilityPanel>
  );
};

const renderAvailability = (data, airline) => {
  console.log('renderAvailability called with:', { data, airline });
  
  if (!data) {
    console.log('No data provided to renderAvailability');
    return null;
  }

  // If we have cabin definitions for this airline, use the organized view
  if (airline) {
    console.log('Using cabin view for airline:', airline);
    return renderCabinAvailability(data, airline);
  }
  
  // Otherwise show simple list view
  console.log('Using simple view (no airline cabins defined)');
  return renderSimpleAvailability(data);
};

// Add these styled components
const FormRow = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  margin-bottom: 8px;

  > * {
    flex: 1;
    margin-bottom: 0;
  }
`;

// Add styled components for different warning types
const WarningMessage = styled.div`
  color: ${COLORS.warning};
  background: ${COLORS.warning}10;
  padding: 8px 12px;
  border-radius: 4px;
  margin: 8px 0;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;

  &::before {
    content: "⚠️";
  }
`;

// Add styled components for popular items
const PopularBadge = styled.span`
  font-size: 0.7em;
  background: ${COLORS.primary.base}20;
  color: ${COLORS.primary.base};
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  font-weight: 500;
`;

const Select = styled.select`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid ${COLORS.neutral.light.dark};
  border-radius: 4px;
  font-size: 16px;
  background: white;
  cursor: pointer;

  option:first-child {
    background-color: #4285F4;
    color: white;
  }

  option[value="divider"] {
    color: #4285F4;
  }

  &:focus {
    outline: none;
    border-color: ${COLORS.primary.base};
    box-shadow: 0 0 0 2px ${COLORS.primary.light}20;
  }
`;

const Option = styled.option`
  color: ${props => props.$supported ? 'inherit' : COLORS.neutral.dark.light};
  background: ${props => props.$supported ? 'inherit' : COLORS.neutral.light.base};
`;

function FlightAvailabilityPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    origin: '',
    destination: '',
    date: '',
    airline: '',
    flightNumber: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [availabilityData, setAvailabilityData] = useState(null);
  const [airlineCabins, setAirlineCabins] = useState(null);
  const [airports, setAirports] = useState([]);
  const [airlines, setAirlines] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [supportStatus, setSupportStatus] = useState('not_validated');
  const [savedFlights, setSavedFlights] = useState([]);

  // Fetch saved flights on component mount
  useEffect(() => {
    const fetchSavedFlights = async () => {
      try {
        // First fetch all airline cabins
        const { data: cabinsData, error: cabinsError } = await supabase
          .from('airline_cabins')
          .select('airline_code, support_status');

        if (cabinsError) throw cabinsError;

        // Create a map of airline support status - only mark as unsupported if explicitly not_supported
        const airlineSupport = cabinsData.reduce((acc, cabin) => {
          // Only mark as false if explicitly not_supported
          acc[cabin.airline_code] = cabin.support_status !== 'not_supported';
          return acc;
        }, {});

        // Then fetch flights
        const { data: flightsData, error: flightsError } = await supabase
          .from('flights')
          .select(`
            id,
            airline_code,
            flight_number,
            departure_airport_code,
            arrival_airport_code,
            departure_time,
            airlines (
              name
            ),
            departure_airport:airports!departure_airport_code (
              name
            ),
            arrival_airport:airports!arrival_airport_code (
              name
            )
          `)
          .order('created_at', { ascending: false });

        if (flightsError) throw flightsError;

        // Combine flight data with support status
        const flightsWithSupport = flightsData.map(flight => ({
          ...flight,
          // If no support status found, assume supported
          supported: airlineSupport[flight.airline_code] ?? true
        }));

        // Sort flights: supported first, then unsupported
        const sortedFlights = flightsWithSupport.sort((a, b) => {
          if (a.supported === b.supported) return 0;
          return a.supported ? -1 : 1;
        });

        setSavedFlights(sortedFlights);
      } catch (err) {
        console.error('Error fetching saved flights:', err);
      }
    };

    fetchSavedFlights();
  }, []);

  // Handle saved flight selection
  const handleSavedFlightSelect = (e) => {
    const selectedFlight = savedFlights.find(f => f.id === e.target.value);
    if (selectedFlight) {
      // Format the date from departure_time
      const departureDate = new Date(selectedFlight.departure_time)
        .toISOString()
        .split('T')[0];

      setFormData({
        origin: selectedFlight.departure_airport_code,
        destination: selectedFlight.arrival_airport_code,
        date: departureDate,
        airline: selectedFlight.airline_code,
        flightNumber: selectedFlight.flight_number
      });
    }
  };

  // Fetch airports and airlines on component mount
  useEffect(() => {
    const fetchData = async () => {
      setDataLoading(true);
      try {
        // Fetch airports
        const { data: airportsData, error: airportsError } = await supabase
          .from('airports')
          .select('code, name')
          .order('code');

        if (airportsError) throw airportsError;

        // Fetch airlines
        const { data: airlinesData, error: airlinesError } = await supabase
          .from('airlines')
          .select('code, name')
          .order('code');

        if (airlinesError) throw airlinesError;

        setAirports(airportsData.map(airport => ({
          code: airport.code,
          name: airport.name
        })));

        setAirlines(airlinesData.map(airline => ({
          code: airline.code,
          name: airline.name
        })));
      } catch (err) {
        console.error('Error fetching reference data:', err);
        setError('Failed to load airports and airlines. Please try again later.');
      } finally {
        setDataLoading(false);
      }
    };

    fetchData();
  }, []);

  // Fetch airline cabin configuration when airline changes
  useEffect(() => {
    const fetchAirlineCabins = async () => {
      try {
        console.log('Fetching cabin config for airline:', formData.airline);
        const { data, error } = await supabase
          .from('airline_cabins')
          .select('*, support_status')
          .eq('airline_code', formData.airline)
          .single();

        if (error && error.code !== 'PGRST116') {
          console.error('Error fetching airline cabins:', error);
          throw error;
        }

        console.log('Fetched airline cabins:', data);
        setAirlineCabins(data || null);
        setSupportStatus(data?.support_status || 'not_validated');
      } catch (err) {
        console.error('Error in fetchAirlineCabins:', err);
        setAirlineCabins(null);
        setSupportStatus('not_validated');
      }
    };

    if (formData.airline) {
      fetchAirlineCabins();
    } else {
      setAirlineCabins(null);
      setSupportStatus('not_validated');
    }
  }, [formData.airline]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Prevent submission for unsupported airlines
    if (supportStatus === 'not_supported') {
      setError('This airline is not currently supported. Please select a different airline.');
      return;
    }

    setError(null);
    setLoading(true);

    try {
      // Validate all required fields
      if (!formData.origin || !formData.destination || !formData.airline || !formData.flightNumber) {
        throw new Error('All fields are required');
      }

      console.log('Submitting flight availability request:', formData);
      console.log('Current airline cabins:', airlineCabins);

      const data = await getFlightAvailability(formData);
      console.log('Flight availability response:', data);
      setAvailabilityData(data);
    } catch (err) {
      console.error('Submit error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/', { replace: true });
    } catch (error) {
      console.error('Error signing out:', error);
      navigate('/', { replace: true });
    }
  };

  // Add useEffect to log state changes
  useEffect(() => {
    console.log('availabilityData changed:', availabilityData);
    console.log('airlineCabins changed:', airlineCabins);
  }, [availabilityData, airlineCabins]);

  // Helper function to render support status message
  const renderSupportMessage = () => {
    if (!formData.airline) return null;

    switch (supportStatus) {
      case 'not_supported':
        return (
          <ErrorMessage>
            This airline is not currently supported. Please select a different airline.
          </ErrorMessage>
        );
      case 'not_validated':
        return (
          <WarningMessage>
            Airline not fully validated for availability yet - please email matthew@flyercopilot.com with any problems
          </WarningMessage>
        );
      default:
        return null;
    }
  };

  const renderFlightOptions = () => {
    const supportedFlights = savedFlights.filter(f => f.supported);
    const unsupportedFlights = savedFlights.filter(f => !f.supported);

    return (
      <>
        <option value="">Select a saved flight...</option>
        {supportedFlights.map((flight) => (
          <option 
            key={flight.id} 
            value={flight.id}
          >
            {flight.airlines?.name || flight.airline_code} {flight.flight_number} ({
              flight.departure_airport?.name 
                ? formatCityName(flight.departure_airport.name) 
                : flight.departure_airport_code
            } - {
              flight.arrival_airport?.name 
                ? formatCityName(flight.arrival_airport.name) 
                : flight.arrival_airport_code
            })
          </option>
        ))}
        {unsupportedFlights.length > 0 && (
          <option 
            disabled
            value="divider"
          >
            &#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500; Unsupported Airlines &#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;&#x2500;
          </option>
        )}
        {unsupportedFlights.map((flight) => (
          <option 
            key={flight.id} 
            value={flight.id}
          >
            {flight.airlines?.name || flight.airline_code} {flight.flight_number} ({
              flight.departure_airport?.name 
                ? formatCityName(flight.departure_airport.name) 
                : flight.departure_airport_code
            } - {
              flight.arrival_airport?.name 
                ? formatCityName(flight.arrival_airport.name) 
                : flight.arrival_airport_code
            })
          </option>
        ))}
      </>
    );
  };

  return (
    <PageContainer>
      <HeaderArea>
        <MainHeader onSignOut={handleSignOut} />
        <SubHeader 
          mainPage="My Flights"
          subPage="Flight Availability"
          onBack={() => navigate('/flights')}
          onMainClick={() => navigate('/flights')}
        />
      </HeaderArea>
      <ContentArea>
        {dataLoading ? (
          <LoadingSpinner />
        ) : error ? (
          <ErrorMessage>{error}</ErrorMessage>
        ) : (
          <>
            <SearchForm onSubmit={handleSubmit}>
              <SavedFlightContainer>
                <SavedFlightLabel>Quick Search from Saved Flights</SavedFlightLabel>
                <Select
                  onChange={handleSavedFlightSelect}
                  defaultValue=""
                >
                  {renderFlightOptions()}
                </Select>
              </SavedFlightContainer>
              <FormRow>
                <Autocomplete
                  id="origin"
                  label="Origin Airport"
                  options={airports}
                  value={formData.origin}
                  onChange={handleInputChange}
                  placeholder="Search airport..."
                  required
                />
                <Autocomplete
                  id="destination"
                  label="Destination Airport"
                  options={airports}
                  value={formData.destination}
                  onChange={handleInputChange}
                  placeholder="Search airport..."
                  required
                />
              </FormRow>
              <FormGroup>
                <Label htmlFor="date">Date</Label>
                <Input
                  type="date"
                  id="date"
                  name="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
              <FormRow>
                <Autocomplete
                  id="airline"
                  label="Airline"
                  options={airlines}
                  value={formData.airline}
                  onChange={handleInputChange}
                  placeholder="Search airline..."
                  required
                />
                <FormGroup>
                  <Label htmlFor="flightNumber">Flight Number</Label>
                  <Input
                    type="text"
                    id="flightNumber"
                    name="flightNumber"
                    value={formData.flightNumber}
                    onChange={handleInputChange}
                    required
                    placeholder="e.g. 123"
                  />
                </FormGroup>
              </FormRow>
              {renderSupportMessage()}
              <Button 
                type="submit" 
                disabled={loading || supportStatus === 'not_supported'}
              >
                {loading ? 'Searching...' : 'Search Flight'}
              </Button>
            </SearchForm>
            {availabilityData && (
              <div>
                {console.log('Attempting to render availability with:', { availabilityData, airlineCabins })}
                {renderAvailability(availabilityData, airlineCabins)}
              </div>
            )}
          </>
        )}
      </ContentArea>
    </PageContainer>
  );
}

export default FlightAvailabilityPage; 