import { supabase } from '../lib/supabase';
import { getAmadeusToken } from './auth';

/**
 * Gets seatmap data for a specific flight and cabin class
 * 
 * @param {Object} flightDetails - Flight ID and details
 * @param {Object} cabinClass - Cabin configuration
 * @param {string} cabinClass.cabin - ECONOMY, PREMIUM_ECONOMY, BUSINESS, or FIRST
 * @param {string} cabinClass.class - Y, W, C, or F
 */
async function updateSeatmapApiCallCount() {
  try {
    // First check if row exists
    const { data: existing, error: checkError } = await supabase
      .from('api_calls')
      .select('*')
      .eq('api_name', 'amadeus_v2_seatmaps')
      .eq('endpoint', 'seatmap_details')
      .single();

    console.log('Existing API call row:', existing);
    
    if (checkError) {
      console.error('Error checking API call row:', checkError);
      return;
    }

    // Then try to update using direct increment
    const { data, error } = await supabase
      .from('api_calls')
      .update({ 
        count: existing.count + 1,  // Direct increment instead of RPC
        last_called: new Date().toISOString(),
        url: 'https://api.amadeus.com/v1/shopping/seatmaps'
      })
      .eq('api_name', 'amadeus_v2_seatmaps')
      .eq('endpoint', 'seatmap_details')
      .select();

    console.log('Update response:', data);
    
    if (error) {
      console.error('Update error details:', error);
      throw error;
    }
    
  } catch (error) {
    console.error('Error updating seatmap API call count:', error);
  }
}

// Map our cabin classes to Amadeus enum values and fare classes
const CABIN_MAPPINGS = {
  'Economy': { cabin: 'ECONOMY', class: 'Y' },
  'Premium Economy': { cabin: 'PREMIUM_ECONOMY', class: 'W' },
  'Business': { cabin: 'BUSINESS', class: 'J' },  // Use J class for Business
  'First': { cabin: 'FIRST', class: 'F' }
};

// Helper function to get timestamp field name for cabin
const getSeatmapTimestampField = (cabin) => {
  switch (cabin) {
    case 'FIRST':
      return 'first_class_seatmap_checked';
    case 'BUSINESS':
      return 'business_class_seatmap_checked';
    case 'PREMIUM_ECONOMY':
      return 'premium_economy_seatmap_checked';
    case 'ECONOMY':
      return 'economy_class_seatmap_checked';
    default:
      return null;
  }
};

export async function getSimplifiedSeatmap({ id }, cabinClass) {
  console.log('Requesting seatmap for cabin:', cabinClass);

  try {
    // 1. Get flight details from our database
    const { data: flight, error } = await supabase
      .from('flights')
      .select(`*,airlines(code,name)`)
      .eq('id', id)
      .single();

    console.log('Flight details from DB:', flight);

    if (error) throw error;

    // 2. Get auth token
    const token = await getAmadeusToken();
    console.log('Got auth token:', token.slice(0, 10) + '...');

    // 3. Construct request payload
    const requestBody = {
      data: [{
        type: "flight-offer",
        id: "1",
        itineraries: [{
          segments: [{
            id: "1",
            departure: {
              iataCode: flight.departure_airport_code,
              at: new Date(flight.departure_time).toISOString().split('.')[0]
            },
            arrival: {
              iataCode: flight.arrival_airport_code,
              at: new Date(flight.arrival_time).toISOString().split('.')[0]
            },
            carrierCode: flight.airline_code,
            number: flight.flight_number,
            aircraft: {
              code: "777"
            }
          }]
        }],
        travelerPricings: [{
          travelerId: "1",
          travelerType: "ADULT",
          fareDetailsBySegment: [{
            segmentId: "1",
            cabin: cabinClass.cabin,    // Use passed cabin class
            class: cabinClass.class     // Use passed fare class
          }]
        }]
      }]
    };

    // 4. Make the API call
    const seatmapResponse = await fetch(
      'https://api.amadeus.com/v1/shopping/seatmaps', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      }
    );

    console.log('Seatmap response status:', seatmapResponse.status);
    const seatmapData = await seatmapResponse.json();
    console.log('Seatmap response:', JSON.stringify(seatmapData, null, 2));

    if (!seatmapResponse.ok) {
      throw new Error(`Failed to get seatmap: ${seatmapData.errors?.[0]?.detail || 'Unknown error'}`);
    }

    // Get the appropriate payload field name for this cabin class
    const payloadField = `${cabinClass.cabin.toLowerCase()}_class_seatmap_payload`.replace('premium_economy_class', 'premium_economy');
    
    // Store the API response payload in the database
    const { error: payloadError } = await supabase
      .from('flights')
      .update({ [payloadField]: seatmapData })
      .eq('id', id);

    if (payloadError) {
      console.error('Error storing seatmap response payload:', payloadError);
    }

    // Increment the counter after successful API call
    await updateSeatmapApiCallCount();

    // Count seats by cabin class and prepare update data
    const seatCounts = {
      economy_seats: 0,
      premium_economy_seats: 0,
      business_seats: 0,
      first_seats: 0
    };

    // Get the appropriate timestamp field for this cabin class
    const timestampField = getSeatmapTimestampField(cabinClass.cabin);
    if (timestampField) {
      seatCounts[timestampField] = new Date().toISOString();
    }

    // Parse the seatmap response to count seats
    seatmapData.data.forEach(deck => {
      deck.decks?.forEach(deck => {
        deck.seats?.forEach(seat => {
          switch(seat.cabin) {
            case 'ECONOMY':
              seatCounts.economy_seats++;
              break;
            case 'PREMIUM_ECONOMY':
              seatCounts.premium_economy_seats++;
              break;
            case 'BUSINESS':
              seatCounts.business_seats++;
              break;
            case 'FIRST':
              seatCounts.first_seats++;
              break;
          }
        });
      });
    });

    console.log('Updating flight with seat counts and timestamp:', {
      ...seatCounts,
      timestampField,
      cabin: cabinClass.cabin
    });

    // Update the flight record with seat counts and timestamp
    const { error: updateError } = await supabase
      .from('flights')
      .update(seatCounts)
      .eq('id', id);

    if (updateError) {
      console.error('Error updating flight data:', updateError);
    } else {
      console.log('Successfully updated flight with seat counts and seatmap data');
    }

    return {
      requestDetails: {
        seatmap: requestBody
      },
      response: {
        seatmap: seatmapData,
        seatCounts
      }
    };
    
  } catch (error) {
    console.error('Error in seatmap flow:', error);
    throw error;
  }
}; 

export const getSeatmap = async (flightDetails) => {
  try {
    console.log('Flight details from DB:', JSON.stringify(flightDetails, null, 2));
    
    // Get auth token
    const token = await getAmadeusToken();
    console.log('Got auth token:', token.slice(0, 10) + '...');

    // Construct request payload
    const requestBody = {
      data: [{
        type: "flight-offer",
        id: "1",
        source: "GDS",
        instantTicketingRequired: false,
        disablePricing: false,
        nonHomogeneous: false,
        oneWay: false,
        lastTicketingDate: flightDetails.departure_date,
        numberOfBookableSeats: 1,
        itineraries: [{
          duration: "PT12H",
          segments: [{
            departure: {
              iataCode: flightDetails.departure_airport_code,
              terminal: flightDetails.departure_terminal,
              at: `${flightDetails.departure_date}T${flightDetails.departure_time}`
            },
            arrival: {
              iataCode: flightDetails.arrival_airport_code,
              terminal: flightDetails.arrival_terminal,
              at: `${flightDetails.arrival_date}T${flightDetails.arrival_time}`
            },
            carrierCode: flightDetails.airline_code,
            number: flightDetails.flight_number,
            aircraft: {
              code: "777"
            },
            operating: {
              carrierCode: flightDetails.airline_code,
              aircraft: {
                code: "777"
              }
            },
            id: "1"
          }]
        }]
      }]
    };

    console.log('=== REQUEST PAYLOAD ===');
    console.log(JSON.stringify({
      data: [{
        type: "flight-offer",
        id: "1",
        source: "GDS",
        instantTicketingRequired: false,
        disablePricing: false,
        nonHomogeneous: false,
        oneWay: false,
        lastTicketingDate: flightDetails.departure_date,
        numberOfBookableSeats: 1,
        itineraries: [{
          duration: "PT12H",
          segments: [{
            departure: {
              iataCode: flightDetails.departure_airport_code,
              terminal: flightDetails.departure_terminal,
              at: `${flightDetails.departure_date}T${flightDetails.departure_time}`
            },
            arrival: {
              iataCode: flightDetails.arrival_airport_code,
              terminal: flightDetails.arrival_terminal,
              at: `${flightDetails.arrival_date}T${flightDetails.arrival_time}`
            },
            carrierCode: flightDetails.airline_code,
            number: flightDetails.flight_number,
            aircraft: {
              code: "777"
            },
            operating: {
              carrierCode: flightDetails.airline_code,
              aircraft: {
                code: "777"
              }
            },
            id: "1"
          }]
        }]
      }]
    }, null, 2));
    console.log('=====================');
    console.log('Critical flight details:', {
      carrierCode: flightDetails.airline_code,
      number: flightDetails.flight_number,
      aircraft: {
        code: "777"
      }
    });
    console.log('Flight details being requested:', {
      flight: `${flightDetails.airline_code}${flightDetails.flight_number}`,
      date: flightDetails.departure_date,
      route: `${flightDetails.departure_airport_code}-${flightDetails.arrival_airport_code}`,
      times: `${flightDetails.departure_time}-${flightDetails.arrival_time}`,
      aircraft: flightDetails.aircraft_type
    });

    // Make the request with retries
    let retries = 3;
    let lastError = null;

    while (retries > 0) {
      try {
        // Log the exact payload we're about to send
        console.log('PAYLOAD BEING SENT TO AMADEUS:', JSON.stringify(requestBody, null, 2));

        const response = await fetch('https://api.amadeus.com/v1/shopping/seatmaps', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestBody)
        });

        console.log('Response headers:', {
          status: response.status,
          statusText: response.statusText,
          headers: Object.fromEntries([...response.headers.entries()])
        });

        const data = await response.json();
        console.log('Full response data:', JSON.stringify(data, null, 2));

        if (!response.ok) {
          // Log specific error details
          if (data.errors) {
            console.error('Detailed Amadeus API Error:', {
              status: response.status,
              errors: data.errors,
              flight: `${flightDetails.airline_code}${flightDetails.flight_number}`,
              date: flightDetails.departure_date,
              requestBody: requestBody
            });

            // Check for specific error codes
            const errorCode = data.errors[0]?.code;
            if (errorCode === 141) {
              console.warn('Amadeus system error (141) - Possible causes:', {
                invalidFormat: 'Check date/time formats',
                flightNotFound: 'Verify flight exists in Amadeus',
                systemIssue: 'May be temporary - retry after delay'
              });
            }
          }

          throw new Error(`Amadeus API error: ${JSON.stringify(data.errors)}`);
        }

        return data;
      } catch (err) {
        console.error(`Attempt ${4 - retries} failed:`, {
          error: err.message,
          stack: err.stack,
          flight: `${flightDetails.airline_code}${flightDetails.flight_number}`,
          date: flightDetails.departure_date
        });
        
        lastError = err;
        retries--;
        if (retries > 0) {
          const delay = (4 - retries) * 2000; // Exponential backoff
          console.log(`Retrying in ${delay/1000} seconds...`);
          await new Promise(resolve => setTimeout(resolve, delay));
        }
      }
    }

    throw lastError || new Error('Failed to get seatmap after all retries');
  } catch (err) {
    console.error('Error in seatmap flow:', {
      error: err.message,
      stack: err.stack,
      flight: `${flightDetails.airline_code}${flightDetails.flight_number}`,
      date: flightDetails.departure_date
    });
    throw err;
  }
}; 

// Update flight with seat counts
const updateFlightWithSeatCounts = async (flightId, seatCounts) => {
  // Log the update in a more structured way
  console.log('Updating flight with:', {
    flightId,
    seatCounts
  });

  try {
    const { data, error } = await supabase
      .from('flights')
      .update(seatCounts)
      .eq('id', flightId)
      .select();

    if (error) {
      console.error('Error updating flight data:', error);
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error updating flight data:', error);
    throw error;
  }
}; 