import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { H1, BodyM } from '../styles/typography';
import { supabase } from '../lib/supabase';

const PageContainer = styled.div`
  min-height: 100vh;
  background-color: ${props => props.theme.colors.neutral.light.lightest};
`;

const LogoContainer = styled.div`
  width: 100%;
  height: 208px;
  background-color: ${props => props.theme.colors.highlight.lightest};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  padding-top: 6px;
`;

const Logo = styled.img`
  width: 196px;
  height: 196px;
`;

const Title = styled(H1)`
  margin-bottom: 16px;
  padding: 0 24px;
`;

const Form = styled.form`
  width: 100%;
  max-width: 428px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;
  box-sizing: border-box;
`;

const Input = styled.input`
  width: 100%;
  padding: 16px;
  border: 1px solid ${props => props.theme.colors.neutral.light.dark};
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;
  
  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.highlight.darkest};
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 16px;
  background-color: ${props => props.theme.colors.highlight.darkest};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin: 4px 0;
`;

const ErrorMessage = styled(BodyM)`
  color: ${props => props.theme.colors.support.error.dark};
  text-align: center;
  margin-bottom: 16px;
`;

const Description = styled(BodyM)`
  text-align: center;
  color: ${props => props.theme.colors.neutral.dark.light};
  margin-bottom: 16px;
`;

const AvatarContainer = styled.div`
  width: 120px;
  height: 120px;
  margin: 0 auto 24px auto;
  position: relative;
`;

const AvatarUpload = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 60px;
  background-color: ${props => props.theme.colors.neutral.light.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  
  &:hover {
    opacity: 0.9;
  }
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AvatarInitial = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 48px;
  color: ${props => props.theme.colors.neutral.light.lightest};
  text-transform: uppercase;
`;

const HiddenInput = styled.input`
  display: none;
`;

const UploadHint = styled(BodyM)`
  position: absolute;
  bottom: -24px;
  left: 0;
  right: 0;
  text-align: center;
  color: ${props => props.theme.colors.neutral.dark.light};
  font-size: 12px;
`;

function OnboardingPage({ onComplete }) {
  const [handle, setHandle] = useState('');
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    // Get user email for initial
    const getUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUserEmail(user?.email || '');
    };
    getUser();
  }, []);

  const handleAvatarClick = () => {
    document.getElementById('avatar-upload').click();
  };

  const handleAvatarChange = async (e) => {
    try {
      const file = e.target.files?.[0];
      if (!file) return;

      // Validate file type and size
      if (!file.type.startsWith('image/')) {
        setError('Please upload an image file');
        return;
      }
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError('Image must be less than 5MB');
        return;
      }

      setAvatarUrl(URL.createObjectURL(file));
    } catch (error) {
      console.error('Avatar selection error:', error);
      setError('Error selecting image');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!handle.trim()) {
      setError('Please enter a handle');
      return;
    }

    try {
      setError(null);
      setLoading(true);

      // Check if handle exists using count instead of single
      const { data: existingHandles, error: checkError } = await supabase
        .from('profiles')
        .select('handle', { count: 'exact' })
        .eq('handle', handle.toLowerCase());

      console.log('Handle check result:', { existingHandles, checkError });

      if (checkError) {
        console.error('Handle check error:', checkError);
        throw checkError;
      }

      if (existingHandles?.length > 0) {
        setError('This handle is already taken');
        return;
      }

      // Get current user
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError) throw userError;

      console.log('Updating profile for user:', user.id);

      // Update profile with handle
      const { error: updateError } = await supabase
        .from('profiles')
        .update({ 
          handle: handle.toLowerCase(),
          updated_at: new Date().toISOString()
        })
        .eq('id', user.id);

      if (updateError) {
        console.error('Profile update error:', updateError);
        throw updateError;
      }

      console.log('Profile updated successfully');
      onComplete();

    } catch (error) {
      console.error('Onboarding error:', error);
      setError(error.message || 'Failed to create profile. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageContainer>
      <LogoContainer>
        <Logo src="/logo.png" alt="FlyerCopilot Logo" />
      </LogoContainer>
      <Title>Create Your Profile</Title>
      <Form onSubmit={handleSubmit}>
        <Description>
          Add a photo and choose a unique handle for your profile.
        </Description>
        <AvatarContainer>
          <AvatarUpload onClick={handleAvatarClick}>
            {avatarUrl ? (
              <AvatarImage src={avatarUrl} alt="Profile" />
            ) : (
              <AvatarInitial>
                {userEmail.charAt(0)}
              </AvatarInitial>
            )}
          </AvatarUpload>
          <HiddenInput
            id="avatar-upload"
            type="file"
            accept="image/*"
            onChange={handleAvatarChange}
          />
          <UploadHint>Click to upload photo</UploadHint>
        </AvatarContainer>
        <Input
          type="text"
          placeholder="Enter your handle"
          value={handle}
          onChange={(e) => setHandle(e.target.value)}
          disabled={loading}
          maxLength={30}
        />
        <Button type="submit" disabled={loading}>
          {loading ? 'Creating...' : 'Continue'}
        </Button>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Form>
    </PageContainer>
  );
}

export default OnboardingPage; 